import clsx from 'clsx';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import MyNumericInput from '../../MyNumericInput';
import PriceToStr from '../../PriceToStr';
import {CauseSelector} from './CauseSelector';

export const TableProductItem = ({order, setSelectedResults}) => {
  const [flag, setFlag] = useState(0);
  const [returnReason, setSelectedReason] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  useEffect(() => {
    if (returnReason) {
      setSelectedResults(prevState => {
        const currentOrder = prevState.find(
          item => item.article === order.article,
        );
        return [
          ...prevState.filter(item => item.article !== order.article),
          {...currentOrder, returnReason, selectedQuantity},
        ];
      });
    }
  }, [returnReason, selectedQuantity]);

  const {t} = useTranslation();
  const popupItems = [
    {title: t('common:the_customer_did_not_order'), value: 2},
    {title: t('common:we_shipped_incorrectly'), value: 3},
    {title: t('common:didnt_like_the_quality'), value: 4},
    {title: t('common:defective_goods'), value: 5},
    {title: t('common:product_arrived_damaged'), value: 6},
  ];

  // useEffect(() => {
  //   setSelectedQuantity(order?.quantity);
  // }, []);

  const onChangeQuantity = (
    quantity,
    goods_id,
    price,
    priceCurrent,
    maxQuantity,
    minQuantity,
  ) => {
    if (quantity === null || quantity === '') {
      quantity = minQuantity;
      setFlag(flag + 1);
    } else {
      setFlag(flag + 1);
    }
    quantity = parseInt(quantity);
    price = parseFloat(price);
    maxQuantity = parseInt(maxQuantity);

    if (quantity > maxQuantity) quantity = maxQuantity;
    if (quantity < minQuantity) quantity = minQuantity;
    setSelectedQuantity(quantity);
  };

  const handleRemoveOrder = () => {
    setSelectedResults(prevState =>
      prevState.filter(item => item.article !== order.article),
    );
  };
  return (
    <div className="row mx-0 py-3 product-all-item items-center">
      <div className="pl-0 col-5">
        <div className="d-flex items-center gap-10">
          <img src={order?.images?.thumb} alt="product" />
          <p className="product-item-title">{order?.name}</p>
        </div>
      </div>
      <div className="p-0 col-2">
        <div className="product-item-title">{order?.article}</div>
        {/* <div className="product-item-title">Код: {order?.sku_product}</div> */}
      </div>
      <div className="p-0 pr-2 col-2">
        <MyNumericInput
          onChange={onChangeQuantity}
          uuid={order?.order__id}
          price={order?.price_current}
          priceCurrent={order?.price_current}
          min={1}
          max={order?.quantity}
          value={1}
          defaultValue={0}
          step={1}
          stDisplay={'flex'}
          onCartPage={true}
        />
      </div>
      <div className="p-0 col-3 d-flex items-center justify-between">
        <CauseSelector
          setSelectedReason={setSelectedReason}
          items={popupItems}
          title={t('common:choose_a_reason')}
        />
        <svg
          onClick={handleRemoveOrder}
          className="cursor-pointer"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="15" fill="#EBF0F8" />
          <path
            d="M11 11L18.9114 19M19 11L11.0886 19"
            stroke="#FF5252"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};
