import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';
// import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import TableSortLabel from '@material-ui/core/TableSortLabel';
//import Paper from '@material-ui/core/Paper';
import {withRouter} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {TableBody, Grid, useMediaQuery, useTheme} from '@material-ui/core';

//import DateFnsUtils from '@date-io/date-fns';
//import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';

import {useTranslation} from 'react-i18next';

import clsx from 'clsx';

import {userActions} from '../../../_actions';

import PriceToStr from '../../../_components/PriceToStr';
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import i18n from "../../../i18n";
// import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

const MOBILE_SIZE = 768;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2, 0),
    '& h4': {
      fontWeight: '500',
      fontSize: '24px',
      [theme.breakpoints.down(MOBILE_SIZE)]: {
        fontSize: '16px',
        fontWeight: '600',
        margin: '13px 0',
      },
    },
  },
  root2: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 8px 6px 8px',
      [theme.breakpoints.down(355)]: {
        padding: '6px 5px 6px 5px',
      },
    },
  },
  root_mobile: {
    '& .MuiGrid-root:first-child': {
      display: 'flex',
      flexDirection: 'column',
      '& .MuiGrid-item:nth-child(2n)': {
        alignSelf: 'flex-end',
      },
    },
  },
  table: {
    '& td': {
      textAlign: 'center',
    },
    // [theme.breakpoints.up('sm')]: {
    //   // marginLeft: drawerWidth,
    //   width: `calc(100% - ${100}px)`,
    // },
  },
  cellHeader: {
    fontSize: '14px',
    fontWeight: '700',
    background: '#ededed',
    borderTop: '#c0c0c0 2px solid',
    textAlign: 'center',
    [theme.breakpoints.down(MOBILE_SIZE)]: {
      background: '#f7f6f6',
    },
  },
  cellRightBorder: {
    borderRight: '#dedede 1px solid',
  },
  cell: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#505050',
  },
  priceCell: {
    '& span': {
      //background: 'red',
      display: 'inline-block',
      width: '100%',
      paddingRight: '30%',
      [theme.breakpoints.down(MOBILE_SIZE)]: {
        paddingRight: '0',
      },
      textAlign: 'right',
    },
  },
  cellBold: {
    fontWeight: '700',
    fontSize: '14px',
  },
  sectionDivider: {
    paddingTop: '10px',
  },
  positive: {color: '#56AF3B'},
  i: {
    color: '#56AF3B',
    display: 'inline-block',
    marginLeft: '10px',
    border: '1px solid #56AF3B',
    cursor: 'pointer',
    borderRadius: '50%',
    width: '11px',
    height: '11px',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '9px',
    lineHeight: '11px',
  },
  tooltip: {
    tooltip: {
      background: 'red',
    },
  },
  // continue here : mobile view
  mobileSummary: {
    borderTop: '#c0c0c0 2px solid',
    fontSize: '14px',
    paddingTop: '5px',
  },
  mobileFirstCol: {
    padding: '5px 0',
    fontWeight: '500',
    whiteSpace: 'nowrap',
  },
  mobileSecondCol: {
    padding: '5px 0',
    textAlign: 'right',
  },

  selectOrganization: {
    margin: '0 0 0 15px',
    fontSize: '15px',
    minWidth: '210px',
    [theme.breakpoints.down(MOBILE_SIZE)]: {
      minWidth: '160px',
    },
    '&:before': {
      borderColor: 'rgba(255, 255, 255 0)!important',
      border: 'none',
      opacity: 0,
    },
    '&:after': {
      borderColor: 'rgba(255, 255, 255 0)!important',
      border: 'none',
      opacity: 0,
    },
    // '&:hover': {
    //   borderColor: 'inherit!important',
    //   border: 'none',
    // },

    border: '1px solid #bebdbd',
    borderRadius: '4px',
    '& select': {
      textIndent: '5px',
    },
  },
}));

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: '310px',
  },
}))(Tooltip);

function Finance(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const theme = useTheme();

  // finance calendar data
  const [data, setData] = useState();
  // contragent(customer) detailed data
  const [customerData, setCustomerData] = useState();
  // user balance data
  const [userBalance, setUserBalance] = useState();
  // outdated debt
  const [outdatedDebt, setOutdatedDebt] = useState(null);
  // const [openOrganization, setOpenOrganization] = useState(false);
  const [showFinanceCalendar, setShowFinanceCalendar] = React.useState('');
  const [selectOrganization, setSelectOrganization] = React.useState(
    'ТОВ "ДАНІ-ПАРТС"',
  );
  const [organizationData, setOrganizationData] = React.useState([]);

  const isTablet = useMediaQuery(theme.breakpoints.down(780), {
    defaultMatches: true,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down(448), {
    defaultMatches: true,
  });
  const isSmall = useMediaQuery(theme.breakpoints.down(420), {
    defaultMatches: true,
  });

  const stPositive = {color: '#008000'};
  const stNegative = {color: '#C43939'};

  const {
    customerId: propsCustomerId,
    financeCalendar: propsFinanceCalendar,
    userBalance: propsUserBalance,
    contragentData: propsContragentData,
    financeCalendarByOrganization,
    // getPaymentCalendarByOrganization
  } = props;
  const {getFullPaymentCalendar} = props;
  useEffect(() => {
    // this function will be called twice without "return" when tab changes
    return getFullPaymentCalendar(propsCustomerId);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   // this function will be called twice without "return" when tab changes
  //   return getPaymentCalendarByOrganization(propsCustomerId);
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (propsFinanceCalendar && propsFinanceCalendar.loading === false) {
      setOutdatedDebt(propsFinanceCalendar.outdatedDebt);
      setData(propsFinanceCalendar);
    }

    if (
      financeCalendarByOrganization &&
      financeCalendarByOrganization.loading === false &&
      financeCalendarByOrganization.result.organization_data.length
    ) {
      // debugger;
      setSelectOrganization(
        financeCalendarByOrganization.result.organization_data[0].organization,
      );
      setShowFinanceCalendar(
        financeCalendarByOrganization.result.organization_data[0]
          .organization_internal_id,
      );
      setOrganizationData(
        financeCalendarByOrganization.result.organization_data,
      );
    }
  }, [propsFinanceCalendar, financeCalendarByOrganization, selectOrganization]);

  useEffect(() => {
    if (propsUserBalance) {
      setUserBalance(propsUserBalance);
    }
  }, [propsUserBalance]);

  useEffect(() => {
    if (propsContragentData && propsContragentData.customer_data) {
      setCustomerData(propsContragentData.customer_data);
    }
  }, [propsContragentData]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     const currentScreenWidth = window.innerWidth;
  //     if (currentScreenWidth <= 780) {
  //       showMobileList(true);
  //     } else {
  //       showMobileList(false);
  //     }
  //   };
  //   handleResize();
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const getCurrencySymbol = () => {
    if (financeCalendarByOrganization.result) {
      if (!financeCalendarByOrganization.result.current_currency) return '';

      let sForTranslate = financeCalendarByOrganization.result.current_currency.toLowerCase();
      let s = t('common:' + sForTranslate);
      if (!s || s === sForTranslate) {
        s = financeCalendarByOrganization.result.current_currency;
      } else {
        s += '.';
      }
      return s;
    }
  };

  const getRecommendedDate = payDate => {
    let financeDate = payDate.split('.');
    let transformDate = new Date(
      financeDate[2],
      financeDate[1] - 1,
      financeDate[0],
    );
    let today = new Date();
    let date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let subtractionDate = Date.parse(transformDate) - Date.parse(date);
    let currentDay = Math.abs(
      Math.floor(subtractionDate / (24 * 60 * 60 * 1000)),
    );
    let tooltipText = '';
    if (currentDay === 0) {
      tooltipText = t('common:recommended_to_pay_tooltip');
    } else if (currentDay === 1) {
      tooltipText = t('common:recommended_to_pay_tooltip2');
    } else if (currentDay === 2) {
      tooltipText = t('common:recommended_to_pay_tooltip3');
    }

    return tooltipText;
  };

  const [states, setStates] = React.useState({
    age: '',
    name: selectOrganization,
  });

  const handleChange = event => {
    setShowFinanceCalendar(event.target.value);
    const name = event.target.name;
    setStates({
      ...states,
      [name]: event.target.value,
    });
  };

  return (
    <>
      {data && customerData && userBalance ? (
        <div className={clsx(classes.root, isSmall && classes.root_mobile)}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 0 5px',
            }}>
            <h4>{t('common:balance_structure')}</h4>
            <div className={classes.ratePrivatBank}>
              {financeCalendarByOrganization.result &&
              financeCalendarByOrganization.result.currency !== '980' ? (
                <div
                  style={{
                    width: '150px',
                    textAlign: 'center',
                    border: '1px solid #c0c0c0',
                    padding: '5px',
                  }}>
                  <stong style={{color: '#62ab36', fontSize: '20px'}}>
                    {financeCalendarByOrganization.result.rate_privat_bank}
                  </stong>
                  <div style={{color: '#62ab36', fontSize: '12px'}}>
                    {t('common:rate_privat_bank')}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          {isTablet && (
            <div className={classes.mobileSummary}>
              <Grid container>
                <Grid item xs={6} className={classes.mobileFirstCol}>
                  {t('common:balance')} ({getCurrencySymbol()})
                </Grid>
                <Grid item xs={6} className={classes.mobileSecondCol}>
                  <PriceToStr
                    data={userBalance.current_balance}
                    stPositive={stPositive}
                    stNegative={stNegative}
                  />
                </Grid>

                <Grid item xs={6} className={clsx(classes.mobileFirstCol)}>
                  {t('common:outdated_debt')} ({getCurrencySymbol()})
                </Grid>
                <Grid item xs={6} className={classes.mobileSecondCol}>
                  <span style={outdatedDebt ? stNegative : null}>
                    <PriceToStr data={outdatedDebt} />
                  </span>
                </Grid>

                <Grid item xs={6} className={classes.mobileFirstCol}>
                  {t('common:credit')} ({getCurrencySymbol()})
                </Grid>
                <Grid item xs={6} className={classes.mobileSecondCol}>
                  <PriceToStr data={customerData.credit_limit_sum} />
                </Grid>

                <Grid item xs={6} className={classes.mobileFirstCol}>
                  {t('common:postponement_days')}
                </Grid>
                <Grid item xs={6} className={classes.mobileSecondCol}>
                  {customerData.credit_limit_days}
                </Grid>

                <Grid item xs={6} className={classes.mobileFirstCol}>
                  {t('common:shipment_available_sum_mobile')} (
                  {getCurrencySymbol()})
                </Grid>
                <Grid item xs={6} className={classes.mobileSecondCol}>
                  {data.shipment_allowed === false ? (
                    <span style={stNegative}>
                      {t('common:not_available_pay_debt_mobile')}
                    </span>
                  ) : (
                    <PriceToStr
                      data={
                        customerData.credit_limit_sum +
                        userBalance.current_balance
                      }
                      stPositive={stPositive}
                      stNegative={stNegative}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          )}
          {!isTablet && (
            <Table
              className={clsx(classes.table, classes.root2)}
              size="small"
              aria-label="Summary table">
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell className={classes.cellHeader}>
                    {t('common:company')}
                  </TableCell>
                  <TableCell className={classes.cellHeader}>
                    {t('common:balance')} ({getCurrencySymbol()})
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.cellHeader,
                      // classes.cellRightBorder,
                    )}>
                    {t('common:outdated_debt')} ({getCurrencySymbol()})
                  </TableCell>
                  <TableCell className={classes.cellHeader}>
                    {t('common:credit')} ({getCurrencySymbol()})
                  </TableCell>
                  <TableCell
                    className={clsx(
                      classes.cellHeader,
                      // classes.cellRightBorder,
                    )}>
                    {t('common:postponement_days')}
                  </TableCell>
                  <TableCell className={classes.cellHeader}>
                    {t('common:shipment_available_sum')} ({getCurrencySymbol()})
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {organizationData &&
                  organizationData.map((item, key) => (
                    <TableRow key={item.organization + key}>
                      <TableCell
                        className={clsx(classes.cell)}
                        style={{textAlign: 'left'}}>
                        {item.organization}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <PriceToStr
                          data={
                            item.shipment_allowed === false
                              ? item.full_payment * -1
                              : item.full_payment * -1
                          }
                          stPositive={stPositive}
                          stNegative={stNegative}
                        />
                      </TableCell>
                      <TableCell className={clsx(classes.cell)}>
                        <span style={outdatedDebt ? stNegative : null}>
                          <PriceToStr data={item.overdue_payment} />
                        </span>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <PriceToStr data={item.credit_limit} />
                      </TableCell>
                      <TableCell className={clsx(classes.cell)}>
                        {item.number_of_days_of_grace}
                      </TableCell>
                      <TableCell className={classes.cellBold}>
                        {item.shipment_allowed === false ? (
                          <span style={stNegative}>
                            {t('common:not_available_pay_debt')}
                          </span>
                        ) : (
                          <PriceToStr
                            data={item.credit_limit + item.full_payment * -1}
                            stPositive={stPositive}
                            stNegative={stNegative}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}

          <>
            <div className={classes.sectionDivider}></div>

            <Grid
              container
              style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
              <h4 style={{margin: '15px 0 15px 0'}}>
                {t('common:payment_calendar')}
              </h4>
              <Grid item xs={6} lg={4}>
                <FormControl className={classes.formControl}>
                  {/*<InputLabel shrink htmlFor="name-native-disabled" id="organization">{t('common:company')}</InputLabel>*/}
                  <NativeSelect
                    className={classes.selectOrganization}
                    variant="outlined"
                    value={states.name}
                    defaultValue={states.name}
                    labelId="organization"
                    onChange={handleChange}
                    inputProps={{
                      name: 'name',
                      id: 'name-native-disabled',
                    }}>
                    {financeCalendarByOrganization.result &&
                      financeCalendarByOrganization.result.organization_data.map(
                        (item, index) => (
                          <option
                            key={index}
                            className={classes.option}
                            name={item.organization}
                            value={item.organization_internal_id}>
                            {item.organization}
                          </option>
                        ),
                      )}
                  </NativeSelect>
                  {/*<FormHelperText>Disabled</FormHelperText>*/}
                </FormControl>
              </Grid>
            </Grid>

            <Table
              className={clsx(classes.table, isMobile && classes.root2)}
              size="small"
              aria-label="Summary table">
              <TableHead>
                <TableRow className={classes.tableHeadRow}>
                  <TableCell className={classes.cellHeader}>
                    {t('common:payment_date')}
                  </TableCell>
                  <TableCell className={classes.cellHeader}>
                    {t('common:payment_sum')} ({getCurrencySymbol()})
                  </TableCell>
                  {!isTablet && (
                    <TableCell className={classes.cellHeader}>
                      {t('common:days_overdue')}
                    </TableCell>
                  )}
                  <TableCell className={classes.cellHeader}>
                    {t('common:total_debt')} ({getCurrencySymbol()})
                  </TableCell>
                </TableRow>
              </TableHead>

              {organizationData.map((item, key) => (
                <>
                  {showFinanceCalendar &&
                  item.organization_internal_id === showFinanceCalendar ? (
                    <TableBody>
                      {item.payment_calendar.map((item, key) => (
                        <TableRow key={key}>
                          <TableCell className={classes.cell}>
                            {item.date}
                          </TableCell>
                          <TableCell
                            className={clsx(classes.cell, classes.priceCell)}>
                            <span style={item.is_overdue ? stNegative : null}>
                              <PriceToStr data={item.payment} />
                            </span>
                          </TableCell>
                          {!isTablet && (
                            <TableCell className={classes.cell}>
                              {item.days_overdue > 0 ? (
                                <span style={stNegative}>
                                  {item.days_overdue}
                                </span>
                              ) : null}
                              {item.recommend && (
                                <>
                                  {/*{item.recommend ? getRecommendedDate(item.date) : null }*/}
                                  <span className={classes.positive}>
                                    {t('common:recommended_to_pay')}
                                    {/*{recommendedToday}*/}
                                    {/*item.diffWithToday*/}
                                  </span>
                                  <LightTooltip
                                    //leaveDelay={10000}

                                    className={classes.tooltip}
                                    title={getRecommendedDate(item.date)}>
                                    <span className={classes.i}>i</span>
                                  </LightTooltip>
                                </>
                              )}
                              {!item.days_overdue && !item.recommend ? 0 : null}
                            </TableCell>
                          )}
                          <TableCell
                            className={clsx(
                              item.recommend ? classes.cellBold : classes.cell,
                              classes.priceCell,
                            )}>
                            <span style={item.is_overdue ? stNegative : null}>
                              <PriceToStr data={item.full_payment} />
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : null}
                </>
              ))}
            </Table>
          </>
        </div>
      ) : null}
    </>
  );
}

function mapState(state) {
  const {userBalance, userSelectedData, authentication, contragentData} = state;
  const {customer_id: customerId} = userSelectedData;
  const {loggedIn} = authentication;
  const {main_currency} = state.frontendData;
  const {financeCalendar, financeCalendarByOrganization} = state;
  return {
    customerId,
    userBalance,
    loggedIn,
    contragentData,
    main_currency,
    financeCalendar,
    financeCalendarByOrganization,
  };
}

const actionCreators = {
  getFullPaymentCalendar: userActions.getFullPaymentCalendar,
  // getPaymentCalendarByOrganization: userActions.getPaymentCalendarByOrganization
};

export default connect(mapState, actionCreators)(withRouter(Finance));
