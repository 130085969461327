import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import PtoductsItemColumns from './PtoductsItemColumns';
import {Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import {dataProcessing} from '../../../../_helpers';
import {connect} from 'react-redux';
import {ProductItem} from '../../../../_components/Product/ProductItem';
import {AddToCartModal} from '../../../../_components/Product/AddToCartModal';
import {ProductItemHr} from '../../../../_components/Product/ProductItemHr';
// import {ProductItem} from './ProductItem';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // marginTop: theme.spacing(0),
    // //border: '1px solid blue',
    // flexWrap: 'wrap',
    // justifyContent: 'flex-start',//''//'center',//'space-between',
    // textAlign: 'center',
  },
  /*table: {
        minWidth: 700,
    },*/

  gridContainerHr: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #eeeeee',
    boxShadow: '0 1px 2px #eeeeee',
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'white',
    width: 'calc((100% - 20px)/2)',
    margin: 5,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 'calc(100% - 10px)',
    },
    '&:hover': {
      boxShadow: '0 2px 5px #333333',
    },
  },

  gridContainerVr: {
    display: 'block',
    justifyContent: 'space-between',
    border: '1px solid #eeeeee',
    boxShadow: '0 1px 2px #eeeeee',
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'white',

    width: 'calc((100% - 30px)/3)',
    margin: 5,
    [theme.breakpoints.down('md')]: {
      width: 'calc((100% - 30px)/3)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc((100% - 20px)/2)',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 'calc(100% - 10px)',
    },
    '&:hover': {
      boxShadow: '0 2px 5px #333333',
    },
  },

  gridContainerBl: {
    //padding: '0 5px',
    alignItems: 'center',
    display: 'inline-flex',
    //justifyContent: 'space-between',
    //border: '1px solid red',
    height: 205,
    maxHeight: 205,
  },
  gridContainerBlCont: {
    padding: '0 5px',
    //alignItems: "flex-start",
    display: 'flex',
    justifyContent: 'space-between',
    //border: '1px solid red',
    //height: 305,
    maxHeight: 300,
  },

  gridContainerSubBlCont: {
    padding: 0,
    alignItems: 'flex-start',
    display: 'block', //'inline-block',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },

  divider: {
    margin: theme.spacing(1, 0),
  },

  signOutButton: {
    minWidth: 40,
    padding: '4px 0',
    //backgroundColor: 'darkorange',
  },

  stBrand: {
    fontSize: 14,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis' /* Многоточие */,
  },
  stArticle: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stName: {
    marginTop: 3,
    fontSize: 14,
    height: 50,
    maxHeight: 50,
    //whiteSpace: 'nowrap',
    overflow: 'hidden',
    //textOverflow: 'ellipsis', /* Многоточие */
  },
  stPrice: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stBalance: {
    fontFamily: 'sans-serif',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stDelivery: {
    color: '#888888',
    fontFamily: 'sans-serif',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stQuantity: {
    color: 'green',
    fontWeight: 'bold',
    fontSize: 16,
  },
  stInfo: {
    fontSize: 14,
    color: '#546e7a',
  },

  otherOffersHr: {
    transitionProperty: 'opacity',
    transitionDuration: '300ms',
    opacity: 0,
    zIndex: 1,
    backgroundColor: 'white',
    margin: 0,
    //width: 'calc(100% + 32px)',
    width: 'calc(100% + 92px)',
    //left: -16,
    left: -76,
    top: -6,
    position: 'relative', //'absolute',
    display: 'none',
    //maxHeight: 350,
  },
  otherOffersVr: {
    transitionProperty: 'opacity',
    transitionDuration: '300ms',
    opacity: 0,
    zIndex: 1,
    backgroundColor: 'white',

    margin: 0,
    width: 'calc(100% + 32px)',
    left: -16,
    top: -6,
    position: 'relative', //'absolute',
    display: 'none',
    //maxHeight: 350,
  },

  mainImg: {
    maxWidth: '99%',
    maxHeight: '200px',
  },

  storagePanel: {
    position: 'relative',
    left: 6,
    top: -2,
    cursor: 'pointer',
  },

  stShowProductCart: {
    display: 'contents',
    cursor: 'pointer',
    '&:hover': {
      color: '#3f51b5',
    },
  },
}));

// lg={3}
// sm={6}
// xl={3}
// xs={12}
let addCountRoes = 20;

const PtoductsNewTableColumns = props => {
  /*const {className, data, addToCart, setDialogData, ...rest} = props;*/
  /*const {results, warehouses} = data;*/
  /*const handleChange = panel => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };*/
  /*const [expanded, setExpanded] = React.useState(false);*/

  /*const StyledBadgeIcon = withStyles(theme => ({
            badge: {
                top: 15,
                right: 18,
                backgroundColor: '#b0bec5',
                color: 'white',
                fontsize: 8
            },
        }))(Badge);*/

  const {
    typePage,
    type,
    data,
    setDialogData,
    setPhotosGallery,
    currencyMainContr,
    //currencyMainSite,
    currencyMain,
    cartAll,
    handleUuidInfo,
    sorting,
    //showProductCart,
    searchAvailability,
    listMyFavorite,
    promotionsByProductsList,
    row,
    sell_currency_id,
  } = props;
  const {results} = data;
  const {warehouses} = data;

  const classes = useStyles();

  const [countRows, setCountRows] = React.useState(addCountRoes);
  const [cartModalData, setCartModalData] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const addRows = () => {
    setCountRows(countRows + addCountRoes);
  };

  const {t} = useTranslation();

  let resultsMod = dataProcessing(
    currencyMain,
    results,
    cartAll,
    listMyFavorite,
    sorting,
    searchAvailability,
    promotionsByProductsList,
  ).results;

  let widthXS = [5, 7];
  let xs = 12;
  let sm = 6;
  let md = 6;
  if (typePage === 'cardVr') widthXS = 12;
  if (typePage === 'cardVr') md = 4;
  return (
    <>
      <div
        className={clsx(classes.root, 'product__list product-listing')}
        style={typePage === 'cardHr' ? {gap: 35} : {}}>
        {typePage === 'cardVr'
          ? resultsMod.map(
              (row, index) =>
                row.count > 0 &&
                index < countRows && (
                  <div
                    key={row.id}
                    className={clsx(
                      'product__item',
                      row.balance?.every(i => i.quantity === '0')
                        ? 'empty'
                        : '',
                    )}>
                    <ProductItem
                      setDialogData={setDialogData}
                      row={row}
                      promoLabel={row.promoLabel}
                    />
                  </div>
                ),
            )
          : resultsMod.map(
              (row, index) =>
                row.count > 0 &&
                index < countRows && (
                  <div
                    key={row.id}
                    className={clsx(
                      'product__item hr',
                      row.balance?.every(i => i.quantity === '0')
                        ? 'empty'
                        : '',
                    )}>
                    <ProductItemHr
                      setDialogData={setDialogData}
                      row={row}
                      promoLabel={row.promoLabel}
                    />
                  </div>
                ),
            )}
      </div>
      {countRows < resultsMod.length && (
        <button onClick={addRows} className="btn btn-big mx-auto my-4">
          <svg
            className="mr-2"
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.878 7.42578C15.2282 8.90675 15.0884 10.4613 14.4798 11.8561C13.8711 13.2509 12.8263 14.4106 11.5024 15.161C10.1785 15.9114 8.64688 16.212 7.13753 16.0178C5.62818 15.8235 4.22259 15.1448 3.13184 14.0836"
              stroke="#1F1F1F"
              strokeWidth="1.8"
              strokeLinecap="round"
            />
            <path
              d="M1.15846 10.5463C1.0529 10.0551 0.99979 9.55398 1.00001 9.05151C0.997234 7.67104 1.40104 6.32031 2.16104 5.16787C2.92104 4.01544 4.00359 3.11232 5.2736 2.57122C6.54361 2.03012 7.94484 1.875 9.30245 2.1252C10.6601 2.3754 11.9139 3.01986 12.9076 3.97815"
              stroke="#1F1F1F"
              strokeWidth="1.8"
              strokeLinecap="round"
            />
            <path
              d="M9.74756 4.28857L13.0032 4.0494L12.7491 1"
              stroke="#1F1F1F"
              strokeWidth="1.8"
              strokeLinecap="round"
            />
            <path
              d="M6.21387 13.7109L2.96118 13.9501L3.2153 16.9995"
              stroke="#1F1F1F"
              strokeWidth="1.8"
              strokeLinecap="round"
            />
          </svg>
          {t('common:show_another')}{' '}
          {resultsMod.length - countRows < addCountRoes
            ? resultsMod.length - countRows
            : addCountRoes}
        </button>
      )}
      {/* <AddToCartModal data={cartModalData} showModal={showModal} setShowModal={setShowModal}  /> */}
    </>
  );
};
class Wrapper extends React.Component {
  componentDidMount() {
    // console.log('componentDidMount==', this.props)
  }

  componentDidUpdate(prevProps) {
    // console.log('componentDidUpdate==', this.props)
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log('this.props==', this.props.dialogData);
    // console.log('nextProps==', nextProps.dialogData);
    //При открытии окна СЛАЙДЕНРА и ДОБАВЛЕНИЯ В КОРЗИНУ не перерисовывать окно поиска
    if (this.props.dialogData !== nextProps.dialogData) return false;
    else if (this.props.photosGallery !== nextProps.photosGallery) return false;
    else if (this.props.anchorElInfo !== nextProps.anchorElInfo) return false;
    else return true;
  }

  render() {
    return <PtoductsNewTableColumns {...this.props} />;
  }
}

function mapState(state) {
  const {listMyFavorite, promotionsByProductsList} = state;
  const {sell_currency_id} = state.userSelectedData;
  return {
    listMyFavorite,
    promotionsByProductsList,
    sell_currency_id,
  };
}

PtoductsNewTableColumns.propTypes = {
  //history: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
};

export default connect(mapState)(Wrapper);
//export default withRouter(PtoductsNewTableColumns);
