import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {useTranslation} from 'react-i18next';
import {Skype as SkypeIcon, Viber as ViberIcon} from '../../../../icons';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import RoomIcon from '@material-ui/icons/Room';
import FooterLists from './components/FooterLists/FooterLists';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Hidden from '@material-ui/core/Hidden';
import ContactLink from './components/ContactLink';
import FooterNav from './components/FooterNav/FooterNav';
import footer_logo from '../../../../images/logo_header_work.svg';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4),
  },
}));

const Footer = props => {
  const {className, frontendData, ...rest} = props;

  const classes = useStyles();
  const {t} = useTranslation();

  // let sb_phone_const = {
  //     title: '+??? (??) ???-??-??',
  //     href: '',
  //     icon: <PhoneIcon/>,
  //     icon2: '',
  // };

  let custom_text_phone = [
    {
      title: `+38 067 340-12-00 (${t('common:cargo_department')})`,
      href: 'tel:+38 067 340-12-00',
      icon: <PhoneIcon />,
      // icon2: <ViberIcon />,
    },
    // {
    //   title: '+38 067 360-12-00',
    //   href: 'tel:+38 067 360-12-00',
    //   icon: <PhoneIcon />,
    //   icon2: <ViberIcon />,
    // },
    {
      title: `+38 097 865-77-43 (${t('common:electronics_department')})`,
      href: 'tel:+38 097 865-77-43',
      icon: <PhoneIcon />,
      icon2: '',
    },
    {
      title: `+38 063 112-74-25 (${t('common:car_department')})`,
      href: 'tel:+38 063 112-74-25',
      icon: <PhoneIcon />,
      icon2: '',
    },
    {
      title: `+38 067 616-12-00 (${t('common:bookkeeping')})`,
      href: 'tel:+38 067 616-12-00',
      icon: <PhoneIcon />,
      icon2: '',
    },

    // {
    //     title: '+38 067 231-69-34',
    //     href: 'fax:+38 067 231-69-34',
    //     icon: <FaxIcon/>,
    //     icon2: '',
    // },
  ];

  // let last_number = 0;
  // let SbPhone = [];
  // SbPhone[0] = {...sb_phone_const};
  // let t_phone = [];
  // if (frontendData.phone_primary !== null) t_phone = frontendData.phone_primary;
  // else if (frontendData.phones_secondary !== null)
  //     t_phone = frontendData.phones_secondary;
  // else t_phone = [];
  //
  // console.log('t_phone=', t_phone);
  //
  // if (t_phone !== undefined) {
  //
  //     last_number = t_phone.length;
  //
  //     for (let i in t_phone) {
  //         sb_phone_const.title = t_phone[i].number;
  //         sb_phone_const.href = `tel:${t_phone[i].number}`;
  //         SbPhone[i] = {...sb_phone_const};
  //     }
  //
  //
  //     for (let i in custom_text_phone) {
  //         sb_phone_const.title = custom_text_phone[i].title;
  //         sb_phone_const.href = `tel:${custom_text_phone[i].href}`;
  //         SbPhone[i+last_number] = {...sb_phone_const};
  //     }
  //
  //
  // }

  // SbPhone[last_number+1]= {...custom_text_phone};

  const schedule_arr = [
    {title: t('common:schedule1'), href: '/'},
    {title: t('common:schedule2'), href: '/'},
    {title: t('common:schedule3'), href: '/'},
    {title: t('common:schedule4'), href: '/'},
    {title: t('common:schedule5'), href: '/'},
    {title: t('common:Shipment_order'), href: '/'},
  ];
  let contact_info_2 = [
    {
      title: 'daniparts',
      href: 'skype:daniparts?chat',
      icon: <SkypeIcon />,
      icon2: '',
    },
    {
      title: 'rviparts',
      href: 'skype:rviparts?chat',
      icon: <SkypeIcon />,
      icon2: '',
    },
    {
      title: 'rviparts@gmail.com',
      href: 'mailto:rviparts@gmail.com',
      icon: <AlternateEmailIcon />,
      icon2: '',
    },
    {
      title: t('common:company_location'),
      href: 'https://goo.gl/maps/YgFq3eiBRjyxmor5A',
      icon: <RoomIcon />,
      icon2: '',
      target: 'blank',
    },
  ];

  const SbCompanyInfo = [
    {
      title: t('common:about-us'),
      href: '/about-us',
      icon: '',
      icon2: '',
    },
    {
      title: t('common:delivery'),
      href: '/delivery',
      icon: '',
      icon2: '',
    },
    // {
    //     title: t('common:contacts'),
    //     href: '/contacts',
    //     icon: '',
    //     icon2: '',
    // },
    {
      title: t('common:payment'),
      href: '/payment',
      icon: '',
      icon2: '',
    },
    {
      title: t('common:warranty'),
      href: '/warranty',
      icon: '',
      icon2: '',
    },
    {
      title: t('common:return_terms'),
      href: '/return_terms',
      icon: '',
      icon2: '',
    },
    {
      title: t('common:user_agreement'),
      href: '/user_agreement',
      icon: '',
      icon2: '',
    },
  ];

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    // combine classes
    <footer
      {...rest}
      className={clsx(classes.root, className, 'footer')}
      id="footer">
      <div className="footer__top">
        <div className="wrapper-inner">
          <div className="row">
            <div className="col-12">
              <div className="footer__top-inner">
                <RouterLink to="/" className="footer__logo">
                  <img src={footer_logo} />
                </RouterLink>
                <FooterNavItems
                  pages={SbCompanyInfo}
                  title={t('common:information')}
                />
                <FooterNavItems
                  pages={custom_text_phone}
                  title={t('common:contacts')}
                />
                <FooterNavItems
                  pages={contact_info_2}
                  title={t('common:contacts')}
                />
                <FooterNavItems
                  pages={schedule_arr}
                  title={t('common:schedule')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bot d-xxl-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer__copyright">
                © 2010-{new Date().getFullYear()} | Daniparts, Inc. All Rights
                Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;

const FooterNavItems = ({pages, title}) => {
  return (
    <div className="footer__navs-item">
      <div className="footer__navs-title">{title}</div>
      <ul className="footer__nav">
        {pages.map(page => (
          <li key={page.title} className="d-flex gap-10">
            {page.icon ? (
              <>
                {page.icon}
                <RouterLink to={page.href}>{page.title}</RouterLink>
                {page.icon2}
              </>
            ) : (
              <RouterLink to={page.href}>{page.title}</RouterLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
