import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Timer from 'react-compound-timer';
import moment from 'moment';
import ProductList from '../ProductList/ProductList';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {searchActions} from '../../_actions';
import {useTranslation} from 'react-i18next';
import {BreadCrumbs} from '../../_components/BreadCrumbs/BreadCrumbs';
import {PromotionPageSkeleton} from './PromotionPageSkeleton';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    margin: '15px 0 0 0',

    '& .title': {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '160%',
      color: '#1F1F1F',
    },
    '& .timer-title': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '100%',
      color: '#1F1F1F',
      // textAlign: 'center',
      margin: '30px auto 17px',
    },
    '& .timer': {
      background: '#EBF0F8',
      borderRadius: 99,
      width: 70,
      height: 70,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 30,
      lineHeight: '100%',
      textAlign: 'center',
      color: '#414D5F',
      '& span': {
        fontWeight: 500,
        fontSize: 12,
        maxHeight: 25,
      },
    },
    '& .timers': {
      display: 'flex',
      // justifyContent: 'center',
      gap: 10,
    },
  },
}));

const PromotionPage = props => {
  const classes = useStyles();
  const {dataPromotionList} = props;
  const [promoData, setPromoData] = useState(dataPromotionList);
  const {t} = useTranslation();

  useEffect(() => {
    setPromoData(dataPromotionList);
  }, [dataPromotionList.loading]);

  const createMarkup = html => {
    return {__html: html};
  };

  let toDay = moment().valueOf();

  return promoData.loading === false ? (
    <>
      <BreadCrumbs
        items={[
          {title: t('common:Actions'), path: '/'},
          {title: promoData?.promotion_data?.name, path: '/'},
        ]}
      />
      <div className={clsx('wrapper-inner', classes.root)}>
        <div className="title mb-4">{promoData.promotion_data.name}</div>
        <div className="row">
          <div className="col-lg-4 col-12">
            <img
              style={{width: '100%'}}
              src={promoData.promotion_data.banner_tablet[0]}
            />
            {/* <div className="text-center">
              <div className="timer-title">
                {t('common:It_remained_until')}:
              </div>
              <Timer
                initialTime={
                  moment(promoData.promotion_data.promotion_date_to).valueOf() -
                  toDay
                }
                direction="backward">
                <div className="timers justify-center">
                  <div className="timer">
                    <Timer.Days /> <span>{t('common:days')}</span>
                  </div>
                  <div className="timer">
                    <Timer.Hours /> <span>{t('common:hours')}</span>
                  </div>
                  <div className="timer">
                    <Timer.Minutes /> <span>{t('common:minutes')}</span>
                  </div>
                  <div className="timer">
                    <Timer.Seconds /> <span>{t('common:seconds')}</span>
                  </div>
                </div>
              </Timer>
            </div> */}
          </div>
          <div className="col-lg-8 col-12 promotion-page-left">
            <div className="formated-text-wrapper">
              <div
                dangerouslySetInnerHTML={createMarkup(
                  promoData.promotion_data.promotion_formatted_description,
                )}
              />
            </div>
            <div className="">
              <div className="timer-title">
                {t('common:It_remained_until')}:
              </div>
              <Timer
                initialTime={
                  moment(promoData.promotion_data.promotion_date_to).valueOf() -
                  toDay
                }
                direction="backward">
                <div className="timers">
                  <div className="timer">
                    <Timer.Days /> <span>{t('common:days')}</span>
                  </div>
                  <div className="timer">
                    <Timer.Hours /> <span>{t('common:hours')}</span>
                  </div>
                  <div className="timer">
                    <Timer.Minutes /> <span>{t('common:minutes')}</span>
                  </div>
                  <div className="timer">
                    <Timer.Seconds /> <span>{t('common:seconds')}</span>
                  </div>
                </div>
              </Timer>
            </div>
          </div>
        </div>
      </div>
      <ProductList promotionLayout={true} />
    </>
  ) : (
    <PromotionPageSkeleton />
  );
};

const actionCreators = {
  searchPromotionList: searchActions.searchPromotionList,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.searchPromotionList(window.location.pathname.split('/')[2]);
  }

  render() {
    return <PromotionPage {...this.props} />;
  }
}

function mapState(state) {
  const {slug, id, dataPromotionList} = state;
  const {customer_id, sell_currency_id} = state.userSelectedData;
  return {slug, id, customer_id, sell_currency_id, dataPromotionList};
}

Wrapper.propTypes = {
  history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
