import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import PriceToStr from '../../../../_components/PriceToStr';
import {ProductTooltip} from '../../../../_components/Product/ProductTooltip';
import {promotionService} from '../../../../_services/promotion.service';
import IconDiscount from '../../../../images/icon_discount.svg';
import {dataProcessing} from '../../../../_helpers';
import {searchActions} from '../../../../_actions';
import {Link} from 'react-router-dom';

const CategoryListProduct = ({
  catalogProducts,
  groupId,
  setDialogData,
  empty,
  setIsPromoDataExsist,
  currencies,
  sell_currency_id,
  cart,
  listMyFavorite,
  searchAvailability,
  promotionsByProductsList,
  searchPromotions,
  fullWidth,
}) => {
  const [productList, setProductList] = useState([]);

  const {t} = useTranslation();
  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);
  let cartAll = cart && cart.list ? cart.list.all : [];

  useEffect(() => {
    promotionService.getPromoByGroupId(groupId).then(data => {
      if (data.length <= 0) {
        setIsPromoDataExsist(false);
      }
      setProductList(data);
    });
  }, []);
  useEffect(() => {
    if (productList.length > 0) {
      searchPromotions(productList.map(item => item.product_id));
    }
  }, [productList]);

  const resultsMod = dataProcessing(
    currencyMain,
    productList,
    cartAll,
    listMyFavorite,
    1,
    searchAvailability,
    promotionsByProductsList,
  ).results;

  const handleAddToCart = (e, row) => {
    e.preventDefault();
    if (empty) return;
    setDialogData({row, indexRows: '', type: 'promoProducts'});
  };

  return (
    <Swiper
      slidesPerView={1}
      modules={[Pagination]}
      pagination={{clickable: true}}
      className="product-column slider"
      style={fullWidth ? {maxWidth: 'none'} : {}}
      spaceBetween={20}>
      {resultsMod.map((item, index) => (
        <SwiperSlide key={index + item.article}>
          <Link
            to={`/product/${item.brand}/${item.article}`}
            className="product-item">
            <div className="product__img promo">
              <img src={item.images.thumb} alt="product-image" />
              {item.promoLabel && (
                <span
                  style={{
                    background: '#' + item.promoLabel[0].badge_rgb_code,
                    color: 'white',
                  }}
                  className="info">
                  {item.promoLabel[0].badge_name}
                </span>
              )}
              {item?.isDiscount ? (
                <span
                  // style={item.badge_name ? {top: 27, right: 0} : {}}
                  className="percent">
                  – {item?.isDiscount}%
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="product-inner">
              <div className="product__title">{item.article}</div>
              <div className="d-flex justify-content-between">
                <span className="product-code">{item.brand}</span>
                <span className="product-min-part">{item.weight} кг</span>
              </div>
              <div className="product-end">
                <div className="line"></div>
                <div className="product-footer">
                  <div className="price">
                    {/* {item.price} {currency === 2 ? 'грн' : '$'} */}
                    {currencyMain && currencyMain.simbol && (
                      <div style={{display: 'inline-grid', textAlign: 'right'}}>
                        {item.isDiscount !== 0 && (
                          <PriceToStr
                            stAll={{marginBottom: -10}}
                            stSumma={{
                              fontStyle: 'normal',
                              fontSize: 12,
                              fontWeight: 'normal',
                              color: '#546e7a',
                              textDecoration: 'line-through',
                            }}
                            data={
                              item.offers
                                ? item.offers[0].priceCurrent
                                : item.regularPrice
                            }
                            beforeText={''}
                            text={currencyMain.simbol}
                            grn={t('common:uah')}
                          />
                        )}
                        <PriceToStr
                          stSumma={{
                            fontStyle: 'normal',
                            color: item.isDiscount !== 0 && 'red',
                          }}
                          // data={item.offers ? item.offers[0].price * currencyMainContr.koef : item.price * currencyMainContr.koef}
                          data={
                            item.offers
                              ? item.offers[0].priceCurrent
                              : item.priceCurrent
                          }
                          beforeText={''}
                          text={currencyMain.simbol}
                          grn={t('common:uah')}
                        />
                      </div>
                    )}
                    {item.discounts_by_quantity_sum_of_order &&
                    item.discounts_by_quantity_sum_of_order.best_discount !==
                      undefined &&
                    item.discounts_by_quantity_sum_of_order.best_discount
                      .discount_conditions_document__promotion_type !==
                      'NO_CONDITION' ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <img
                            style={{width: '20px', margin: '0 5px 0 0'}}
                            src={IconDiscount}
                            alt={'IconDiscount'}
                          />
                          <span
                            style={{
                              fontWeight: 'bold',
                              fontSize: 12,
                              margin: '0 5px 0 0',
                            }}>
                            {t('common:best_price')}
                          </span>
                        </div>
                        <PriceToStr
                          stSumma={{
                            fontStyle: 'normal',
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: '600',
                          }}
                          data={
                            item.discounts_by_quantity_sum_of_order
                              .best_discount.price_with_discount
                          }
                          beforeText={''}
                        />
                      </div>
                    ) : null}
                  </div>
                  <ProductTooltip
                    title={
                      <ul className="single-product-list">
                        {item?.balance?.map(i => (
                          <li
                            key={i.name}
                            className={i.quantity <= 0 ? 'disabled' : ''}>
                            <span className="title">{i.name}</span>
                            <span className="count">{i.quantity} шт</span>
                          </li>
                        ))}
                      </ul>
                    }>
                    <button
                      className="btn"
                      onClick={e => handleAddToCart(e, item)}>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M0.304453 0.654591C0.49957 0.454903 0.778008 0.344864 1.05645 0.348731C1.87699 0.348731 2.7225 0.519591 3.41332 0.980841C3.95227 1.33697 4.36184 1.871 4.59633 2.47076C4.72289 2.77803 4.79637 3.10322 4.86 3.42877C5.30719 5.68791 5.75227 7.94776 6.21633 10.2037C6.29121 10.5493 6.47402 10.8844 6.77109 11.0865C7.11879 11.3375 7.55789 11.4237 7.98012 11.4237C9.72738 11.4258 11.475 11.4212 13.2223 11.4258C13.5197 11.0809 13.7602 10.6928 13.9971 10.3053C14.3768 9.68939 14.7104 9.04709 15.0525 8.41006C15.4093 7.75932 15.7127 7.08115 16.0555 6.42303C16.2605 6.03631 16.7295 5.81904 17.1577 5.90729C17.6295 5.9899 17.9975 6.43252 18 6.911V6.97252C17.9845 7.13108 17.9395 7.28576 17.8703 7.42885C17.216 8.79854 16.5414 10.1619 15.7419 11.4535C15.4009 11.99 15.0441 12.5237 14.5948 12.9772C14.3205 13.2497 13.9771 13.5067 13.5714 13.4975C11.707 13.5035 9.84234 13.4993 7.97801 13.4996C7.14656 13.5014 6.30141 13.2873 5.6148 12.8074C4.88426 12.3092 4.37379 11.5161 4.18992 10.6537C3.70934 8.33693 3.25512 6.01486 2.79422 3.69385C2.73727 3.39854 2.64937 3.09338 2.43914 2.86803C2.22469 2.63072 1.90898 2.51787 1.60031 2.46865C1.26105 2.4008 0.891211 2.47674 0.574453 2.31045C0.237656 2.14697 0.0158203 1.79049 0 1.41854V1.34049C0.0189844 1.08561 0.120234 0.834591 0.304453 0.654591Z"
                          fill="#1F1F1F"
                        />
                        <path
                          d="M6.94234 14.2255C7.36633 14.1379 7.81563 14.2339 8.18266 14.4582C8.54617 14.6867 8.82426 15.0527 8.93324 15.4696C9.15649 16.1991 8.79613 17.0464 8.13449 17.4159C7.74109 17.6448 7.25137 17.7228 6.81297 17.5868C6.39004 17.4753 6.01809 17.1905 5.79273 16.8168C5.54453 16.4059 5.46227 15.8852 5.61836 15.4264C5.78992 14.8193 6.32676 14.3464 6.94234 14.2255Z"
                          fill="#1F1F1F"
                        />
                        <path
                          d="M13.165 14.227C13.5724 14.1415 14.0073 14.2224 14.3655 14.4312C15.0402 14.8088 15.3998 15.6807 15.1509 16.4193C14.9523 17.1358 14.2404 17.6586 13.4996 17.6529C13.0155 17.6512 12.5332 17.436 12.2129 17.0721C11.8934 16.7164 11.7292 16.2238 11.7798 15.7471C11.8304 15.2996 12.0554 14.8703 12.4091 14.5884C12.6239 14.4034 12.8911 14.2913 13.165 14.227Z"
                          fill="#1F1F1F"
                        />
                        <rect
                          x="7.875"
                          y="5.625"
                          width="5.625"
                          height="2.25"
                          rx="1.125"
                          fill="#1F1F1F"
                        />
                      </svg>
                    </button>
                  </ProductTooltip>
                </div>
              </div>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const mapState = state => {
  const {currencies} = state.frontendData;
  const {sell_currency_id} = state.userSelectedData;
  const {cart, listMyFavorite, promotionsByProductsList} = state;
  const {searchAvailability} = state.searchParams;
  return {
    currencies,
    sell_currency_id,
    cart,
    listMyFavorite,
    searchAvailability,
    promotionsByProductsList,
  };
};
const actionCreators = {
  searchPromotions: searchActions.searchPromotions,
};

export default connect(mapState, actionCreators)(CategoryListProduct);
