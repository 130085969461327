import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {SliderNoElements} from './SliderNoElements';

const useStyles = makeStyles(theme => ({
  black: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // background: 'rgba(31, 31, 31, 0.65)',
    // backgroundColor: 'rgba(31, 31, 31, 0.65)',
    backgroundColor: '#000',
    zIndex: -1,

    '& img': {
      opacity: 0.6,
      position: 'relative',
      zIndex: 1,
    },
  },
}));

export const SliderItem = props => {
  const history = useHistory();
  const {t} = useTranslation();
  const classes = useStyles();
  const {
    path,
    name,
    image,
    banner_subtitle,
    banner_title,
    type_name,
    banner_blackout,
    disable_link_button,
    ...other
  } = props;
  const sliderTitleArray = name?.split(' ');

  const handleNewsRoute = () => {
    history.push(path);
  };
  return (
    <div
      className={clsx(
        // banner_blackout ? classes.black : '',
        'home-slider__item swiper-slide',
      )}>
      <div className={image && banner_blackout ? classes.black : ''}>
        {image ? (
          <img className="home__slider-image" src={image} alt={type_name} />
        ) : (
          <SliderNoElements />
        )}
      </div>
      <div className="wrapper-inner">
        <div className="home-slider__title">
          {/* <span>{sliderTitleArray[0]}</span>  */}
          {banner_title ?? banner_title}
        </div>
        <div className="home-slider__description">
          {banner_subtitle ?? banner_subtitle}
        </div>
        {!disable_link_button && other.url_repr_text !== '' ? (
          <div onClick={handleNewsRoute} className="btn btn-home-slider">
            {other.url_repr_text}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
