import {userConstants} from '../_constants';
import { wishlistService } from '../_services/wishlist.service';

export const wishlistActions = {
   getWishlistItems,
};

function getWishlistItems(list) {
  return dispatch => {
    dispatch(request());
    wishlistService.getWishlistItems(list).then(
      data => {
        dispatch(success(data.data));
      },
      error => {
        dispatch(failure(error));
      },
    );
  };

  function request() {
    return {type: userConstants.GET_WISHLIST_ITEMS_REQUEST};
  }

  function success(data) {
    return {type: userConstants.GET_WISHLIST_ITEMS_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.GET_WISHLIST_ITEMS_FAILURE, error};
  }
}
