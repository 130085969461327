import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {ProductHeader} from '../../_components/Product/ProductHeader';
import {ProductList} from '../../_components/Product/ProductList';
import {Banner} from './components/Banner';
import {PromotionItem} from './components/PromotionItem';
import {CategoryList} from './components/CategoryItems/CategoryList';
import {connect} from 'react-redux';
import {BannerSkeleton} from './components/BannerSkeleton';
import {useParams} from 'react-router-dom';
import AddToCartDialog from '../ProductList/components/AddToCartDialog/AddToCartDialog';
import {getCurrencyContrData} from '../../_helpers';
import {useTranslation} from 'react-i18next';
import {promotionActions} from '../../_actions/promotion.actions';
import {productsActions} from '../../_actions/product.actions';
import {cartActions} from '../../_actions';
import {Helmet} from 'react-helmet';

const promotionSlidderBreakpoints = {
  550: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  780: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1050: {
    slidesPerView: 4,
  },
  1500: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
};

const Category = props => {
  const {
    catalogTree,
    sell_currency_id,
    currencies,
    main_currency,
    customer_data,
    searchByOtherOwn,
    authentication,
    promotionItems,
    getPromotions,
    addToCart,
  } = props;

  const {t} = useTranslation();
  const {pageName, groupId} = useParams();
  const catalogResult = catalogTree?.data?.result.find(
    item => item.url_repr === pageName,
  );

  const [dialogData, setDialogData] = useState({});
  const [promotionSliderInfo, setPromotionSliderInfo] = useState(null);
  const [promotionSliderIndex, setPromotionSliderIndex] = useState(0);

  useEffect(() => {
    getPromotions(+groupId);
  }, []);

  const handleClose = props => {
    const {offers, localMainCartId} = props;
    if (offers) {
      Object.keys(offers).map(
        key =>
          offers[key].quantity > 0 &&
          addToCart(
            localMainCartId,
            key,
            offers[key].price,
            offers[key].quantity,
          ),
      );
    }
    setDialogData({});
  };
  const customer_group_id = customer_data && customer_data.discount_group_id;

  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);

  const currencyMainContr = getCurrencyContrData(
    currencyMain,
    main_currency,
    sell_currency_id,
    customer_data,
  );

  return (
    <>
      <div className="banners">
        <div className="wrapper-inner">
          <div
            className="banners-inner_1"
            style={
              promotionItems?.results?.length === 0
                ? {gridTemplateColumns: '1fr'}
                : {}
            }>
            {catalogTree?.data &&
            promotionItems?.results &&
            !promotionItems.loading ? (
              <>
                <Banner
                  catalogResult={catalogResult}
                  catalogTree={catalogTree}
                />
                {promotionItems.results.length > 0 && (
                  <div className="products-block product product-9 pt-0 pb-0">
                    <div className="product__list-wrapper">
                      <div className="product__list">
                        <Swiper
                          onSlideChange={e =>
                            setPromotionSliderIndex(e.activeIndex)
                          }
                          onSwiper={e => setPromotionSliderInfo(e)}
                          breakpoints={promotionSlidderBreakpoints}
                          spaceBetween={20}
                          style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                          }}>
                          <ProductHeader
                            slider={promotionSliderIndex + 1}
                            title={t('common:Actions')}
                            totalCount={promotionSliderInfo?.snapGrid?.length}
                          />
                          {promotionItems?.results?.map((item, index) => (
                            <SwiperSlide key={index} className="product__item">
                              <PromotionItem promotionInfo={item} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <BannerSkeleton />
            )}
          </div>
        </div>
      </div>
      {catalogResult?.sub_items?.map((item, index) => (
        <CategoryList
          index={index}
          subItem={item}
          key={item.id}
          setDialogData={setDialogData}
          currencyMain={currencyMain}
        />
      ))}
      <AddToCartDialog
        currencyMainContr={currencyMainContr}
        currencyMain={currencyMain}
        dialogData={dialogData}
        handleClose={handleClose}
        priorityParams={searchByOtherOwn.warehouses}
        customerGroupId={customer_group_id}
        authentication={authentication}
        discountsByProduct={
          dialogData.row && dialogData.row.discounts_by_quantity_sum_of_order
            ? dialogData.row.discounts_by_quantity_sum_of_order
            : dialogData.row && dialogData.row.balance[0] !== undefined
            ? dialogData.row &&
              dialogData.row.balance[0].discounts_by_quantity_sum_of_order
            : ''
        }
      />
    </>
  );
};

function Wrapper(props) {
  // componentDidUpdate() {
  //   // const {groupId} = useParams();
  //   const groupId = this.props.match.params.groupId;
  //   if (this.catalogTree) {
  //     this.pageData = this.catalogTree.results.find(
  //       item => item.id === groupId,
  //     );
  //   }
  //   console.log(this.pageData);
  //   // this.groupId = groupId;
  // }
  // render() {
  const {pageName} = useParams();
  const catalogResult = props.catalogTree?.data?.result.find(
    item => item.url_repr === pageName,
  );
  return (
    <>
      <Helmet>
        <meta name="description" content={catalogResult?.meta_desc} />
        <meta name="keywords" content={catalogResult?.meta_keywords} />
        <title>{catalogResult?.meta_title}</title>
      </Helmet>
      <Category {...props} />;
    </>
  );
  // }
}

const actionCreators = {
  getPromotions: promotionActions.getPromotionsByGroupId,
  getBoundProductsByGroupId: productsActions.getBoundProductsByGroupId,
  addToCart: cartActions.addToCart,
};

const mapState = state => {
  const {searchByOtherOwn, authentication, catalogTree, promotionItems} = state;

  const {customer_id, sell_currency_id} = state.userSelectedData;
  const {currencies, main_currency, user_perms} = state.frontendData;
  const {customer_data} = state.contragentData;
  const {product} = state;

  return {
    customer_id,
    sell_currency_id,
    currencies,
    main_currency,
    user_perms,
    searchByOtherOwn,
    authentication,
    catalogTree,
    customer_data,
    promotionItems,
    product,
  };
};

export default connect(mapState, actionCreators)(Wrapper);
