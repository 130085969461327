import {makeStyles, Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles(theme => ({
  skeleton_innner: {
    width: '100%',
    height: 390,
    backgroundColor: '#DBDDE0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .wrapper-inner': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      gap: 20,
    },

    '& .wrapper-info': {
      width: '100%',
    },
    '& .MuiSkeleton-rect': {
      borderRadius: 4,
    },
    '& .home-slider__description': {
      margin: '20px 0',
    },
    '& .btn-home-slider': {
      height: 50,
      borderRadius: 40,
      marginTop: 30,
    },
  },
}));

export const SliderSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.skeleton_innner}>
      <div className="wrapper-inner">
        <div className="wrapper-info">
          <Skeleton
            variant="rect"
            className="home-slider__title"
            style={{maxWidth: 1300}}
          />
          <Skeleton
            variant="rect"
            style={{maxWidth: 800}}
            className="home-slider__description"
          />
          <Skeleton variant="rect" className="btn-home-slider" />
        </div>
      </div>
    </div>
  );
};
