import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
//import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useDebouncer} from '../../../hooks/useDebouncer';
import {catalogActions, searchActions} from '../../../_actions';
import {brandsActions} from '../../../_actions/brands.actions';
import {BreadCrumbs} from '../../../_components/BreadCrumbs/BreadCrumbs';
import axInst from '../../../_helpers/axios-instance';
import ProductList from '../../ProductList';
import BrandsRangeFilter from './components/BrandsRangeFilter';

const BrandList = props => {
  const {url_repr} = useParams();
  const {t} = useTranslation();
  const [brand, setBrand] = useState({});
  const [searchText, setSearchText] = useState('');
  const [value, setValue] = useState([0, 100]);
  const [catalogByGroup, setCatalogByGroup] = useState(props.catalogByGroup);

  const debouncedSearchTerm = useDebouncer(searchText, 600);
  const debouncedFilterTerm = useDebouncer(value, 600);

  let filteredResult = props.catalogByGroup.results;

  useEffect(() => {
    axInst
      .post('/api/get-trademarks-detail/', {url_repr})
      .then(res => setBrand(res.data.data));
  }, []);

  const createHtml = html => ({
    __html: html,
  });

  useEffect(() => {
    setCatalogByGroup(props.catalogByGroup);
    setValue(getMinAndMaxPrice(props.catalogByGroup.results));
  }, [props.catalogByGroup]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      filteredResult = filteredResult?.filter(item =>
        item.name.toUpperCase().includes(searchText.toUpperCase()),
      );
    }
    if (debouncedFilterTerm) {
      filteredResult = filteredResult?.filter(item => {
        if (item.price > value[0] && item.price < value[1]) {
          return true;
        }
      });
    }
    setCatalogByGroup({
      ...props.catalogByGroup,
      results: filteredResult,
    });
  }, [debouncedSearchTerm, debouncedFilterTerm]);

  const handleChangeRange = (e, value) => {
    setValue(value);
  };

  const renderBrandsFilter = () => {
    return (
      <>
        <div className="filter-block">
          <h3 className="filter-title mb-4">{t('common:result_search')}</h3>
          <form className="filter__search mb-4">
            <input
              type="search"
              placeholder={t('common:result_search')}
              className="filter__search-input"
              onChange={e => setSearchText(e.target.value)}
            />
            <button className="filter__search-btn">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.13371 11.2674C8.96898 11.2674 11.2674 8.96898 11.2674 6.13371C11.2674 3.29844 8.96898 1 6.13371 1C3.29844 1 1 3.29844 1 6.13371C1 8.96898 3.29844 11.2674 6.13371 11.2674Z"
                  strokeWidth="1.6"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.61383 9.90625L14.3108 15.0003"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </form>
        </div>
        <div className="filter-block">
          <BrandsRangeFilter
            title={t('common:price')}
            handleChange={handleChangeRange}
            value={value}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={brand?.meta_desc} />
        <meta name="keywords" content={brand?.meta_keywords} />
        <title>{brand?.meta_title}</title>
      </Helmet>
      <main>
        <BreadCrumbs items={[{title: brand.name, path: url_repr}]} />
        <div className="wrapper-inner">
          <h3 className="title">{brand.name}</h3>
          <div className="btnd__list d-flex items-center gap-10 my-8">
            <div className="row">
              <div className="col-xxl-2 col-lg-4 col-12">
                <div
                  className="d-flex items-center p-2"
                  style={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                  }}>
                  <img src={brand?.image} />
                </div>
              </div>
              <div className="col-xxl-10 col-lg-8 col-12">
                <ul className="delivery-list">
                  {brand?.additional_info?.map((item, index) => (
                    <li key={index} className="delivery-item">
                      <div className="icon">
                        <img src={item.image} />
                      </div>
                      <div className="content">
                        <div className="title">{item.text}</div>
                      </div>
                    </li>
                  ))}
                </ul>
                <div
                  className="brand__info my-8"
                  dangerouslySetInnerHTML={createHtml(brand?.HTML)}
                />
              </div>
            </div>
          </div>
        </div>
        <ProductList
          brandCatalogByGroup={catalogByGroup}
          renderBrandsFilter={renderBrandsFilter}
          brandsLayout={true}
        />
      </main>
    </>
  );
};

const getMinAndMaxPrice = arr => {
  const numsArr = [];
  for (let i = 0; i < arr?.length; i++) {
    numsArr.push(arr[i].price);
  }
  return [Math.min(...numsArr), Math.max(...numsArr)];
};

//href={`${'/brand_list/'}${partnersList.url_repr}`}
function mapState(state) {
  const {
    catalogPage,
    catalogByGroup,
    brands,
    //cart,
  } = state;

  //const {customer_id, sell_currency_id} = state.userSelectedData;
  //const {currencies} = state.frontendData;

  return {
    //customer_id,
    //sell_currency_id,
    //currencies,
    catalogPage,
    catalogByGroup,
    brands,
    //cart,
  };
}

const actionCreators = {
  getCatalogByGroup: catalogActions.getCatalogByGroup,
  searchPromotionList: searchActions.searchPromotionList,
};

class Wrapper extends React.Component {
  componentDidMount() {
    // console.log('this.props.match',this.props.match);
    if (this.props.match.params && this.props.match.params.url_repr)
      this.props.getCatalogByGroup('setData', 0, '/', [
        this.props.match.params.url_repr,
      ]);
    // this.props.searchPromotionList('pri-kupivli-3-h-dzerkal-perfekt-light-podarunok-katalog-dzerkal-ta-komplektuyuchih', 4)
  }

  componentDidUpdate(prevProps) {}

  render() {
    //return <CatalogList {...this.props} />;
    return <BrandList {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
