import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {connect} from 'react-redux';
import {customerActions} from '../_actions/customer.actions';
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";

import CircularProgress from "@material-ui/core/CircularProgress";
import axInst from "../_helpers/axios-instance";

// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(0),
        /*minWidth: 120,*/
        maxWidth: '280px',
    },
    labelsel: {
        color: 'inherit!important',
    },

    select: {
        /*marginTop: '6px!important',*/
        color: 'inherit',
        fontSize: 16,
        '&:before': {
            borderColor: 'inherit!important',
            border: 'none',
        },
        '&:after': {
            borderColor: 'inherit!important',
            border: 'none',
        },
        '&:hover': {
            borderColor: 'inherit!important',
            border: 'none',
        },
    },
    icon: {
        fill: 'inherit',
    },


}));

let countCustomers = 0;
// let maxCountCustomers = 0;

const SelectCompany = props => {
    const classes = useStyles();
    const {userSelectedData, setCustomerData, userBalance, NewContragent} = props;
    const {current_balance} = userBalance;
    const {
        //customer_id: customerId,
        customer_name: customerName,
        customer_code: customerCode
    } = {...userSelectedData};

    // console.log('userSelectedData',userSelectedData);
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [strSearch, setStrSearch] = React.useState('');
    const [strSearchOld, setStrSearchOld] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [inputValue, setInputValue] = React.useState(customerName);

    /*const handleChange = event => {
        const customer = customers.find(e => e.id === event.target.value);
        setCustomerData(customer.id, customer.text, customer.code);
    };*/

    React.useEffect(() => {
        let active = true;

        if (loading) {
            (async () => {
                const contr = await axInst.post(
                    '/api/user-select2/',
                    JSON.stringify({
                        _type: 'query',
                        term: strSearch,
                    }),
                );
                //await sleep(1e3); // For demo purposes.
                if (active) {
                    setLoading(false);
                    // console.log(contr.data.results);
                    countCustomers = contr.data.results.length;
                    // if (strSearch === '') maxCountCustomers = countCustomers;
                    /*contr.data.results.map((item) => {
                        item.text = item.text + ' / ' + item.discount_group;
                        return true;
                    });*/
                    setOptions(contr.data.results);
                }
            })();

            return () => {
                active = false;
            };
        } else return undefined;
    }, [loading, strSearch]);

    React.useEffect(() => {
        if (open) {
            setLoading(true);
        } else {
            setStrSearch('');
        }
    }, [open]);


    const onSelect = (option, sourceAr) => {
        // console.log('onSelect-target=', option.target);
        // console.log('onSelect-value=', option.target.value);

        if (sourceAr) {
            let customer = sourceAr.find(
                x => x.text === option.target.value,
            );
            // console.log('Нашли==', customer);
            if (customer) {
                setInputValue(option.target.value);
                setCustomerData(
                    customer.id,
                    customer.text,
                    customer.code,
                    customer.discount_group + '; ' + customer.credit_limit_currency
                );
                NewContragent();
            }
        }
    };
    const onInputChange = (option) => {
        // console.log('onInputChange-target=', option.target);
        // console.log('onInputChange-value=', option.target.value);
        // console.log('onInputChange-name=', option.target.name);
        if (!option) return true;
        if (option.target.value === 0 || option.target.value === undefined) {
            //Что-то выбрали
        } else {
            setStrSearchOld(strSearch);
            setStrSearch(option.target.value);
            if (strSearch.indexOf(strSearchOld) >= 0 && countCustomers < 30 && option.target.value !== '') {
                setLoading(false);
            } else {
                setLoading(true);
            }
        }
    };

    return (
        <FormControl className={classes.formControl}>
            <Autocomplete
                id="mainHeaderSearchSelect"
                //inputValue={''}
                style={{width: 280}}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                getOptionSelected={(option, value) => option.text === value.text}
                getOptionLabel={option => option.text}
                options={options}
                loading={loading}

                onSelect={(option) => onSelect(option, options)}
                onInputChange={(option) => onInputChange(option)}

                renderInput={params => (
                    <TextField
                        error={current_balance === undefined || customerCode === null}
                        {...params}
                        label={inputValue.length > 24 ? inputValue.slice(0, 24) + '...' : inputValue}
                        placeholder="поиск контрагента"
                        fullWidth
                        margin={"normal"}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </FormControl>
    );
};

SelectCompany.propTypes = {
    /*
          className: PropTypes.string,
          onClose: PropTypes.func,
          open: PropTypes.bool.isRequired,
          variant: PropTypes.string.isRequired
      */
};

class Wrapper extends React.Component {
    componentDidMount() {
        //this.props.getCustomers()
    }

    render() {
        return <SelectCompany {...this.props} />
    }
}

function mapState(state) {
    const {userSelectedData, userBalance} = state;
    return {userSelectedData, userBalance};
}

const actionCreators = {
    getCustomers: customerActions.getCustomers,
    setCustomerData: customerActions.setCustomerData,
};

export default connect(mapState, actionCreators)(Wrapper);
