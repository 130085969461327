import PropTypes from 'prop-types';
import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  cls1: {
    fill: '#dedede',
  },

  cls2: {
    fill: '#b3b3b3',
  },
  cls3: {
    fill: '#c9c9c9',
  },
  cls4: {
    fill: '#ecb000',
  },
  cls5: {
    fill: '#d7a000',
  },
  cls6: {
    fill: '#ffbe00',
  },
  cls7: {
    fill: '#d9d9d9',
  },
  cls8: {
    fill: '#315efb',
  },
  cls9: {
    fill: '#008cb4',
    fontSize: 11.44,
    // fontFamily: 'MyriadPro-Regular, Myriad Pro',
  },
  cls10: {
    fill: 'none',
    stroke: '#ffbe00',
    strokeLinecap: 'round',
    strokeMiterlimit: 10,
    strokeWidth: '0.8',
  },
  cls11: {
    fill: '#008cb4',
    // fontFamily: 'MyriadPro-Regular, Myriad Pro',
    fontSize: 11.44,
  },
}));

export default function Packing(props) {
  const {height, width, length} = props;
  const classes = useStyles();
  // const {t} = useTranslation();

  return (
    <SvgIcon {...props} viewBox={'0 0 293.21 208.4'}>
      <g id="Слой_2" data-name="Слой 2">
        <g id="Layer_1" data-name="Layer 1">
          <polygon
            className={classes.cls1}
            points="177.02 195.06 23.71 146.9 23.71 32.65 177.02 65.29 177.02 195.06"
          />
          <polygon
            className={classes.cls2}
            points="269.51 142.48 177.02 195.06 177.02 65.29 269.51 20.78 269.51 142.48"
          />
          <polygon
            className={classes.cls3}
            points="23.71 32.65 117.28 0 269.51 20.78 177.02 65.29 23.71 32.65"
          />
          <polygon
            className={classes.cls4}
            points="68.2 17.12 212.69 48.77 215.66 100.5 220.75 107.92 223.26 98.38 226.68 101.35 230.08 94.56 232.62 98.38 229.35 40.11 80.94 12.68 68.2 17.12"
          />
          <polygon
            className={classes.cls5}
            points="86.69 46.06 177.02 8.15 192.38 10.25 105.72 50.11 86.69 46.06"
          />
          <polygon
            className={classes.cls6}
            points="86.69 46.06 86.69 166.68 105.72 172.66 105.72 50.11 86.69 46.06"
          />
          <path
            className={classes.cls7}
            d="M211,162.2c0,1.4-1,1.88-2.54,2.54l-15.69,7.92c-2.12,1.06-2.54-1.14-2.54-2.54V146c0-1.41.91-1.74,2.54-2.55l15.69-7.92c3.32-1.69,2.33,1.76,2.54,8.27Z"
          />
          <polygon
            className={classes.cls8}
            points="191.82 148.33 207.4 140.76 207.4 141.63 191.82 149.46 191.82 148.33"
          />
          <polygon
            className={classes.cls8}
            points="191.82 151.31 207.4 143.74 207.4 144.61 191.82 152.44 191.82 151.31"
          />
          <polygon
            className={classes.cls8}
            points="191.82 154.65 207.4 147.09 207.4 147.96 191.82 155.78 191.82 154.65"
          />
          <polygon
            className={classes.cls8}
            points="192.19 163.83 202.03 159.04 202.16 159.95 192.19 164.96 192.19 163.83"
          />
          <polygon
            className={classes.cls8}
            points="192.19 166.09 202.03 161.3 202.16 162.21 192.19 167.22 192.19 166.09"
          />
          <polygon
            className={classes.cls8}
            points="192.19 168.4 202.03 163.61 202.16 164.52 192.19 169.53 192.19 168.4"
          />
          <text
            className={classes.cls9}
            transform="matrix(0.91, -0.54, 0.51, 0.86, 235.61, 184.65)">
            {width}
          </text>
          <line
            className={classes.cls10}
            x1="288.54"
            y1="150.12"
            x2="268.59"
            y2="162"
          />
          <polygon
            className={classes.cls6}
            points="286.48 149.77 288.33 150.25 287.86 152.1 288.85 151.52 289.32 149.66 287.46 149.19 286.48 149.77"
          />
          <line
            className={classes.cls10}
            x1="201.57"
            y1="202.07"
            x2="228.39"
            y2="186.32"
          />
          <polygon
            className={classes.cls6}
            points="203.63 202.43 201.78 201.95 202.26 200.09 201.27 200.67 200.78 202.53 202.64 203.01 203.63 202.43"
          />
          <line
            className={classes.cls10}
            x1="177.01"
            y1="194.74"
            x2="205.66"
            y2="204.66"
          />
          <line
            className={classes.cls10}
            x1="269.51"
            y1="142.15"
            x2="292.06"
            y2="149.96"
          />
          <text
            className={classes.cls11}
            transform="matrix(0, -1.21, 1, 0, 289.1, 93.77)">
            {height}
          </text>
          <line
            className={classes.cls10}
            x1="285.32"
            y1="49.3"
            x2="285.32"
            y2="15.63"
          />
          <polygon
            className={classes.cls6}
            points="286.68 17.22 285.32 15.86 283.97 17.22 283.97 16.07 285.32 14.71 286.68 16.07 286.68 17.22"
          />
          <line
            className={classes.cls10}
            x1="285.46"
            y1="132.96"
            x2="285.46"
            y2="102.55"
          />
          <polygon
            className={classes.cls6}
            points="286.82 131.37 285.46 132.73 284.1 131.37 284.1 132.52 285.46 133.88 286.82 132.52 286.82 131.37"
          />
          <line
            className={classes.cls10}
            x1="269.45"
            y1="21.03"
            x2="290.12"
            y2="11.46"
          />
          <line
            className={classes.cls10}
            x1="269.45"
            y1="142.07"
            x2="290.12"
            y2="132.5"
          />
          <text
            className={classes.cls9}
            transform="translate(67.67 180.46) rotate(18.05) scale(1.06 1)">
            {length}
          </text>
          <line
            className={classes.cls10}
            x1="110.93"
            y1="190.23"
            x2="156.38"
            y2="204.78"
          />
          <polygon
            className={classes.cls6}
            points="154.44 205.58 156.15 204.7 155.27 203 156.37 203.35 157.25 205.06 155.54 205.93 154.44 205.58"
          />
          <line
            className={classes.cls10}
            x1="8.31"
            y1="157.41"
            x2="60.68"
            y2="174.46"
          />
          <polygon
            className={classes.cls6}
            points="9.4 159.19 8.53 157.48 10.24 156.61 9.15 156.26 7.44 157.13 8.31 158.84 9.4 159.19"
          />
          <line
            className={classes.cls10}
            x1="176.85"
            y1="194.76"
            x2="153.45"
            y2="208"
          />
          <line
            className={classes.cls10}
            x1="23.8"
            y1="146.85"
            x2="0.4"
            y2="160.09"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

Packing.propTypes = {
  height: PropTypes.any,
  length: PropTypes.any,
  width: PropTypes.any,
};
