import React from 'react';

export const TableProductItem1 = props => {
  const {order, setSelectedResults, selectedResults} = props;

  const handleChange = e => {
    if (e.target.checked) {
      setSelectedResults(prevState => [...prevState, order]);
    } else {
      setSelectedResults(prevState =>
        prevState.filter(item => item.order__id !== order.order__id),
      );
    }
  };
  // console.log(selectedResults?.find(item => item.uuid === order.uuid));
  // console.log(selectedResults);
  // console.log(order);
  return (
    <div className="row mx-0 py-3 product-all-item items-center">
      <div className={'pl-0 col-6'}>
        <div className="d-flex items-center gap-10">
          <img src={order?.images?.thumb} alt="product" />
          <p className="product-item-title">{order.name}</p>
        </div>
      </div>
      <div className={'p-0 col-2'}>
        <div className="product-item-title">{order.article}</div>
      </div>
      <div className="p-0 col-4 result-bold-text d-flex items-center justify-end">
        <label>
          <div className="cursor-pointer">
            <input
              type="checkbox"
              className="checkbox__input"
              onChange={event => handleChange(event)}
              //   value={true}
              checked={selectedResults?.find(
                item => item.article === order.article,
              )}
            />
            <span className="checkbox__marker"></span>
          </div>
        </label>
      </div>
    </div>
  );
};
