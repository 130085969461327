import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {static_pages_Actions} from '../../../_actions/staticPages.actions';
import clsx from 'clsx';
import {BreadCrumbs} from '../../../_components/BreadCrumbs/BreadCrumbs';

const useStyles = makeStyles(theme => ({}));

const Delivery = props => {
  const classes = useStyles();
  const {staticPage} = props;
  const {html, name, image, images, title} = staticPage;
  const {t} = useTranslation();
  const createMarkup = () => {
    return {__html: html};
  };

  console.log(images);

  return (
    <>
      <main>
        <BreadCrumbs items={[{title: name, path: '/'}]} />
        <div className="products-block pt-1">
          <div className="wrapper-inner">
            <div className="products-block__header mb-4">
              <h2 className="title">{name}</h2>
            </div>
            <p className="about-us__description mb-8">{title}</p>
            <div dangerouslySetInnerHTML={createMarkup()} />
            <ul className="logos-list">
              {images
                ? images.map(items => (
                    <li key={items.image[0]}>
                      <img
                        src={items.image[0]}
                        alt="logo-list 1"
                      />
                    </li>
                  ))
                : ''}
            </ul>
            <div className="row mb-4">
              <div className="col-md-9 col-12"></div>
              <div className="col-md-3 col-12">
                <div className="image-block">
                  <img
                    className="about-us__img"
                    src={image ? image[0] : ''}
                    alt="delivery"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

function mapState(state) {
  const {staticPage} = state;

  return {staticPage};
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getStaticPage('delivery');
  }

  render() {
    return <Delivery {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
