import IconButton from "@material-ui/core/IconButton";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axInst from "../../../../_helpers/axios-instance";
import {searchActions} from "../../../../_actions";

const AddToFavorite = (props) => {
    const {
        getMyFavorite,
        favoriteSost,
        customer_id,
        row,
        forTable,
        productCart,
    } = props;
    const [favorite, setFavorite] = React.useState(favoriteSost);
    const [start, setStart] = React.useState(false);

    ///shopping-cart/wish-list/delete_row  вх.данные - customer_id, id-uuid
    // /shopping-cart/wish-list/list-rows/ список,  shopping-cart/wish-list/add-row/ - добавление
    //  customer_id , sell_currency_id - для списка, customer_id, price_uuid, wish_quantity - для добавления
    useEffect(
        () => {
            // Make sure we have a value (user has entered something in input)
            let uuid = '';
            if (row.offers) uuid = row.offers[0].uuid;
            else if (row.balance) uuid = row.balance[0].uuid;
            else if (row.on_stock) uuid = row.on_stock[0].uuid; //Из карточки

            if (customer_id && start && uuid !== '') {
                let active = true;
                (async () => {
                    let res;
                    if (favorite) {
                        res = await axInst.post(
                            'shopping-cart/wish-list/add-row/',
                            // '/shopping-cart/wish-list/list-rows/',
                            JSON.stringify({
                                customer_id: customer_id,
                                //sell_currency_id: 2,
                                // price_uuid: row.offers ? row.offers[0].uuid : row.balance[0].uuid,
                                price_uuid: uuid,
                                wish_quantity: 1,
                                timestamp: new Date().getTime(),
                            }),
                        );
                    } else {
                        if (row.favorite_id) {
                            res = await axInst.post(
                                'shopping-cart/wish-list/delete-row/',
                                JSON.stringify({
                                    customer_id: customer_id,
                                    id: row.favorite_id,
                                    timestamp: new Date().getTime(),
                                }),
                            );
                        }
                    }
                    getMyFavorite();
                    //await sleep(1e3); // For demo purposes.
                    if (active) {
                        console.log('==>', res);
                        //setOptions(res.data.results);
                    }
                })();

                return () => {
                    active = false;
                };
            }
        },
        [favorite, customer_id, row, start, getMyFavorite],
    );

    const changeFavorite = event => {
        event.preventDefault();
        //event.stopPropagation();
        setStart(true);
        if ((row.favorite_id && favorite === true) || favorite === false) {
            setFavorite(!favorite);
        }
    };

    return (
        <div style={{
            zIndex: 1,
            marginTop: 0,
            marginLeft: 0,
            position: "absolute",
            top: productCart !== undefined && 16,
            right: productCart !== undefined && 76,

        }}>
            <IconButton
                color={"secondary"}
                /*onClick={() => {
                    setFavorite(!favorite);
                }}*/
                onClick={changeFavorite}
                size={"small"}
                className="add_to_favorite">
                {favorite
                    ? <FavoriteIcon style={{color: "#008CB4", fontSize: forTable && 16}}/>
                    : <FavoriteBorderIcon style={{color: "#008CB4", fontSize: forTable && 16}}/>}
            </IconButton>
        </div>
    )

};

class Wrapper extends React.Component {
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return <AddToFavorite {...this.props} />;
    }
}

const actionCreators = {
    getMyFavorite: searchActions.getMyFavorite,
};

function mapState(state) {
    const {customer_id, sell_currency_id} = state.userSelectedData;
    return {
        customer_id,
        sell_currency_id,
    };
}

Wrapper.propTypes = {
    history: PropTypes.object,
};


export default connect(mapState, actionCreators)(Wrapper);
// export default AddToFavorite;
