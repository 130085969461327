import clsx from 'clsx';
import React, {useRef, useState} from 'react';
import {useEffect} from 'react';

export const CauseSelector = ({title, items, setSelectedReason}) => {
  const [isActive, setIsActive] = useState(false);
  const [activeItem, setActiveItem] = useState(0);

  const popupRef = useRef();

  useEffect(() => {
    if (activeItem) {
      setSelectedReason(items[activeItem - 1].title);
    }
  }, [activeItem]);

  document.addEventListener('click', e => {
    if (!e.composedPath().includes(popupRef.current)) {
      setIsActive(false);
    }
  });

  const handleActiveItem = index => {
    setActiveItem(index + 1);
    setIsActive(false);
  };

  const openPopup = () => {
    setIsActive(!isActive);
  };

  return (
    <div ref={popupRef} className="cause-selector">
      <div onClick={openPopup} className="selector-title">
        <div>
          {activeItem ? (
            items[activeItem - 1].title
          ) : (
            <div className="cause-title">{title}</div>
          )}
        </div>
        <svg
          className={isActive ? 'svg-rotate' : ''}
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 1L5.18628 5L9 1"
            stroke="#999999"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1L5.18628 5L9 1"
            stroke="#999999"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div
        style={{height: isActive ? items?.length * 29 : null}}
        className={clsx('selector-list', isActive ? 'open' : '')}>
        <ul>
          {items?.map((item, index) => (
            <li
              onClick={() => handleActiveItem(index)}
              key={item?.value}
              className="rounded px-2 py-1 hover:bg-slate-200 bg-transparent cursor-pointer duration-300">
              {item.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
