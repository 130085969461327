import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {static_pages_Actions} from '../../../_actions/staticPages.actions';
import clsx from 'clsx';
import {BreadCrumbs} from '../../../_components/BreadCrumbs/BreadCrumbs';

const useStyles = makeStyles(theme => ({
  // root: {
  //     padding: theme.spacing(3),
  // },
}));

const AboutUs = props => {
  const classes = useStyles();
  const {staticPage} = props;
  const {html} = staticPage;
  const {t} = useTranslation();
  const createMarkup = () => {
    return {__html: html};
  };

  return (
    <>
      <BreadCrumbs items={[{title: t('common:about-us'), path: '/'}]} />
      <div className="wrapper-inner">
        <div className={clsx(classes.root, 'static_page_container')}>
          <div className="products-block__header mb-4">
            <h2 className="title">{t('common:about-us')}</h2>
          </div>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
      </div>
    </>
  );
};

function mapState(state) {
  const {staticPage} = state;
  return {staticPage};
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getStaticPage('about-us-page');
  }

  render() {
    return <AboutUs {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
