import React, {useEffect, useState} from 'react';
import SwiperCore, {Navigation, Pagination, Autoplay} from 'swiper';
import {connect} from 'react-redux';
import {newsActions} from '../../_actions/news.actions';
import {Swiper, SwiperSlide} from 'swiper/react';
import {SliderItem} from './SliderItem';
import {Info, NextBtn, PrevBtn} from '../../_components/SwiperInterface';

import 'swiper/swiper.scss';
import {SliderSkeleton} from './SliderSkeleton';
import {useParams} from 'react-router-dom';
// import {  } from '@material-ui/styles';
import {useMediaQuery, useTheme} from '@material-ui/core';
import {SliderNoElements} from './SliderNoElements';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const TopSlider = props => {
  const {news, getNews} = props;
  const {data} = news;
  const {pageName} = useParams();
  const theme = useTheme();

  const topSliderBreakpoint1 = useMediaQuery(theme.breakpoints.down(1280));
  const topSliderBreakpoint2 = useMediaQuery(theme.breakpoints.down(1080));
  const topSliderBreakpoint3 = useMediaQuery(theme.breakpoints.down(880));
  const topSliderBreakpoint4 = useMediaQuery(theme.breakpoints.down(760));

  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderHeight, setSliderHeight] = useState(390);

  useEffect(() => {
    if (topSliderBreakpoint4) {
      return setSliderHeight(200);
    }
    if (topSliderBreakpoint3) {
      return setSliderHeight(230);
    }
    if (topSliderBreakpoint2) {
      return setSliderHeight(270);
    }
    if (topSliderBreakpoint1) {
      return setSliderHeight(330);
    }
    setSliderHeight(390);
  }, [
    topSliderBreakpoint1,
    topSliderBreakpoint2,
    topSliderBreakpoint3,
    topSliderBreakpoint4,
  ]);
  useEffect(() => {
    getNews(pageName);
  }, []);

  // !data || data.length === 0

  // if (data?.data.length === 0) {
  //   return (
  //     <div className="home-slider swiper">
  //       <div className="home-slider-inner swiper-wrapper">
  //         <SliderNoElements />
  //       </div>
  //     </div>
  //   );
  // }
  console.log(data);
  console.log(news);

  // if (data?.data?.length === 0 && pageName !== '') {
  //   return <></>;
  // }
  if (news?.loading) {
    return (
      <div className="home-slider swiper">
        <div className="home-slider-inner swiper-wrapper">
          <SliderSkeleton />
        </div>
      </div>
    );
  } else if (!data?.data[0].image) {
    return <></>;
  } else if (data?.data?.length === 0 && pageName !== '') {
    return <></>;
  } else {
    return (
      <Swiper
        direction="vertical"
        className="home-slider swiper"
        pagination={{clickable: true}}
        slidesPerView={1}
        simulateTouch
        preloadImages={true}
        updateOnImagesReady={true}
        autoplay={{delay: 8000}}
        speed={300}
        style={{height: sliderHeight}}
        onSlideChange={e => setCurrentSlide(e.activeIndex)}>
        <div className="home-slider-inner swiper-wrapper">
          {data.data.length !== 0 ? (
            data.data.map(item => (
              <SwiperSlide key={item.id}>
                <SliderItem path={`/news/${item.id}`} {...item} />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <SliderItem />
            </SwiperSlide>
          )}
          {/* {data.data.map(item => (
            <SwiperSlide key={item.id}>
              <SliderItem path={`/news/${item.id}`} {...item} />
            </SwiperSlide>
          ))} */}
        </div>
        <div className="home-slider-nav">
          <PrevBtn
            isDeactive={!(currentSlide + 1 > 1)}
            arrowType="home-slider"
          />
          <Info
            currentCount={currentSlide + 1}
            totalCount={data.data.length > 0 ? data.data.length : 1}
          />
          <NextBtn
            isDeactive={!(currentSlide + 1 < data.data.length)}
            arrowType="home-slider"
          />
        </div>
      </Swiper>
    );
  }
};

function mapState(state) {
  const news = state.news;
  return {news};
}

const actionCreators = {
  getNews: newsActions.getNews,
};

export default connect(mapState, actionCreators)(TopSlider);
