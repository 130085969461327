import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import {useLocation} from 'react-router-dom';

const Accordion = withStyles({
  root: {
    marginBottom: 20,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    border: '1px solid #FFBE00',
    borderRadius: '100px',
    minHeight: 56,

    '&:hover': {
      backgroundColor: '#FFBE00',
    },
    '&$expanded': {
      minHeight: 56,
      backgroundColor: '#FFBE00',
    },
  },
  content: {
    justifyContent: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
    '& svg': {
      marginRight: '8px',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function CategoryAccordion({name, children, icon, url}) {
  const [expanded, setExpanded] = React.useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(url)) {
      setExpanded('panel1');
    }
  }, []);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <AccordionSummary
          //   className="btn-show__category btn btn-big btn-icon btn-icon-left btn-icon-right btn--w100 mt-4"
          variant="button"
          aria-controls="panel1d-content"
          id="panel1d-header">
          {icon}
          <Typography>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
