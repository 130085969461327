import React from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {static_pages_Actions} from '../../../_actions/staticPages.actions';
import returnImage from '../../../images/return1.svg';
import {BreadCrumbs} from '../../../_components/BreadCrumbs/BreadCrumbs';
import MainDialog from '../../../_components/ReturnProduct/MainDialog';
import {useState} from 'react';

const ReturnTerms = props => {
  const {staticPage, authentication} = props;
  const {html, name, image} = staticPage;
  const {t} = useTranslation();

  const [openReturnDialog, setOpenReturnDialog] = useState(false);

  const createMarkup = () => {
    return {__html: html};
  };
  return (
    <>
      <BreadCrumbs items={[{title: name, path: '/'}]} />
      <div className="products-block pt-1">
        <div className="wrapper-inner">
          <div className="products-block__header mb-4">
            <h2 className="title">{name}</h2>
            {authentication.loggedIn && (
              <button
                onClick={() => setOpenReturnDialog(!openReturnDialog)}
                className="btn">
                {t('common:issue_a_refund')}
              </button>
            )}
            <MainDialog
              setOpenReturnDialog={setOpenReturnDialog}
              openReturnDialog={openReturnDialog}
            />
          </div>
          <div
            className="formated-text-wrapper"
            dangerouslySetInnerHTML={createMarkup()}
          />
          <div className="row mb-4 mt-4">
            <div className="col-md-9 col-12"></div>
            <div className="col-md-3 col-12">
              <div className="image-block">
                <img
                  className="about-us__img"
                  src={image ? image[0] : ''}
                  alt="delivery"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function mapState(state) {
  const {staticPage, authentication} = state;

  return {staticPage, authentication};
}

const actionCreators = {
  getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getStaticPage('return_terms');
  }

  render() {
    return <ReturnTerms {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
