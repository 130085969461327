import React, {
    // useState,
    useEffect} from 'react';
// import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import {Grid, Tabs, Tab, Paper,
    // Typography
} from '@material-ui/core';
import {connect} from 'react-redux';

import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ComingReceipts from './components/ComingReceipts';
import PreviousOrder from './components/PreviousOrder';
import PromotionalGoods from './components/PromotionalGoods';
import {userActions} from '../../_actions/user.actions';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'none' /*theme.palette.background.default,*/,
        height: '100%',
    },

    preOrder_page:{
        display: 'flex',
    },

    leftBlock: {
        // width: '400px',
        margin: '0 20px 0 0',
    },

    filterBlock: {
        display: "block",
        justifyContent: 'space-between',
        padding: "15px",
        background: 'white',
        boxShadow: '2px 2px 8px 0px rgba(34, 60, 80, 0.2)',
    },

    promotionalGoodsBlock: {
        margin: '20px 0 0 0',
        padding: "15px",
        background: 'white',
        boxShadow: '2px 2px 8px 0px rgba(34, 60, 80, 0.2)',
    },

    filterBlockHeader: {
        display: "flex",
        justifyContent: 'space-between',
    },

    filterItem: {
        position: 'relative',
    },

    subText: {
        position: 'absolute',
        bottom: 0,
        width:'100%',
        color:'#ddd',
        fontSize: '10px',
        textAlign: 'center',
        lineHeight: 1,
    },

}));

const PreOrder = props => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {history} = props;
    // console.log(history,'history');
    // console.log(props,'props');

    const tabs = {
        'coming-receipts': t('common:comingReceipts'),
        'previous-order': t('common:previousOrder'),
    };

    const [value, setValue] = React.useState('female');

    const getActiveTab = function(){
        if(Object.keys(tabs).indexOf(props.match.params.type)!==-1){
            return {tabValue:props.match.params.type};
        }else{
            return {tabValue:Object.keys(tabs)[0]};
        }
    };

    const [values, setValues] = React.useState(() => {
        return getActiveTab();
    });

    // console.log(values, 'values');

    useEffect(()=>{
        setValues(getActiveTab())
        // eslint-disable-next-line
    },[props.match.params.type]);

    const handleChangeTab = (event, value) => {
    // debugger
    //     console.log(value,'value22222');
        history.push(`/pre-orders/${value}/`);
        //setValues({...values, tabValue: value});
    };
    // console.log(history,'history22222');

    const TabContainer = props => {
        return (
            // <Typography component="div" style={{padding: 8 * 3}}>
            <div>{props.children}</div>
        );
    };

    TabContainer.propTypes = {
        children: PropTypes.node.isRequired,
    };

    const handleChangeFilter = event => {
        // console.log(event.target.value);
        setValue(event.target.value);
        event.persist();
    };




    useEffect(() => {

    }, []);


    return (
        <Grid container className={clsx(classes.root, classes.preOrder_page)}>
            <Grid md={3} xs={12}>
                <div className={classes.leftBlock}>
                    <div className={classes.filterBlock}>
                        <div className={classes.filterBlockHeader}>
                            <div>{t('common:filters')}</div>
                            <button>{t('common:clean')}</button>
                        </div>
                        <FormControl component="fieldset" style={{width: "100%"}}>
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChangeFilter}>
                                <div className={classes.filterItem}>
                                    <FormControlLabel value="0" control={<Radio />} label="0% Передоплати"/>
                                    <div className={classes.subText}>Базова вартiсть</div>
                                </div>
                                <div className={classes.filterItem}>
                                    <FormControlLabel value="50" control={<Radio />} label="50% Передоплати" />
                                    <div className={classes.subText}>Додаткова знижка</div>
                                </div>
                                <div className={classes.filterItem}>
                                    <FormControlLabel value="100" control={<Radio />} label="100% Передоплати" />
                                    <div className={classes.subText}>Максимальна знижка</div>
                                </div>

                            </RadioGroup>
                        </FormControl>
                        <hr/>
                        <FormControl component="fieldset" style={{width: "100%"}}>
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChangeFilter}>
                                <FormControlLabel value="0" control={<Radio />} label="Вантажний вiддiл" />
                                <FormControlLabel value="50" control={<Radio />} label="Легковий вiддiл" />
                            </RadioGroup>
                        </FormControl>

                    </div>
                    <div className={classes.promotionalGoodsBlock}>
                        <PromotionalGoods/>
                    </div>
                </div>
            </Grid>
            <Grid md={9} xs={12}>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <Grid container>
                            <Grid item md={12} xs={12}>
                                <Tabs
                                    variant="scrollable"
                                    value={values.tabValue}
                                    onChange={handleChangeTab}
                                    // indicatorColor="primary"
                                    classes={{
                                        root: 'profile_tab',
                                        indicator: classes.indicator,
                                    }}
                                    // textColor="primary"

                                    scrollButtons="auto">
                                    {Object.keys(tabs).map((value)=>(<Tab key={value} label={tabs[value]} value={value} />))}
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Paper elevation={2} className={classes.divider}/>
                        <Paper elevation={0} className={classes.paper}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>

                                        <Grid item md={12} xs={12} className={classes.root2}>
                                            {values.tabValue === 'previous-order' && (
                                                <TabContainer>
                                                    <PreviousOrder/>
                                                </TabContainer>
                                            )}
                                        </Grid>
                                        <Grid item md={12} xs={12} className={classes.root2}  style={{margin: '0 0 20px'}}>
                                            {values.tabValue === 'coming-receipts' && (
                                                <TabContainer>
                                                    <ComingReceipts/>
                                                </TabContainer>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

class Wrapper extends React.Component {
    componentDidMount() {
        // this.props.getCatalogTree();
    }

    render() {
        return <PreOrder {...this.props} />;
    }
}

function mapState(state) {
    const {userSelectedData, userProfile, frontendData} = state;
    return {userSelectedData, userProfile, frontendData};
}

const actionCreators = {
    openOrdersList: userActions.openOrdersList,
};

export default connect(mapState, actionCreators)(Wrapper);

