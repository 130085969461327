import React, {useState} from 'react'; // useEffect, useState
import {makeStyles} from '@material-ui/styles';
import {Link, withRouter} from 'react-router-dom';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';

// import catalog2 from '../../images/product2.png';
import sliderLeftArrow from '../../images/left-arrow.svg';
import sliderRightArrow from '../../images/right-arrow.svg';
import {searchActions} from '../../_actions';
import {promotionActions} from '../../_actions/promotion.actions';
import clsx from 'clsx';
import ImageWithFallback from '../../_components/ImageWithFallback';
import {StocksSkeleton} from '../../_components/Stocks';
import Stocks from '../../_components/Stocks/Stocks';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Info, NextBtn, PrevBtn} from '../../_components/SwiperInterface';
// import config from "../../_services/config";

const SliderPromo = props => {
  const {
    searchPromotionList,
    settingsPromotionSlider,
    promotionItems,
    historySearch,
  } = props;

  const useStyles = makeStyles(theme => ({
    // ".slick-slider": {
    //     margin: '0 0 5px'
    // },
    list: {
      // display: promotionItems.length>0 ?'block': 'none',
      // height: '100%',
      '& .slick-arrow.slick-prev': {
        left: '-25px',
        zIndex: 2,
        height: 25,
        width: 25,
        margin: '0 auto 15px',
        backgroundImage: `url(${sliderLeftArrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        transition: 'all 0.15s linear',
        '&:hover': {
          opacity: '0.2',
        },
        '&:before': {
          display: 'none',
        },
        [theme.breakpoints.down(1200)]: {
          left: '-23px',
        },
      },
      '& .slick-arrow.slick-next': {
        right: '-25px',
        zIndex: 2,
        height: 25,
        width: 25,
        backgroundImage: `url(${sliderRightArrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        transition: 'all 0.15s linear',
        '&:hover': {
          opacity: '0.2',
        },
        '&:before': {
          display: 'none',
        },
        [theme.breakpoints.down(1200)]: {
          right: '-25px',
        },
      },
      '& .slick-dots': {
        '& li.slick-active button': {
          backgroundColor: '#fdc700',
        },
        '& li button': {
          width: 6,
          height: 6,
          backgroundColor: '#d6d6d6',
          borderRadius: '50%',
        },
        '& li button:before': {
          width: 6,
          height: 6,
          fontSize: 0,
          color: 'transparent',
        },
      },
    },

    listVariant3: {
      '& .slick-list': {
        // height: 'calc(100% - 45px)',
      },
      '& img': {
        height: '100%',
      },

      '& .slick-track': {
        height: '100%',
      },
    },

    subCatalogSlide: {
      textDecoration: 'none',
      color: '#000',
      marginBottom: 20,
      width: '50% !important ',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',

        '& img': {
          maxWidth: '100%',
          maxHeight: '100%',
          height: 'auto',
        },
      },
    },
    slider_image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  }));

  const classes = useStyles();

  const {t} = useTranslation();
  let settings = settingsPromotionSlider;
  // console.log(promotionItems, 'promotionItemspromotionItemspromotionItems')

  const [currentSlide, setCurrentSlide] = useState(null);

  return (
    <>
      {promotionItems.results &&
      !promotionItems.error &&
      promotionItems.results.length > 0 &&
      promotionItems.loading === false ? (
        <>
          <Swiper
            onSlideChange={e => setCurrentSlide(e.activeIndex)}
            direction="vertical"
            height={258}
            slidesPerView={1}
            style={{maxHeight: 258, position: 'relative'}}>
            {promotionItems.results.map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  className={classes.slider_image}
                  src={
                    item.horizontal_banner[0] ??
                    'https://emresolutions.com/assets/imgs/no-image.jpg'
                  }
                />
              </SwiperSlide>
            ))}
            <div className="home-slider-nav">
              <PrevBtn
                arrowType="home-slider"
                isDeactive={currentSlide + 1 === 1}
              />
              <Info
                currentCount={currentSlide + 1}
                totalCount={promotionItems.results.length}
              />
              <NextBtn
                arrowType="home-slider"
                isDeactive={currentSlide + 1 === promotionItems.results.length}
              />
            </div>
          </Swiper>
          {/* {settings.variant === 'variant2' && (
            <Slider
              vertical={true}
              className={
                settings.variant === 'variant3'
                  ? clsx(classes.list, classes.listVariant3)
                  : classes.list
              }
              prevArrow={<h1>Hello{'<<'}</h1>}
              nextArrow={<h1>Hello{'>>'}</h1>}
              {...settings}>
              {promotionItems.results.map((item, id) => (
                <>
                  <Link
                    key={id}
                    to={`/promotion/${item.url}/${item.id}`}
                    className={classes.subCatalogSlide}>
                    <ImageWithFallback
                      images={
                        settingsPromotionSlider.variant === 'variant2'
                          ? item.horizontal_banner
                          : settingsPromotionSlider.variant === 'variant3'
                          ? item.vertical_banner
                          : item.banner_handset
                          ? item.banner_handset
                          : item.banner_tablet
                      }
                      imgAlt="Product silhouette"
                      onClick={() => {
                        historySearch({
                          name: `${t('common:Actions')}: ${item.name}`,
                          url: `/promotion/${item.url}/${item.id}`,
                        });
                        searchPromotionList(item.url, item.id);
                      }}
                    />
                  </Link>
                </>
              ))}
            </Slider>
          )} */}
          {/* {settings.variant === 'variant1' && (
            <Stocks items={promotionItems.results} />
          )} */}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

function mapState(state) {
  // const {currencies, main_currency} = state.frontendData;
  // const {customer_id, sell_currency_id} = state.userSelectedData;
  // const {customer_data} = state.contragentData;
  const {
    // productCart,
    // cart,
    // searchByCrossOwn,
    // searchByCrossOffer,
    // searchStatus,
    // listSearchRelated,
    // listMyFavorite,
    authentication,
    promotionItems,
  } = state;

  return {
    // productCart,
    // cart,
    // currencies, main_currency, customer_id, sell_currency_id,
    // searchByCrossOwn,
    // searchByCrossOffer,
    // searchStatus,
    // customer_data,
    // listSearchRelated,
    // listMyFavorite,
    authentication,
    promotionItems,
  };
}

const actionCreators = {
  searchPromotionList: searchActions.searchPromotionList,
  promotionListItems: promotionActions.promotionListItems,
  historySearch: searchActions.historySearch,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.promotionListItems();
  }

  componentDidUpdate(prevProps) {}

  render() {
    return <SliderPromo {...this.props} />;
  }
}

Wrapper.propTypes = {
  history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
