import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {cartActions} from '../../../../_actions';

const TabPaneAnalogNumbers = ({productAnalogNumbers}) => {
  const currentData = [];
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    let count = 0;
    for (let i = 0; i < productAnalogNumbers?.data?.length / 5; i++) {
      currentData.push(productAnalogNumbers.data.slice(count, count + 5));
      count += 5;
    }
    setColumns(currentData);
  }, [productAnalogNumbers]);
  return (
    <div className="single-product__list">
      {columns?.map((item, index) => (
        <ul className="list" key={index}>
          {item.map(el => (
            <li key={el.article}>
              {el.article} {el.brand}
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

// function mapState(state) {
//   const {productAnalogNumbers, getAnalogNumbers} = state;
//   return {productAnalogNumbers, getAnalogNumbers};
// }

// const actionCreators = {
//   getAnalogNumbers: cartActions.getAnalogNumbers,
// };

// class Wrapper extends React.Component {
//   componentDidMount() {
//     this.props.getAnalogNumbers(this.props.match.params.article);
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.match.params.article !== prevProps.match.params.article) {
//       this.props.getAnalogNumbers(this.props.match.params.article);
//     }
//   }

//   render() {
//     return <TabPaneAnalogNumbers {...this.props} />;
//   }
// }

// export default connect(mapState, actionCreators)(withRouter(Wrapper));
export default TabPaneAnalogNumbers;
