import axInst from '../_helpers/axios-instance';
import {handleResponse} from "./cart.service";
import {store} from "../_helpers";

export const deliveryService = {
    getListTypeDelivery,
    getListDeliveryService,
    getCityByDeliveryType,
    getAddressByCity,
    getScheduler,
    getSchedulerTime,
    getSchedulerDateTime,
    getTermsByFreeShipping
};

export function getListTypeDelivery(id_warehouse) {

    const state = store.getState();
    const {userSelectedData} = state;
    const {customer_id: customerID} = userSelectedData;

    if (!customerID) return Promise.reject('Wrong params');
    let url_api = `/api/delivery-type/?customer=${customerID}&warehouse=${id_warehouse}`;
    if(id_warehouse === '')
        url_api = `/api/delivery-type/?customer=${customerID}`;
    return axInst
        .get(
            url_api,
            // `/api/delivery-type/?customer=${customerID}&warehouse=${id_warehouse}`,
        )
        .then(handleResponse);
}

export function getListDeliveryService() {
    const state = store.getState();
    const {userSelectedData} = state;
    const {customer_id: customerID} = userSelectedData;
    return axInst
        .get(
            // `/api/delivery/delivery_services/`,
            `/api/delivery/delivery-services-for-customer/${customerID}/`,
        )
        .then(handleResponse);
}


export function getCityByDeliveryType(deliveryType, id_warehouse) {
    const state = store.getState();
    const {userSelectedData} = state;
    const {customer_id: customerID} = userSelectedData;

    if (!customerID) return Promise.reject('Wrong params');

    return axInst
        .get(
            `/api/delivery/cities-by-delivery-type/${customerID}/${id_warehouse}/${deliveryType}/`,
        )
        .then(handleResponse);
}

export function getAddressByCity(deliveryType, cityId) {
    const state = store.getState();
    const {userSelectedData} = state;
    const {customer_id: customerID} = userSelectedData;

    if (!customerID) return Promise.reject('Wrong params');

    return axInst
        .get(
            `/api/delivery/address-by-city/${customerID}/${deliveryType}/${cityId}/?timestamp=${new Date().getTime()}`,
        )
        .then(handleResponse);
}

export function getScheduler(warehouseID, deliveryType, cityId) {
    return axInst
        .get(
            `/api/delivery/scheduler/${warehouseID}/${deliveryType}/${cityId}/`,
        )
        .then(handleResponse);
}

export function getSchedulerTime(warehouseID, deliveryType, cityId, date) {
    return axInst
        .get(
            `/api/delivery/scheduler-time/${warehouseID}/${deliveryType}/${cityId}/${date}/`,
        )
        .then(handleResponse);
}

export function getSchedulerDateTime(warehouseID, deliveryType, cityId, code3pl, orderLength, orderHeight, orderWidth, allWeightNP, totalVeightOrder) {
    const state = store.getState();
    const {userSelectedData} = state;
    const {customer_id: customerID} = userSelectedData;

    return axInst
        .get(
            `/api/delivery/scheduler-date-time/${customerID}/${warehouseID}/${deliveryType}/${cityId}/${code3pl}/?timestamp=${new Date().getTime()}/&length=${orderLength}&height=${orderHeight}&width=${orderWidth}&weight=${allWeightNP> totalVeightOrder ? allWeightNP: totalVeightOrder }`,
            // `/api/delivery/scheduler-date-time/${warehouseID}/${deliveryType}/${cityId}/${code3pl}/?timestamp=${new Date().getTime()}`,
        )
        .then(handleResponse);
}

/*
export function getSchedulerDateTime(warehouseID, deliveryType, cityId, code3pl) {
    const state = store.getState();
    const {userSelectedData} = state;
    const {customer_id: customerID} = userSelectedData;
    return axInst
        .get(
            `/api/delivery/scheduler-date-time/${customerID}/${warehouseID}/delivery_service/${cityId}/0/?timestamp=${new Date().getTime()}`,
            // `/api/delivery/scheduler-date-time/${warehouseID}/${deliveryType}/${cityId}/${code3pl}/?timestamp=${new Date().getTime()}`,
        )
        .then(handleResponse);
}*/

function getTermsByFreeShipping(deliveryTerms) {
    const state = store.getState();
    const {userSelectedData} = state;
    // console.log(deliveryTerms, 'deliveryTerms');

    if (!userSelectedData.customer_id) return Promise.reject('Wrong params');

    return axInst
        .post(
            `/api/delivery/delivery-terms/`,
            JSON.stringify({
                delivery_type: deliveryTerms.delivery_type,
                warehouse: deliveryTerms.warehouse,
                city_destination: deliveryTerms.city_destination,
                date_delivery: deliveryTerms.date_delivery,
                time_delivery: deliveryTerms.time_delivery,
                delivery_code_3pl: deliveryTerms.delivery_code_3pl,
                customer_id: deliveryTerms.customer_id,
                price_sell: deliveryTerms.price_sell,
                currency_sell: deliveryTerms.currency_cell
            }),
        )
        .then(handleResponse);
}
