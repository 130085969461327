import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import React, {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Thumbs} from 'swiper';
import {NextBtn, PrevBtn} from '../../../_components/SwiperInterface';
import {DetailImageModal} from './DetailImageModal';

const useStyles = makeStyles(theme => ({
  'swiper-wrapper1': {
    display: 'flex',
    alignItems: 'center',
    rowGap: 10,
    flexDirection: 'column',

    '& .swiper-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      transform: 'translate3d(0px, 0px, 0px) !important',
    },
  },
  'single-product__children': {
    display: 'flex',
    width: 90,
    padding: '0 14px',
  },
  'single-product-thumb': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '90px !important',
    '&.swiper-slide-thumb-active': {
      border: '2px solid #FFBE00',
      borderRadius: 4,
    },
  },
}));

export const DetailProductSlider = props => {
  const {product, result} = props;

  const photos = result.article.photo.large_photos;

  const [sliderIndex, setSliderIndex] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);

  const classes = useStyles();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef();

  return (
    <div className="col-12 col-lg-6 mb-lg-0 mb-3">
      <div className="single-product-block single-product__sliders">
        <div
          className={clsx(
            classes['single-product__children'],
            'd-none d-sm-flex',
          )}>
          <div>
            <Swiper
              slidesPerView={1}
              modules={[Thumbs]}
              onSwiper={setThumbsSwiper}
              className={clsx(classes['swiper-wrapper1'])}>
              {photos?.map(photo => (
                <SwiperSlide
                  key={photo}
                  className={clsx(classes['single-product-thumb'], 'active')}>
                  <img src={photo} alt="slider-1" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <Swiper
          ref={swiperRef}
          thumbs={{swiper: thumbsSwiper}}
          onSlideChange={e => {
            setSliderIndex(e.activeIndex);
            console.log(e);
          }}
          modules={[Thumbs]}>
          <div className="single-product__parent swiper">
            {photos?.map(photo => (
              <SwiperSlide key={photo}>
                <div
                  onClick={() => setShowImageModal(!showImageModal)}
                  className="single-product__parent-item swiper-slide cursor-pointer">
                  <img className="mt-0" src={photo} alt="slider-1" />
                </div>
              </SwiperSlide>
            ))}
          </div>
          <div className="single-product__elements">
            <div className="products-block__slick">
              <PrevBtn isDeactive={sliderIndex + 1 === 1} />
              <NextBtn isDeactive={sliderIndex + 1 === photos.length} />
            </div>
            <div className="single-product__tags">
              {/* <span className="single-product__tag info">Олива даром</span>
              <span className="single-product__tag percent">– 30%</span> */}
            </div>
          </div>
        </Swiper>
      </div>
      <DetailImageModal
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
        photos={photos}
      />
    </div>
  );
};
