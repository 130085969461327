import clsx from 'clsx';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const NavBtns = props => {
  const {setActiveStep, activeStep, disableBtn, setShowLastContent} = props;

  const {t} = useTranslation();

  const nextStep = () => {
    if (activeStep >= 3) return setShowLastContent(true);

    setActiveStep(activeStep + 1);
  };
  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className="d-flex justify-between items-center mt-4">
      <div
        onClick={prevStep}
        className={clsx(
          'd-flex items-center gap-10 cursor-pointer',
          activeStep === 0 ? 'disabled' : '',
        )}>
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.5"
            d="M5.54918 1L1.00264 6.23494L5.54918 11M1 6.18882H13H1Z"
            stroke="#414D5F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="styled-text">{t('common:Back')}</span>
      </div>
      <button
        onClick={nextStep}
        className={clsx('btn px-15 py-3', disableBtn ? 'disabled' : '')}>
        {activeStep >= 3 ? t('common:To issue') : t('common:next')}
      </button>
    </div>
  );
};
