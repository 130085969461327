import {makeStyles, Typography} from '@material-ui/core';
import logoIcon from '../../images/logo-light.svg';
import React from 'react';

const useStyles = makeStyles(theme => ({
  skeleton_innner: {
    width: '100%',
    height: 390,
    backgroundColor: '#EBF0F8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .wrapper-inner': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      gap: 20,
    },
  },
}));

export const SliderNoElements = () => {
  const classes = useStyles();
  return (
    <div className={classes.skeleton_innner}>
      <div className="wrapper-inner">
        <img src={logoIcon} alt="logoIcon" />
      </div>
    </div>
  );
};
