import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {withStyles} from '@material-ui/styles';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import {deliveryActions} from '../../../../_actions';
import FormField from '../../../Authorization/FormField';
import {v4 as uuid} from 'uuid';
import {StepSelector} from '../StepSelector';

const Input = withStyles({
  root: {
    borderRadius: 40,
    background: '#EBF0F8',
    border: 'none',

    '& .MuiInputBase-input': {
      marginLeft: 25,
      fontSize: 13,
    },
    '& .MuiFormLabel-root': {
      left: 15,
      color: '#000',

      '&.Mui-focused': {
        color: '#315EFB',
      },
      '&.MuiInputLabel-shrink': {
        color: '#315EFB',
      },
    },

    '& .MuiFilledInput-root': {
      background: 'transparent',
    },
    '& .MuiFilledInput-underline:after': {
      border: 'none',
      content: '',
    },
    '& .MuiFilledInput-underline:before': {
      border: 'none',
      content: '',
    },
  },
})(TextField);

const ThirdStep = props => {
  const {
    setDisableBtn,
    activeStep,
    getListDeliveryService,
    listDeliveryServices,
    customer,
  } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ttnNumber: '',
      placeCount: '',
      user: '',
      otpravitel: '',
      poluchatel: '',
      price: '',
      selectCarrier: '',
    },
    touched: {},
    errors: {},
  });
  const [hideAnotherFields, setHideAnotherFields] = useState(false);

  const {t} = useTranslation();

  let cariers = listDeliveryServices?.data
    ? [
        ...listDeliveryServices.data.map(item => ({...item, _uuid: uuid()})),
        {name: 'Доставка на склад DaniParts', _uuid: uuid()},
        {name: 'Передача курьером DaniParts', _uuid: uuid()},
      ]
    : [
        {name: 'Доставка на склад DaniParts'},
        {name: 'Передача курьером DaniParts'},
      ];

  useEffect(() => {
    if (activeStep === 2) {
      if (
        formState.values.selectCarrier.name === 'Доставка на склад DaniParts' ||
        formState.values.selectCarrier.name === 'Передача курьером DaniParts'
      ) {
        setDisableBtn(false);
        setHideAnotherFields(true);
      } else {
        // setDisableBtn(true);
        setHideAnotherFields(false);
        if (
          formState.values.otpravitel &&
          formState.values.placeCount &&
          formState.values.poluchatel &&
          formState.values.price &&
          formState.values.ttnNumber &&
          formState.values.user
        ) {
          setDisableBtn(false);
        } else {
          setDisableBtn(true);
        }
      }
    }
  }, [formState, activeStep]);
  useEffect(() => {
    getListDeliveryService();
  }, []);
  // useEffect(() => {
  //   cariers.push(
  //     {name: 'Доставка на склад DaniParts'},
  //     {name: 'Передача курьером DaniParts'},
  //   );
  // }, [listDeliveryServices]);

  const handleChange = (event, variant) => {
    event.persist();

    if (variant === 'no-text') {
      if (/[^0-9]+/g.test(event.target.value)) {
        return;
      }
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleChangeAutocomplete = (e, data, name) => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [name]: data,
      },
    });
  };

  // console.log(cariers);
  // console.log(listDeliveryServices?.data);
  // listDeliveryServices?.data
  console.log(formState);
  return (
    <div className="no-scroll">
      {/* <StepSelector /> */}
      <Autocomplete
        id="combo-box-demo"
        options={cariers}
        getOptionLabel={option => option?.name}
        style={{borderRadius: '40px', marginBottom: 20}}
        onChange={(e, data) =>
          handleChangeAutocomplete(e, data, 'selectCarrier')
        }
        renderInput={params => (
          <Input
            {...params}
            label={t('common:select_a_carrier')}
            variant="filled"
          />
        )}
      />
      {!hideAnotherFields && (
        <>
          <div className="row mx-0">
            <div className="user-form p-0 col-6 pr-1">
              <FormField
                name="ttnNumber"
                type="text"
                variant="no-text"
                handleChange={handleChange}
                formState={formState}
                title={t('common:TTN_number')}
                hasErrorText={t('common:enter_email2')}
                // mask={'999999999999999999999'}
              />
            </div>
            <div className="user-form p-0 col-6 pl-1">
              <Autocomplete
                id="combo-box-demo"
                options={[
                  `1 ${t('common:months')}`,
                  `2 ${t('common:months')}`,
                  `3 ${t('common:months')}`,
                  `4 ${t('common:months')}`,
                  `5 ${t('common:months')}`,
                ]}
                getOptionLabel={option => option}
                style={{borderRadius: '40px'}}
                onChange={(e, data) =>
                  handleChangeAutocomplete(e, data, 'placeCount')
                }
                renderInput={params => (
                  <Input
                    {...params}
                    label={t('common:number_of_seats')}
                    variant="filled"
                  />
                )}
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="user-form p-0 col-6 pr-1">
              {/* <FormField
            name="user"
            type="text"
            handleChange={handleChange}
            formState={formState}
            title={t('common:Payer')}
            hasErrorText={t('common:enter_email2')}
          /> */}
              {/* <Autocomplete
                id="combo-box-demo"
                options={customer?.results}
                getOptionLabel={option => option?.text}
                style={{borderRadius: '40px'}}
                onChange={(e, data) =>
                  handleChangeAutocomplete(e, data, 'user')
                }
                renderInput={params => (
                  <Input
                    {...params}
                    label={t('common:Payer')}
                    variant="filled"
                  />
                )}
              /> */}
              <Autocomplete
                id="combo-box-demo"
                options={[t('common:Recipient'), t('common:Sender')]}
                getOptionLabel={option => option}
                style={{borderRadius: '40px'}}
                onChange={(e, data) =>
                  handleChangeAutocomplete(e, data, 'user')
                }
                renderInput={params => (
                  <Input
                    {...params}
                    label={t('common:Payer')}
                    variant="filled"
                  />
                )}
              />
            </div>
            <div className="user-form p-0 col-6 pl-1">
              <FormField
                name="otpravitel"
                type="text"
                handleChange={handleChange}
                formState={formState}
                title={t('common:Sender')}
                hasErrorText={t('common:enter_email2')}
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="user-form p-0 col-6 pr-1">
              <Autocomplete
                id="combo-box-demo"
                options={[
                  t('common:Igor_Tarasyuk'),
                  t('common:Demyanchuk_Boris'),
                ]}
                getOptionLabel={option => option}
                style={{borderRadius: '40px'}}
                onChange={(e, data) =>
                  handleChangeAutocomplete(e, data, 'poluchatel')
                }
                renderInput={params => (
                  <Input
                    {...params}
                    label={t('common:Recipient')}
                    variant="filled"
                  />
                )}
              />
            </div>
            <div className="user-form p-0 col-6 pl-1">
              <FormField
                name="price"
                type="text"
                variant="no-text"
                handleChange={handleChange}
                formState={formState}
                title={t('common:shipping_amount')}
                hasErrorText={t('common:enter_email2')}
              />
            </div>
          </div>
          {formState.values.poluchatel && (
            <p className="wrong-text-bg">
              {t('common:return_text_when_select')}
            </p>
          )}
        </>
      )}
    </div>
  );
};

const actionCreators = {
  getListDeliveryService: deliveryActions.getListDeliveryService,
};
const mapState = state => {
  const {listDeliveryServices, customer} = state;

  return {listDeliveryServices, customer};
};

export default connect(mapState, actionCreators)(ThirdStep);
