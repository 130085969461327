import React from 'react';
//import PropTypes from 'prop-types';

// import {useTranslation} from "react-i18next";
// const {t} = useTranslation();

const PriceToStr = props => {
  const {
    data,
    text = '',
    putTextAfter = false,
    beforeText = '',
    grn = '',
    stBefore,
    stSumma,
    stAll,
    stPositive,
    stNegative,
  } = props;

  const space = '\u00A0'; //'&nbsp;';
  function floatToStr(val) {
    // let tmp = val.toFixed(2);
    // if(text === "₴") tmp = Math.round(tmp).toFixed(2);
    // tmp = tmp.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + space);
    if (!val) return '0.00';
    // console.log(parseInt(val), 'val~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    let tmp = parseFloat(val)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + space);
    return tmp;
  }

  let stA = stAll ? stAll : {fontStyle: 'normal'};
  let stBF = stBefore ? stBefore : {fontStyle: 'normal'};
  let stSUM = stSumma ? stSumma : {fontStyle: 'normal'};

  if (stPositive && data >= 0) {
    stA = {...stPositive, ...stA};
  }
  if (stNegative && data < 0) {
    stA = {...stNegative, ...stA};
  }

  //stBF.fontStyle = "normal";
  //stSUM.fontStyle = "normal";
  return (
    <em style={stA}>
      <em style={stBF}>{beforeText}</em>
      <em style={stSUM}>
        {text === ''
          ? floatToStr(data)
          : text !== '₴'
          ? putTextAfter
            ? floatToStr(data) + ' ' + text // put symbol after price
            : text + space + floatToStr(data) // text, e.g. currency symbol before
          : grn
          ? floatToStr(data) + space + grn
          : // ? floatToStr(data) + space + t('common:order_processing')
            floatToStr(data)}
      </em>
    </em>
  );
};

export default PriceToStr;
