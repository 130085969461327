import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import aboutImage from '../../../../images/about.png';
import axInst from '../../../../_helpers/axios-instance';

export const About = () => {
  const {t} = useTranslation();
  const [data, setData] = useState(null);

  useEffect(() => {
    axInst
      .post('/api/static-page/', {slug: 'about-us'})
      .then(res => setData(res.data));
  }, []);

  if (!data) return <></>;

  const createMarkup = html => {
    return {__html: html};
  };

  // console.log(data);

  const aboutItems = [
    t('common:about_us_1'),
    t('common:about_us_2'),
    t('common:about_us_3'),
    t('common:about_us_4'),
    t('common:about_us_5'),
    t('common:about_us_6'),
    t('common:about_us_7'),
    t('common:about_us_8'),
    t('common:about_us_9'),
    t('common:about_us_10'),
  ];

  return (
    <div className="products-block about">
      <div className="wrapper-inner">
        <div className="products-block__header">
          <h2 className="title mb-4">{data.name}</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 col-12">
            <div className="about-context">
              <div className="about-context__inner">
                {/* {aboutItems.map((item, index) => (
                  <p key={index}>{item}</p>
                ))} */}
                <div dangerouslySetInnerHTML={createMarkup(data.html)} />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 d-flex align-items-center">
            <img src={aboutImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
