import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PriceToStr from '../../../PriceToStr';
import {TableProductItemResult} from '../TableProductItemResult';
import {TableHeader} from './TableHeader';

export const FourthStep = ({orders, stepIsActive}) => {
  const [totalPrice, setTotalPrice] = useState(0);

  const {t} = useTranslation();

  useEffect(() => {
    if (orders.length > 0 && stepIsActive) {
      setTotalPrice(
        orders
          .map(item => item?.selectedQuantity * item?.price)
          .reduce((partialSum, a) => partialSum + a, 0),
      );
    }
  }, [orders, stepIsActive]);

  return (
    <div className="results-by-search">
      <TableHeader resultLayout={true} />

      <div className="products-all">
        {orders?.map(order => (
          <React.Fragment key={order.id}>
            <TableProductItemResult order={order} resultLayout={true} />
          </React.Fragment>
        ))}
      </div>

      <div className="total-price mt-4">
        <h2 className="title">
          {t('common:in_general_to_return')}:{' '}
          <PriceToStr
            stAll={{fontWeight: 'bold'}}
            data={totalPrice}
            beforeText={''}
            text={orders[0]?.currency_simbol}
            grn={t('common:uah')}
          />
        </h2>
      </div>
    </div>
  );
};
