import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
//import PropTypes from 'prop-types';
import moment from 'moment';
// import Table from '@material-ui/core/Table';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';
// import TableHead from '@material-ui/core/TableHead';
//import TableSortLabel from '@material-ui/core/TableSortLabel';
//import Paper from '@material-ui/core/Paper';
import {withRouter} from 'react-router-dom';
// import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from '@material-ui/core/styles';
import {
  // TableBody,
  Grid,
  Button,
  Box,
} from '@material-ui/core';

//import DateFnsUtils from '@date-io/date-fns';
//import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';

import {useTranslation} from 'react-i18next';

import clsx from 'clsx';

// import {userActions} from '../_actions';

// import PriceToStr from '../../../_components/PriceToStr';
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
// import i18n from "../../../i18n";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
// import NativeSelect from '@material-ui/core/NativeSelect';
// import PriceToStr from "../../_components/PriceToStr";
import axInst from '../../_helpers/axios-instance';
// import IconButton from "@material-ui/core/IconButton";
// import KeyboardArrowUpIcon from "@material-ui/core/SvgIcon/SvgIcon";
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import AttentionRed from "../../icons/AttentionRed";
// import AttentionYellow from "../../icons/AttentionYellow";
// import Typography from "@material-ui/core/Typography";
// import moment from "moment";
// import ReactHtmlParser from "react-html-parser";
// import PriceToStr from "../../_components/PriceToStr";
// import Collapse from "@material-ui/core/Collapse";
// import Box from "@material-ui/core/Box";
// import ListItemText from "@material-ui/core/ListItemText";
import ScheduleDeliveriesHead from './components/ScheduleDeliveriesTableHead';
// import ScheduleDeliveriesRows from "./components/ScheduleDeliveriesRows";
// import IconButton from "@material-ui/core/IconButton";
// import StyledBadgeOne from "../../_components/StyledBadgeOne";
// import BasketAdd from "../../icons/BasketAdd";

const MOBILE_SIZE = 768;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2, 0),
    '& h4': {
      fontWeight: '500',
      fontSize: '24px',
      [theme.breakpoints.down(MOBILE_SIZE)]: {
        fontSize: '16px',
        fontWeight: '600',
        margin: '13px 0',
      },
    },
  },
  table: {
    '& div': {
      textAlign: 'center',
    },
    // '& >div:nth-child(odd)': {backgroundColor: '#fff'},
  },

  groupRow: {
    padding: '0 2px 0 0',
    '& >div:nth-child(odd)': {backgroundColor: '#fff'},
  },

  contentRow: {
    transition: 'background .5s',

    '&:hover': {
      transition: 'background .5s',
      background: '#FFD55B !important',
    },
  },

  cellRightBorder: {
    borderRight: '#dedede 1px solid',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '500',
    color: '#505050',
    borderRight: '#dedede 1px solid',
    // borderLeft:"#dedede 1px solid",
    padding: '3px 4px !important',
    position: 'relative',
    height: '38px',
  },

  captionGroup: {
    '& div': {
      // borderTop: '#e8e8e8 solid 15px',
      background: '#FFBE00',
      fontWeight: '400',
      textAlign: 'left',
      fontSize: 14,
      padding: '4px 15px!important',
    },
  },

  hideRow: {
    display: 'none',
  },

  btnShow: {
    background: '#F5F7FB !important',
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'end',

    // "& :hover": {
    //     background: 'transparent!important'
    // }
  },
}));

const ScheduleDeliveries = props => {
  // const {
  //     // customerId,
  //     // userBalance,
  //     // loggedIn,
  //     // contragentData,
  //     // main_currency,
  // } = props;

  // console.log(customerId, 'customerId')

  const classes = useStyles();
  const {t} = useTranslation();
  const [scheduleList, setScheduleList] = useState([]);
  const [open, setOpen] = React.useState(false);
  // const [expanded, setExpanded] = React.useState(false);
  // const [scheduleListItems, setScheduleListItems] = useState([]);

  useEffect(() => {
    axInst.get('/api/delivery/scheduler_page/', {}).then(res => {
      setScheduleList(res.data.data);
    });
  }, []);

  // console.log(scheduleList);
  let scheduleListItems = [];

  for (let key in scheduleList) {
    scheduleListItems.push({
      name: key,
      val: scheduleList[key],
    });
    // console.log(key, 'key');
    // console.log(scheduleList[key], 'scheduleList[key]');
  }
  // scheduleList.type = scheduleListItems;
  // console.log(scheduleListItems, 'scheduleListItems');
  // console.log(scheduleList, 'scheduleList');

  // debugger;
  //     console.log(typeof scheduleList);
  //     console.log(Array.isArray(scheduleListItems) );
  //     let arr = [];
  //     console.log(Array.isArray(arr));
  //     scheduleList.type.map((item, i) => {
  //         return true
  //     });

  // const handleChange = (panel) => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  // };

  // const handleClose = () => {
  //
  //   setOpenOrganization(false);
  // };
  //
  // const handleOpen = () => {
  //   setOpenOrganization(true);
  // };
  console.log(open, 'open');

  // function weekDays (day) {
  //     let date = new Date();
  //     let targetDay = day;
  //
  //     if(day.length>2){
  //         targetDay = new Date(day).getDay();
  //         console.log(targetDay, 'targetDay')
  //     } else  {
  //         targetDay = day;
  //     }
  //
  //     let targetDate = new Date();
  //     let delta = targetDay - date.getDay();
  //     if (delta >= 0) {targetDate.setDate(date.getDate() + delta)}
  //     else {targetDate.setDate(date.getDate() + 7 + delta)}
  //     // alert(targetDate);
  //     console.log(day);
  //
  //     return moment(targetDate).format('DD.MM')
  // };

  return (
    <div className={classes.root}>
      <Box className="wrapper-inner">
        <Grid container>
          <Grid item xs={12}>
            <h4 style={{margin: '0 0 10px 0px', fontSize: '24px'}}>
              {t('common:delivery_schedule')}
            </h4>
            <div
              className={clsx(classes.table)}
              aria-label="simple table"
              stickyHeader>
              <ScheduleDeliveriesHead />
              <div className={clsx(classes.groupRow)}>
                {scheduleListItems &&
                  scheduleListItems.map((el, index) => (
                    <React.Fragment key={index}>
                      <Grid container className={clsx(classes.captionGroup)}>
                        <Grid
                          xs={12}
                          style={{background: '#008CB4 !important'}}>
                          {el.val[0].delivery_type === 4
                            ? t('common:trucking')
                            : el.val[0].delivery_type === 3
                            ? t('common:minibus')
                            : el.name}
                          {/*{el.name}*/}
                        </Grid>
                      </Grid>
                      {el.val.map((item, index) => (
                        <Grid
                          key={index}
                          container
                          className={
                            index > 9 && open !== el.name
                              ? clsx(classes.hideRow, classes.contentRow)
                              : classes.contentRow
                          }>
                          <Grid container item xs={8}>
                            <Grid xs={2} className={clsx(classes.cell)}>
                              <span>
                                {/*{item.delivery_type === 2 ? item.address: item.name}*/}
                                {item.address === '' ? item.name : item.address}
                                {/*{item.address}*/}
                              </span>
                            </Grid>
                            <Grid xs={2} className={classes.cell}>
                              <span>{item.city_from}</span>
                            </Grid>
                            <Grid xs={2} className={clsx(classes.cell)}>
                              <span>{item.city_to}</span>
                            </Grid>
                            <Grid xs={2} className={classes.cell}>
                              <span>{item.region_to}</span>
                            </Grid>
                            <Grid xs={2} className={clsx(classes.cell)}>
                              <span>
                                {item.time_order.split(':')[0]}:
                                {item.time_order.split(':')[1]}
                              </span>
                            </Grid>
                            <Grid xs={2} className={classes.cell}>
                              <span>
                                {item.time_delivery.split(':')[0]}:
                                {item.time_delivery.split(':')[1]}
                              </span>
                            </Grid>
                          </Grid>
                          {/*<Grid xs className={classes.cell}>*/}
                          {/*    {item.previous_day? 'так': 'нi'}*/}
                          {/*</Grid>*/}
                          <Grid container item xs={4}>
                            {item.days_week.map((el, index) => (
                              <>
                                {index < 6 ? (
                                  <Grid key={index} xs={2} className={classes.cell}>
                                    {/*<span>{el !== null? moment(el).format('DD.MM'):''}</span>*/}
                                    <span>{el}</span>
                                    {item.date_delivery === '0001-01-01'
                                      ? null
                                      : new Date(item.date_delivery).getDay() -
                                          1 ===
                                        index
                                      ? moment(item.date_delivery).format(
                                          'DD.MM',
                                        )
                                      : null}
                                  </Grid>
                                ) : null}
                              </>
                            ))}

                            {/*<Grid xs={2} className={classes.cell}>*/}
                            {/*    {item.days_week ?( item.days_week.map((el, index) => (*/}
                            {/*        <span>{el === '0'? weekDays(1):null}</span>*/}
                            {/*    ))):weekDays(item.date_delivery)}*/}
                            {/*</Grid>*/}
                            {/*<Grid xs={2} className={classes.cell}>*/}
                            {/*    {item.days_week ?( item.days_week.map((el, index) => (*/}
                            {/*        <span>{el === '1'? weekDays(2):null}</span>*/}

                            {/*    ))):weekDays(item.date_delivery)}*/}
                            {/*    /!*{item.days_week.length === 0?<span>{weekDays(item.date_delivery)}</span>: null}*!/*/}
                            {/*</Grid>*/}
                            {/*<Grid xs={2} className={classes.cell}>*/}
                            {/*    {item.days_week ?( item.days_week.map((el, index) => (*/}
                            {/*        <span>{el === '2'? weekDays(3):null}</span>*/}
                            {/*    ))):weekDays(item.date_delivery)}*/}
                            {/*    /!*{item.days_week.length === 0?<span>{weekDays(item.date_delivery)}</span>: null}*!/*/}
                            {/*</Grid>*/}
                            {/*<Grid xs={2} className={classes.cell}>*/}
                            {/*    {item.days_week ? (item.days_week.map((el, index) => (*/}
                            {/*        <span>{el === '3'? weekDays(4):null}</span>*/}
                            {/*    ))):weekDays(item.date_delivery)}*/}
                            {/*    /!*{item.days_week.length === 0?<span>{weekDays(item.date_delivery)}</span>: null}*!/*/}
                            {/*</Grid>*/}
                            {/*<Grid xs={2} className={classes.cell}>*/}
                            {/*    {item.days_week ?( item.days_week.map((el, index) => (*/}
                            {/*        <span>{el === '4'? weekDays(5):null}</span>*/}
                            {/*    ))):weekDays(item.date_delivery)}*/}
                            {/*    /!*{item.days_week.length === 0?<span>{weekDays(item.date_delivery)}</span>: null}*!/*/}
                            {/*</Grid>*/}
                            {/*<Grid xs={2} className={classes.cell} style={{borderRight: 'rgba(255, 255, 255, 0) 1px solid'}}>*/}
                            {/*    {item.days_week ? (item.days_week.map((el, index) => (*/}
                            {/*        <span>{el === '5'? weekDays(6):null}</span>*/}
                            {/*    ))):weekDays(item.date_delivery)}*/}
                            {/*    /!*{item.days_week.length === 0?<span>{weekDays(item.date_delivery)}</span>: null}*!/*/}
                            {/*</Grid>*/}
                            {/*<Grid xs className={classes.cell}>*/}
                            {/*    {item.date_delivery === '0001-01-01'? '':  moment(item.date_delivery).format('DD.MM.YYYY')}*/}
                            {/*</Grid>*/}
                          </Grid>
                        </Grid>
                      ))}

                      <div>
                        <div
                          colSpan={14}
                          className={el.val.length > 9 ? classes.btnShow : ''}
                          style={{height: '60px'}}>
                          {el.val.length > 9 ? (
                            <button
                              className={'btn'}
                              onClick={() => {
                                open === el.name
                                  ? setOpen(false)
                                  : setOpen(el.name);
                              }}>
                              {open === el.name
                                ? t('common:hide')
                                : t('common:show_all')}
                            </button>
                          ) : // <Button
                          //   color="primary"
                          //   variant="contained"
                          // onClick={() => {
                          //   open === el.name
                          //     ? setOpen(false)
                          //     : setOpen(el.name);
                          // }}>
                          // {open === el.name
                          //   ? t('common:hide')
                          //   : t('common:show_all')}
                          // </Button>
                          null}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

function mapState(state) {
  const {userBalance, userSelectedData, authentication, contragentData} = state;
  const {customer_id: customerId} = userSelectedData;
  const {loggedIn} = authentication;
  const {main_currency} = state.frontendData;

  return {
    customerId,
    userBalance,
    loggedIn,
    contragentData,
    main_currency,
  };
}

const actionCreators = {
  // getFullPaymentCalendar: userActions.getFullPaymentCalendar,
  // getPaymentCalendarByOrganization: userActions.getPaymentCalendarByOrganization
};

export default connect(
  mapState,
  actionCreators,
)(withRouter(ScheduleDeliveries));
// export default withRouter(ScheduleDeliveries);
