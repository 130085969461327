import React from 'react';
import {
    // withStyles,
    makeStyles} from '@material-ui/core/styles';
import connect from "react-redux/es/connect/connect";
import {Button, Divider, IconButton, Popover} from "@material-ui/core";
import UserCircle from "../icons/UserCircle";
import {Link as RouterLink, withRouter} from "react-router-dom";
// import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
//import Heart from "../icons/Heart";
import Avatar from "@material-ui/core/Avatar";
import SelectLanguage from "./SelectLanguage";
import Grid from "@material-ui/core/Grid";
// import SelectCurrency from "./SelectCurrency";
import {SidebarNav} from "../layouts/Main/components/Sidebar/components";
import SignOut from "../icons/SignOut";
import Wallet from "../icons/Wallet";
import {useTranslation} from "react-i18next";
//import NavigateNext from '@material-ui/icons/NavigateNext';
// import SelectCompany from "./SelectCompany";
// import {ProfileBalance} from '../views/Balance/Balance';
import SelectContragent from "./SelectContragent";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
// import Tooltip from '@material-ui/core/Tooltip';
import {cartActions, customerActions,
    userActions
} from "../_actions";
import clsx from "clsx";

import PriceToStr from './PriceToStr';

// import { userService } from '../_services';

import InvoiceIcon from '../images/invoice_icon.svg';
import ReconciliationAct from '../images/act_sverki.svg';
import sheduleDeliveries from '../images/logistic.svg';
import returnIcon from '../images/return.svg';
// import preOrders from '../images/cargo-ship.svg';
import PlayList from "../icons/PlayList";
import Preloader from './Preloader';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#f7f7f7',
        width: 400
    },

    divider: {
        margin: theme.spacing(1, 0),
    },
    profilePopover: {
        //padding: 10,
        textAlign: 'center',
    },
    profilePopoverTitle: {
        margin: 0,
        marginBottom: 10,
        fontSize: 20,
    },

    // profilePopoverTitleReg1: {
    //     margin: 0,
    //     fontSize: 12,
    // },
    profilePopoverTitleReg2: {
        margin: 0,
        fontSize: 18,
        fontWeight: 600
    },

    profilePopoverIcon: {
        marginBottom: 10,
        fontSize: 60,
        color: '#001b2d',
    },
    profilePopoverLogin: {
        backgroundColor: '#2a4256',
        color: '#fff',
    },
    profilePopoverNewUser: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            marginRight: 20,
        },
        '& a': {
            color: '#006ce5',
            textDecoration: 'none',
        },
    },
    profilePopoverIcons: {
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'space-between',
        alignItems: 'center',
        '& a': {
            width: '95px',
            height: '95px',

            '& button': {
                borderRadius: '0!important',
            }

        },
        '& button span': {
            display: 'flex',
            flexFlow: 'column wrap',
            color: '#001b2d',
            '& svg': {
                fontSize: 32,
                marginBottom: 8,
            },
            '& span': {
                fontSize: 14,
                color: '#000',
            },
            '& img': {
                height: '32px',
                marginBottom: 8,
            },
        },
    },

    blHead: {
        paddingTop: 10,
        display: "block",
        backgroundColor: '#f9c533',
        boxShadow: '0 2px 3px gray',
    },
    gridBl: {
        padding: '0 10px',
        display: 'flex',
        justifyContent: 'space-between',
    },

    avatar: {
        display: 'inline-flex',
        margin: 0,
        width: 40,
        height: 40,
        color: '#777777',
        backgroundColor: "white",
        border: '1px solid #999999'
    },

    primaryPrice: {
        lineHeight: 1.1,
        //textAlign: 'right',
        fontSize: 16,
    },
    secondaryPrice: {
        lineHeight: 1.2,
        fontSize: 14,
    },
    showSelectContragent: {
        cursor: 'pointer',
    },

    financeContainer:{
        '& a':{
            color:'#000'
        }
    },

    balance_by_organization: {
        width: 200,
        listStile: 'none',
        margin: 0,
        padding: 0,
        "& li": {
            display:'flex',
            justifyContent: 'space-between',
            padding: '0 0 5px',
        }
    },

    overlayPreloader: {
        position: 'fixed',
        top:'64px',
        left: 0,
        width: '100vw',
        height: '100vh',
        background:'#eee',
        zIndex: 2

    },
}));

const MyAccountHeader = props => {
    const classes = useStyles();
    const {
        history,
        handlePopoverClose,
        anchorEl,
        abbrName,
        userSelectedData,
        // frontendData,
        customer,
        // userSelectedData,
        authentication,
        currencies,
        //setCustomerData
        // financeCalendar,
        getFullPaymentCalendar,
        getPaymentCalendarByOrganization,
        userBalance,
        getCart,
        // contragentData,
        financeCalendarByOrganization,
        // getFrontendData,
        // getContragentData
    } = props;
    const [showSelectContragent, setShowSelectContragent] = React.useState(false);
    const [testBlock, setTestBlock] = React.useState(false);
    // let currency = null;
    // if (frontendData.currencies !== undefined) {
    //     currency = frontendData.currencies.find(
    //         x => x.id === userSelectedData.sell_currency_id,
    //     );
    // }

    // console.log(getPaymentCalendarByOrganization, '--->>>getPaymentCalendarByOrganization');
    // console.log(financeCalendarByOrganization, '--->>>financeCalendarByOrganization');
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');

    const {t} = useTranslation();

    /*const SbDocument = [
        {
            title: t('common:documents'),
            fontWeight: 'bold',
            fontSize: '20px',
            href: '',
            style: {
                fontWeight: 'bold',
                fontSize: '20px',
                color: 'black',
                marginLeft: '-30px',
            },
        },
        {
            title: t('common:order'),
            href: '/orders',
            icon: '',
            icon2: <NavigateNext/>,
            style: {marginLeft: '-24px'},
        },
        {
            title: t('common:expenditure_overhead'),
            href: '/products',
            icon: '',
            icon2: <NavigateNext/>,
            style: {marginLeft: '-24px'},
        },
        {
            title: t('common:return'),
            href: '/products',
            icon: '',
            icon2: <NavigateNext/>,
            style: {marginLeft: '-24px'},
        },
        {
            title: '...',
            href: '/products',
            icon: '',
            icon2: <NavigateNext/>,
            style: {marginLeft: '-24px'},
        },
    ];*/

    const SbProfile = [
        {
            title: t('common:go_out'),
            href: '/sign-out',
            icon: <SignOut/>,
            icon2: '',
        },
    ];

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    let contrData = '';
    if (authentication.loggedIn && customer.results && customer.results.length > 0
        && userSelectedData.customer_name
        && userSelectedData.customer_discount_group === undefined) {
        let tmp = customer.results.find(
            x => x.text === userSelectedData.customer_name,
        );
        if (tmp) contrData = tmp.discount_group + '; ' + tmp.credit_limit_currency;
    }

    // React.useEffect(() => {
    //      return getFullPaymentCalendar(userSelectedData.customer_id);
    //      // eslint-disable-next-line
    //  }, [authentication.loggedIn]);

    React.useEffect(() => {
        // getContragentData(userSelectedData.customer_id);
        // getPaymentCalendarByOrganization(userSelectedData.customer_id);
        // getFullPaymentCalendar(userSelectedData.customer_id);
        getCart()
        // eslint-disable-next-line
    }, [authentication.loggedIn]);

    // console.log(userSelectedData, '----------userSelectedData.customer_id');

    React.useEffect(() => {
        // console.log('kggkgkgkgkgk');
        // getContragentData(userSelectedData.customer_id);
        getPaymentCalendarByOrganization(userSelectedData.customer_id);
        getFullPaymentCalendar(userSelectedData.customer_id);
        getCart()
        // eslint-disable-next-line
    }, [userSelectedData.customer_id]);

    if (!currencies) return <React.Fragment/>;

    const SignIn = () => {//for MyAccount
        history.push('/sign-in');
    };
    const NewContragent = () => {//for MyAccount
        // history.push('/');
        // console.log(window.location.pathname, 'window.location.href');
        // history.push( window.location.pathname);
        setTestBlock(true);
        // getFrontendData();
        // console.log(userSelectedData, 'userSelectedData.customer_id header')
        // getContragentData(userSelectedData.customer_id);
        // console.log(getPaymentCalendarByOrganization(userSelectedData.customer_id),'getPaymentCalendarByOrganization(userSelectedData.customer_id);');
        getCart();
        // getFullPaymentCalendar(userSelectedData.customer_id);

        handlePopoverClose();
        // console.log(getPaymentCalendarByOrganization(userSelectedData.customer_id));
        // getPaymentCalendarByOrganization(userSelectedData.customer_id);
        if(financeCalendarByOrganization.loading === false) {
            setTimeout(function () {
                window.location.reload();
            }, 2000);
        }


        // return ()
        // window.location.reload();
    };

    // console.log(userSelectedData, '=================userSelectedData  my account header')

    const showStrSearch = () => {
        if (customer.results && customer.results.length > 1) {
            if (showSelectContragent) setShowSelectContragent(false);
            else {
                setShowSelectContragent(true);
                document.addEventListener("click", handleClickOutsidAutosug, false);
            }
        }
    };

    const handleClickOutsidAutosug = e => {
        let elt = e.target.closest("#SelectContragent");
        if (elt === null) {
            setShowSelectContragent(false);
            document.removeEventListener("click", handleClickOutsidAutosug, false);
        }
    };

    const loggedOut = (
        <div className={classes.profilePopover} style={{padding: '20px 20px 10px 20px'}}>
            <p className={classes.profilePopoverTitle}>{t('common:my account')}</p>
            <UserCircle className={classes.profilePopoverIcon}/>
            <Button
                fullWidth
                color="secondary"
                variant="contained"
                className={classes.profilePopoverLogin}
                onClick={SignIn}
            >
                {t('common:LOGIN')}
            </Button>
            <div className={classes.profilePopoverNewUser}>
                <p>{t('common:No account?')}</p>
                <RouterLink to="/sign-up">{t('common:Sign up')}</RouterLink>
            </div>
            <Divider className={classes.divider}/>
            <Grid container item xs={12} alignItems="center" className={classes.gridBl}>
                <Grid item xs={6} align="left">
                    <SelectLanguage showLaguage={1}/>
                </Grid>
                <Grid item xs={6} align="right">
                    {/*<SelectCurrency/>*/}
                </Grid>
            </Grid>
        </div>
    ); 

    // console.log(financeCalendar, 'financeCalendar');
    // console.log(financeCalendarByOrganization, 'financeCalendarByOrganization');
    // console.log(userBalance, 'userBalance');
    // console.log(contragentData, '======================>>>>>>>>>>>>>>>>>>contragentData');

    const stPositive = {color: '#008000',  margin: '0 0 10px'};
    const stNegative = {color: '#C43939', margin: '0 0 10px'};
    // const customerData = contragentData.customer_data;


    const getCurrencySymbol = () => {
        if(financeCalendarByOrganization.loading === false && financeCalendarByOrganization.result.current_currency!=='UAH'){

            return ' ' + financeCalendarByOrganization.result.current_symbol;
        }
    }

    // const HtmlTooltip = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: '#f5f5f9',
    //         color: 'rgba(0, 0, 0, 0.87)',
    //
    //         fontSize: theme.typography.pxToRem(12),
    //         border: '1px solid #dadde9',
    //     },
    // }))(Tooltip);
    

    const loggedIn = (
        <div className={classes.profilePopover}>
            <div className={classes.blHead}>
                <Grid container item xs={12} alignItems="center" className={classes.gridBl}>
                    <Grid item xs={2} align="left">
                        {abbrName !== '-' ? (
                            <Avatar className={classes.avatar}>{abbrName}</Avatar>
                        ) : (
                            <UserCircle className={classes.profilePopoverIcon}/>
                        )}
                    </Grid>
                    <Grid item xs={10} align="right" className={classes.financeContainer}>
                        <RouterLink to="/profile/finance" onClick={()=>handlePopoverClose()}>
                            {/*<HtmlTooltip*/}

                            {/*    title={*/}
                            {/*        <React.Fragment>*/}
                            {/*            {userBalance.balance_by_organization && userBalance.balance_by_organization.map((item, index) =>*/}
                            {/*                <ul className={classes.balance_by_organization}>*/}
                            {/*                    <li color="inherit" key={index}>*/}
                            {/*                        <span>{item.name}</span>*/}
                            {/*                        <span>{item.balance}{getCurrencySymbol()}</span>*/}
                            {/*                    </li>*/}

                            {/*                </ul>*/}
                            {/*            )}*/}

                            {/*        </React.Fragment>*/}
                            {/*    }*/}
                            {/*>*/}
                            <Grid container className={classes.financeContainer}>

                                <Grid item xs={12} align="left" style={{margin: '0 0 10px'}}>{userBalance.current_balance < 0 ? t('common:debt') : t('common:your_balance') }</Grid>
                                {financeCalendarByOrganization.loading === false && financeCalendarByOrganization.result.organization_data.map((item, index) =>
                                    <React.Fragment key={index}>
                                        <Grid item xs={8} align="left">{item.organization}</Grid>
                                        <Grid item xs={4}><PriceToStr data={ item.shipment_allowed === false ? item.full_payment * -1 : item.full_payment  * -1} />{getCurrencySymbol()}</Grid>

                                        {item.shipment_allowed === false && item.overdue_payment !== undefined ?
                                            <>
                                                <Grid item xs={8} align="left" style={stNegative}>{t('common:outdated_debt')}:</Grid>
                                                <Grid item xs={4} style={stNegative}><b><PriceToStr data={item.overdue_payment}/>{getCurrencySymbol()}</b></Grid>
                                            </> : null }

                                        {item.shipment_allowed === true && item.overdue_payment !== undefined ?
                                            <>
                                                <Grid item xs={8} align="left" style={stPositive}>{t('common:shipment_available_sum_2')}: </Grid>
                                                <Grid item xs={4} style={stPositive}><b><PriceToStr stPositive={stPositive} data={item.credit_limit + item.full_payment*-1} />{getCurrencySymbol()}</b></Grid>
                                            </> : null }
                                    </React.Fragment>
                                )}
                                {/*<>*/}
                                {/*    <Grid item xs={8}>ТОВ "ДАНІ-ПАРТС</Grid>*/}
                                {/*    <Grid item xs={4}><PriceToStr data={userBalance.current_balance} />{getCurrencySymbol()}</Grid>*/}

                                {/*    {financeCalendar.shipment_allowed === false ?*/}
                                {/*    <>*/}
                                {/*        <Grid item xs={8} align="left" style={stNegative}>{t('common:outdated_debt')}:</Grid>*/}
                                {/*        <Grid item xs={4} style={stNegative}><b><PriceToStr data={financeCalendar.outdatedDebt} />{getCurrencySymbol()}</b></Grid>*/}
                                {/*    </> : null }*/}

                                {/*    {financeCalendar.shipment_allowed === true ?*/}
                                {/*    <>*/}
                                {/*        <Grid item xs={8} align="left" style={stPositive}>{t('common:shipment_available_sum_2')}: </Grid>*/}
                                {/*        <Grid item xs={4} style={stPositive}><b><PriceToStr stPositive={stPositive} data={customerData && userBalance ? customerData.credit_limit_sum + userBalance.current_balance: null} />{getCurrencySymbol()}</b></Grid>*/}
                                {/*    </> : null }*/}
                                {/*</>*/}
                            </Grid>
                            {/*</HtmlTooltip>*/}

                        </RouterLink>
                        {/*<p className={classes.profilePopoverTitleReg1}>Ваш борг</p>*/}
                        {/*<p className={classes.profilePopoverTitleReg2} style={{color: 'red'}}>*/}
                        {/*    {currency !== null &&*/}
                        {/*    <PriceToStr data={10009876.564}*/}
                        {/*                beforeText={''} text={currency.simbol} grn={true}/>*/}
                        {/*    }*/}
                        {/*</p>*/}
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" className={classes.gridBl} style={{marginTop: -6}}>
                    <Grid item xs={7} align="left">
                        <p>{authentication.user !== undefined ? authentication.user : ''}</p>
                    </Grid>
                    <Grid item xs={5} align="right">
                        {/*<SelectCurrency/>*/}
                    </Grid>
                </Grid>

                <Grid container item xs={12} alignItems="center" className={classes.gridBl} style={{marginTop: -4}}>
                    <Grid item xs={11} align="left" title={t('common:change_contractor')}>
                        <ListItemText onClick={showStrSearch}
                                      className={classes.showSelectContragent}
                                      classes={{
                                          primary: classes.primaryPrice,
                                          secondary: classes.secondaryPrice
                                      }}
                                      primary={`${userSelectedData.customer_name}`}
                                      secondary={userSelectedData.customer_discount_group !== undefined ? userSelectedData.customer_discount_group : contrData}
                        />
                    </Grid>
                    <Grid item xs={1} align="right" title={t('common:change_contractor')}>
                        {customer.results && customer.results.length > 1
                            ? showSelectContragent
                                ? <ArrowDropUp onClick={showStrSearch}
                                               className={classes.showSelectContragent}/>
                                : <ArrowDropDown onClick={showStrSearch}
                                                 className={classes.showSelectContragent}/>
                            : ''
                        }
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" id="SelectContragent"
                      className={classes.gridBl}
                      style={{marginTop: -10, height: showSelectContragent ? 50 : 10}}
                >
                    {showSelectContragent &&
                    <SelectContragent NewContragent={NewContragent} showStrSearch={showStrSearch} customer={customer}/>
                    }
                </Grid>
                {/*<div style={{padding: 10, textAlign: "left", marginTop: -12}}>
                    <ListItemText style={{marginBottom: -6}}
                        classes={{
                            primary: classes.primaryPrice,
                            secondary: classes.secondaryPrice
                        }}
                        // primary={`${userSelectedData.customer_name}`}
                        primary={`${''}`}
                        secondary={userSelectedData.customer_discount_group !== undefined ? userSelectedData.customer_discount_group : ''}
                    />
                    <SelectCompany NewContragent={NewContragent}/>
                </div>*/}
            </div>
            <div style={{padding: 10}}>
                <div className={classes.profilePopoverIcons}>
                    <RouterLink to="/profile/profile" style={{textDecoration: 'none'}} onClick={()=>handlePopoverClose()}>
                        <IconButton aria-label="account">
                            <UserCircle/>
                            <span>{t('common:profile_menu')}</span>
                        </IconButton>
                    </RouterLink>
                    <RouterLink to="/profile/order" style={{textDecoration: 'none'}} onClick={()=>handlePopoverClose()}>
                        <IconButton title={''} color="inherit">
                            <PlayList/>
                            <span>{t('common:orders')}</span>
                        </IconButton>
                    </RouterLink>

                    <RouterLink to="/profile/finance" style={{textDecoration: 'none'}} onClick={()=>handlePopoverClose()}>
                        <IconButton aria-label="account">
                            <Wallet />
                            <span>{t('common:finance')}</span>
                        </IconButton>
                    </RouterLink>
                    <RouterLink to="/profile/invoice" style={{textDecoration: 'none'}} onClick={()=>handlePopoverClose()}>
                        <IconButton aria-label="account">
                            <img src={InvoiceIcon} alt='Invoice' />
                            <span>{t('common:invoice')}</span>
                        </IconButton>
                    </RouterLink>
                    {window.innerWidth > 680 ?
                        <RouterLink to="/profile/reconciliation" style={{textDecoration: 'none'}} onClick={()=>handlePopoverClose()}>
                            <IconButton aria-label="account">
                                <img src={ReconciliationAct} alt='reconciliation' />
                                <span>{t('common:reconciliation')}</span>
                            </IconButton>
                        </RouterLink>
                        : null
                    }

                    <RouterLink to="/schedule-deliveries" style={{textDecoration: 'none'}} onClick={()=>handlePopoverClose()}>
                        <IconButton aria-label="account">
                            <img src={sheduleDeliveries} alt='sheduleDeliveries' />
                            <span>{t('common:delivery_schedule')}</span>
                        </IconButton>
                    </RouterLink>
                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdUCVzJCbWeru9fnTV1wvPIGGF7zKXFLa40YunWbDzOuSV0BQ/viewform" style={{textDecoration: 'none'}} onClick={()=>handlePopoverClose()}>
                        <IconButton aria-label="account">
                            <img src={returnIcon} alt='sheduleDeliveries' />
                            <span>{t('common:app_for_return')}</span>
                        </IconButton>
                    </a>
                    {/*<RouterLink to="/pre-orders/previous-order" style={{textDecoration: 'none'}} onClick={()=>handlePopoverClose()}>*/}
                    {/*    <IconButton aria-label="account">*/}
                    {/*        <img src={preOrders} alt='preOrders' />*/}
                    {/*        <span>{t('common:preOrder')}</span>*/}
                    {/*    </IconButton>*/}
                    {/*</RouterLink>*/}

                    {/*<IconButton aria-label="garage">*/}
                    {/*    <EmojiTransportationIcon/>*/}
                    {/*    <span>{t('common:garage')}</span>*/}
                    {/*</IconButton>*/}
                    {/*<FavoriteBorderIcon/>*/}
                    {/*<IconButton aria-label="favorite">
                        <Heart/>
                        <span>{t('common:favorites')}</span>
                    </IconButton>*/}
                </div>
                {/*<Divider className={classes.divider}/>
                <div style={{display: 'block'}}>
                    <SidebarNav className={classes.nav} pages={SbDocument}/>
                </div>*/}
                <Divider className={classes.divider}/>
                <Grid container item xs={12} alignItems="center" className={classes.gridBl} style={{padding: 0}}>
                    <Grid item xs={6} align="left">
                        <SidebarNav className={classes.nav} pages={SbProfile}/>
                    </Grid>
                    <Grid item xs={6} align="right" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {/*<SelectCurrency/>*/}
                        <SelectLanguage showLaguage={1}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    );

    return (
        <>
            {testBlock &&
            <React.Fragment>
                <div className={classes.overlayPreloader}>
                    <Preloader />
                </div>

            </React.Fragment>

            }

            <Popover
            classes={{
                paper: clsx(classes.root, "my_account_header"),
            }}
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            {authentication.loggedIn
                ? loggedIn
                : loggedOut
            }
        </Popover>
        </>
    );
};


class Wrapper extends React.Component {
    componentDidMount() {
        if (!this.props.customer.results) this.props.getCustomers();

        if (this.props.loggedIn) {
            this.props.getUserBalance(this.props.customerId);
            this.props.getContragentData(this.props.customerId, this.props.main_currency);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.userSelectedData.customer_name
            !== prevProps.userSelectedData.customer_name) this.props.getCustomers();

        if (this.props.customerId && this.props.customerId !== prevProps.customerId) {
            // console.log(this.props.customerId, '!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!this.props.customerId')
            this.props.getUserBalance(this.props.customerId);
            this.props.getContragentData(this.props.customerId, this.props.main_currency);
        }


    }

    render() {
        return <MyAccountHeader {...this.props} />
    }
}

function mapState(state) {
    const {
        customer,
        userSelectedData,
        frontendData,
        authentication,
        financeCalendar,
        userBalance,
        contragentData,
        financeCalendarByOrganization,
        getFrontendData,
        getContragentData
    } = state;
    const {currencies} = frontendData;
    return {
        customer,
        userSelectedData,
        authentication, currencies,
        financeCalendar,
        userBalance,
        contragentData,
        financeCalendarByOrganization,
        getFrontendData,
        getContragentData
    };
}

const actionCreators = {
    getCustomers: customerActions.getCustomers,
    getFullPaymentCalendar: userActions.getFullPaymentCalendar,
    getPaymentCalendarByOrganization: userActions.getPaymentCalendarByOrganization,
    getCart: cartActions.getCart,
    getFrontendData: userActions.getFrontendData,
    getContragentData: userActions.getContragentData,
    //setCustomerData: customerActions.setCustomerData,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
