import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation, withRouter} from 'react-router-dom';
import TabPaneAnalogNumbers from './TabItems/TabPaneAnalogNumbers';
import TabPaneAnalogs from './TabItems/TabPaneAnalogs';
import {TabPaneDescription} from './TabItems/TabPaneDescription';
import {TabPaneGetProduct} from './TabItems/TabPaneGetProduct';
import {TabPaneQuestion} from './TabItems/TabPaneQuestion';
import {TabPaneReview} from './TabItems/TabPaneReview';

import {TabPanelPacking} from './TabItems/components/TabPanelPacking';
import {dataProcessing} from '../../../_helpers';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {cartActions} from '../../../_actions';
import {productsActions} from '../../../_actions/product.actions';
import {useRef} from 'react';
import TabPanelRelatedProducts from './TabItems/TabPanelRelatedProducts';
import clsx from 'clsx';

const DetailProductTabs = props => {
  const {
    result,
    productAnalogNumbers,
    getAnalogProducts,
    cartAll,
    currencyMain,
    currencyMainContr,
    searchByCrossOwn,
    listMyFavorite,
    promotionsByProductsList,
    listSearchRelated,
  } = props;
  const {t} = useTranslation();
  const searchParams = useLocation();
  const history = useHistory();
  const searchedTab = searchParams.search.split('?tab=').join('');
  const tabsRef = useRef();

  const [activeTab, setActiveTab] = useState(searchedTab || 'receiving');
  const [analogsCount, setAnalogsCount] = useState(null);
  const [relatedCount, setRelatedCount] = useState(null);

  const handleActiveTab = tab => {
    history.push({
      pathname: searchParams.pathname,
      search: '?tab=' + tab.path,
    });
    setActiveTab(tab.path);
  };

  let relatedResultsMod = dataProcessing(
    currencyMain,
    listSearchRelated?.results,
    cartAll,
    listMyFavorite,
    '1',
    ['OWN'],
    promotionsByProductsList,
  ).results;
  let analogResultsMod = dataProcessing(
    currencyMain,
    searchByCrossOwn?.results,
    cartAll,
    listMyFavorite,
    '1',
    ['OWN'],
    promotionsByProductsList,
  ).results;

  const tabItems = {
    'related-products': (
      <TabPanelRelatedProducts
        currencyMainContr={currencyMainContr}
        currencyMain={currencyMain}
        resultsMod={relatedResultsMod}
      />
    ),
    'analog-products': (
      <TabPaneAnalogs
        currencyMain={currencyMain}
        currencyMainContr={currencyMainContr}
        resultsMod={analogResultsMod}
      />
    ),
    'analog-codes': (
      <TabPaneAnalogNumbers productAnalogNumbers={productAnalogNumbers} />
    ),
    description: <TabPaneDescription result={result} />,
    receiving: <TabPaneGetProduct />,
    reviews: <TabPaneReview productId={result.article.product_id} />,
    questions: <TabPaneQuestion productId={result.article.product_id} />,
    'packaging-parameters': <TabPanelPacking result={result} />,
  };
  const tabTitles = [
    {
      title: t('common:Related products'),
      path: 'related-products',
      relatedCount: relatedCount,
    },
    {
      title: t('common:Analogues_is_in_stock'),
      path: 'analog-products',
      analogsCount: analogsCount,
    },
    {
      title: t('common:Analog_codes'),
      path: 'analog-codes',
    },
    {
      title: t('common:Description'),
      path: 'description',
    },
    {
      title: t('common:Receiving_the_good'),
      path: 'receiving',
    },
    {
      title: t('common:Reviews'),
      path: 'reviews',
      reviewsCount: result?.article?.reviews_amount,
    },
    {
      title: t('common:Question'),
      path: 'questions',
      questionsCount: result?.article?.questions_amount,
    },
    {
      title: t('common:packaging_parameters'),
      path: 'packaging-parameters',
    },
  ];

  useEffect(() => {
    setRelatedCount(relatedResultsMod?.length);
  }, [relatedResultsMod]);

  useEffect(() => {
    setAnalogsCount(analogResultsMod?.length);
  }, [analogResultsMod.length]);

  useEffect(() => {
    const selected = searchParams.hash;
    if (selected && selected.length > 0) {
      tabsRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [searchedTab]);

  useEffect(() => {
    if (productAnalogNumbers?.data?.length > 0) {
      getAnalogProducts(productAnalogNumbers.data);
    }
  }, [productAnalogNumbers]);

  // useEffect(() => {
  //   if (searchedTab === '') {
  //     // if (relatedCount === 0 && analogsCount === 0) {
  //     //   setActiveTab('packaging-parameters');
  //     // }
  //     if (relatedCount === 0) {
  //       setActiveTab('analog-products');
  //     } else if (analogsCount === 0) {
  //       setActiveTab('related-products');
  //     } else {
  //       setActiveTab('packaging-parameters');
  //     }
  //     // if (analogsCount === 0) {
  //     //   setActiveTab('related-products');
  //     // }
  //   }
  // }, [analogsCount, relatedCount]);

  useEffect(() => {
    if (searchedTab === '') {
      if (analogsCount === 0) {
        setActiveTab('related-products');
      }

      if (relatedCount === 0) {
        setActiveTab('analog-products');
      } else {
        setActiveTab('related-products');
      }

      if (analogsCount === 0 && relatedCount === 0) {
        setActiveTab('receiving');
      }
    }
  }, [relatedCount, analogsCount]);

  return (
    <div>
      <div
        ref={tabsRef}
        style={{position: 'absolute', top: '-100px', left: 0}}></div>
      <ul
        className="tabs"
        style={{
          flexWrap: 'nowrap',
          overflowX: 'auto',
          height: 42,
          alignItems: 'end',
        }}>
        {tabTitles.map(tab => (
          <li
            style={{
              cursor: 'pointer',
              position: 'relative',
              whiteSpace: 'nowrap',
            }}
            className={clsx(
              'tab-item',
              activeTab === tab.path ? 'active' : '',
              tab?.analogsCount === 0 ? 'd-none' : '',
              tab?.relatedCount === 0 ? 'd-none' : '',
            )}
            onClick={() => handleActiveTab(tab)}
            key={tab.title}>
            {tab.title}
            {tab.reviewsCount ? (
              <div className="count-amount">{tab.reviewsCount}</div>
            ) : null}
            {tab.questionsCount ? (
              <div className="count-amount">{tab.questionsCount}</div>
            ) : null}
            {tab.relatedCount ? (
              <div className="count-amount">{tab.relatedCount}</div>
            ) : null}
            {tab.analogsCount ? (
              <div className="count-amount">{tab.analogsCount}</div>
            ) : null}
          </li>
        ))}
      </ul>
      <div style={{minHeight: 400}}>{tabItems[activeTab]}</div>
    </div>
  );
};

function mapState(state) {
  const {
    productAnalogNumbers,
    getAnalogNumbers,
    product,
    searchByCrossOwn,
    listMyFavorite,
    promotionsByProductsList,
    listSearchRelated,
  } = state;
  return {
    productAnalogNumbers,
    getAnalogNumbers,
    product,
    searchByCrossOwn,
    listMyFavorite,
    promotionsByProductsList,
    listSearchRelated,
  };
}

const actionCreators = {
  getAnalogNumbers: cartActions.getAnalogNumbers,
  getAnalogProducts: productsActions.getAnalogProducts,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getAnalogNumbers(this.props.match.params.article);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.article !== prevProps.match.params.article) {
      this.props.getAnalogNumbers(this.props.match.params.article);
    }
  }

  render() {
    return <DetailProductTabs {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(withRouter(Wrapper));
