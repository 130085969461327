import {store} from '../_helpers';
import axInst from '../_helpers/axios-instance';

const state = store.getState();
const {userSelectedData, contragentData} = state;
const {customer_data} = contragentData;
const {customer_id, sell_currency_id} = userSelectedData;

export const wishlistService = {
  getWishlistItems,
};

function getWishlistItems(list) {
  if (
    !customer_id ||
    !sell_currency_id ||
    !customer_data ||
    !customer_data.credit_limit_currency_id
  )
    return Promise.reject('Wrong params');
  return axInst.post('/api/get-wish-list-products/', {
    wish_list: list,
    customer_id,
    sell_currency_id: customer_data.credit_limit_currency_id,
  });
}
