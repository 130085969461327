import axInst from '../_helpers/axios-instance';
import {handleResponse} from './cart.service';

export const newsService = {
  getNews,
};

function getNews(pageName) {
  if (!pageName)
    return axInst
      .post('/api/get-news/', {main_news: true})
      .then(handleResponse);

  return axInst
    .post('/api/get-news/', {news_type: pageName, main_news: false})
    .then(handleResponse);
}
