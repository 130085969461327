import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
    Grid,
    Button,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {useTranslation} from "react-i18next";
import {userActions} from "../../_actions";
import {connect} from "react-redux";
import validate from "validate.js";
import LoginPageContainer from "../../_components/LoginPageContainer";
import BottomInfo from "../../layouts/Minimal/components/BottomInfo";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'none' /*theme.palette.background.default,*/,
        height: '100%',
    },

    smallFont: {
        fontSize: 14,
    },

    root2: {
        height: 'calc(100vh - 888px)',
    },

    grid: {
        height: '100%',
    },
    content: {
        // textAlign: 'center',
        display: 'contents',
        flexDirection: 'column',
    },
    contentBody: {
        marginTop: 20,
        flexGrow: 1,
        display: 'inline-flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    form: {
        maxWidth: 392,
        width: '100%',
        margin: '0 auto',
        background: '#fff',
        padding: 10,
        [theme.breakpoints.down('md')]: {
            flexBasis: 600,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    iconButton: {padding: 0},
    icons: {
        display: 'block',
        fontSize: 30,
        color: '#616161',
    },
    checkbox: {
        display: 'flex',
        flexFlow: ' row wrap',
        alignItems: ' center',
        fontSize: 14,
        padding: '9px 4px 9px 2px',
        height: 62,
        marginBottom: 16,
        '& a': {
            textDecoration: 'none',
            color: '#006ce5',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    checkboxText: {
        marginTop: 0,
        marginLeft: 8,
        textAlign: 'left',
    },
    registered: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        // alignItems: 'center',
        fontSize: 14,
        '& p': {
            margin: 0,
        },
        '& a': {
            // position: 'absolute',
            // left: '50%',
            // transform: 'translateX(-50%)',
            textDecoration: 'none',
            color: '#7a7a7a',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    footer: {
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        marginTop: '4em',
        fontSize: 12,
        color: '#444',
        '& a': {
            color: '#1464f6',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    title: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        fontWeight: 400,
        fontSize: 26,
        color: '#000',
    },
    button: {
        margin: '1em 0',
    },
    socialButtons: {
        marginTop: theme.spacing(3),
    },
    socialIcon: {
        marginRight: theme.spacing(1),
    },
    sugestion: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        height: 36,
    },
    textFieldInput: {
        paddingLeft: 14,
        '& .MuiFormHelperText-root': {
            color: '#d32f2f',
        },
    },
    textFieldInputDoubleIcon: {
        padding: '0 8px 0 14px',
        '& .MuiFormHelperText-root': {
            color: '#d32f2f',
        },
    },
    textField2: {
        marginTop: theme.spacing(2),
    },
    SignUpButton: {
        margin: theme.spacing(2, 0),
    },
    socialIcons: {
        color: '#444',
        fontSize: '4em',
        margin: '0 0.2em',
    },

}));

const schema = {
    password1: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {minimum: 8, maximum: 64,},
    },
    password2: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {minimum: 8, maximum: 64,},
    },
};

const ConfirmPW = props => {
    const {t} = useTranslation();
    // console.log("ConfirmPW====props================", props);
    const {authentication, match, confirmPW} = props;
    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {
            password1: '',
            password2: '',
        },
        touched: {},
        errors: {},
    });

    function myvalidate(formData, errors) {
        if (formData.password1 !== formData.password2) {
            if (!errors) errors = {};
            if (!errors.password2) errors.password2 = [];
            errors.password2.push("===Passwords don't match");
        }
        return errors;
    }

    useEffect(() => {
        console.log("formState====useEffect================", formState.values);
        console.log("formState====touched================", formState.touched);

        if ((formState.touched["password1"]) && authentication.error)
            authentication.error.response.data.new_password2 = null;

        let errors = validate(formState.values, schema);
        errors = myvalidate(formState.values, errors);

        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {},
        }));

    }, [formState.touched, formState.values, authentication.error]);
    // }, [formState.values]
    // );

    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);

    const [passIsShow, togglePassType] = useState(false);
    const [secondPassIsShow, toggleSecondPassType] = useState(false);

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
        // console.log(" handleChange = event", formState.values);
    };


    const handleSignUp = event => {
        event.preventDefault();

        // formState.touched["password1"]=false;
        setFormState(formState => ({...formState, touched: {},}));
        const {password1} = formState.values;
        confirmPW(password1, match);
    };

    return (
        <LoginPageContainer className={'confirm_password_container'}>
            <form className={'form'} onSubmit={handleSignUp}>
                <Typography className={'title'} variant="h2">
                    {t('common:Password recovery')}

                </Typography>
                <Grid item xs={12} lg={12}>
                    <Typography color="textSecondary" className={classes.smallFont}>
                        {t('common:Your old password')}
                    </Typography>
                </Grid>

                <Grid container className={classes.textField}>
                    <Grid item xs={1}>
                        <LockIcon className={classes.icons}/>
                    </Grid>
                    <Grid item xs={10}
                          className={classes.textFieldInputDoubleIcon}>
                        <TextField
                            fullWidth
                            name="password1"
                            value={formState.values.password1 || ''}
                            onChange={handleChange}
                            placeholder={t('common:Enter your password')}
                            type={passIsShow ? 'text' : 'password'}
                            helperText={
                                (hasError('password1')
                                    && t('common:Password must be at least 8 characters')) ||
                                ((authentication.error) && (authentication.error.response)
                                    && (authentication.error.response.data.new_password2)
                                    && authentication.error.response.data.new_password2[0])
                            }
                            error={hasError('password1') ||
                            ((authentication.error) && (authentication.error.response)
                                && (authentication.error.response.data.new_password2) && true)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.iconButton}
                            aria-label="password"
                            onClick={() => togglePassType(!passIsShow)}>
                            {passIsShow ? (
                                <VisibilityOutlinedIcon className={classes.icons}/>
                            ) : (
                                <VisibilityOffOutlinedIcon className={classes.icons}/>
                            )}
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container className={classes.textField}>
                    <Grid item xs={1}>
                        <LockIcon className={classes.icons}/>
                    </Grid>
                    <Grid item xs={10}
                          className={classes.textFieldInputDoubleIcon}>
                        <TextField
                            name="password2"
                            fullWidth
                            value={formState.values.password2 || ''}
                            onChange={handleChange}
                            placeholder={t('common:confirm_password')}
                            helperText={hasError('password2') ? t('common:Passwords do not match') : ''}
                            type={secondPassIsShow ? 'text' : 'password'}
                            error={hasError('password2')}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.iconButton}
                            aria-label="password"
                            onClick={() => toggleSecondPassType(!secondPassIsShow)}>
                            {secondPassIsShow ? (
                                <VisibilityOutlinedIcon className={classes.icons}/>
                            ) : (
                                <VisibilityOffOutlinedIcon className={classes.icons}/>
                            )}
                        </IconButton>
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!formState.isValid}
                    fullWidth
                    className={classes.button}>
                    {t('common:SAVE')}
                </Button>
                <BottomInfo/>
            </form>
        </LoginPageContainer>
    );
};

function mapState(state) {
    const {authentication} = state;
    return {authentication}
}

const actionCreators = {
    confirmPW: userActions.confirmPW,
};

class Wrapper extends React.Component {
    componentDidMount() {
    }

    render() {
        return <ConfirmPW {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);
