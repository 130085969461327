import React from 'react';
import {ReviewItem} from './ReviewItem';

export const Reviews = ({reviews, isQuestion}) => {
  console.log(reviews);
  if (!reviews) return <>Loading...</>;

  return (
    <div className="reviews">
      {reviews.map((item, index) => (
        <React.Fragment key={index}>
          <ReviewItem
            isQuestion={isQuestion}
            reply={
              item?.review_answers?.length > 0 ||
              item?.question_answers?.length > 0
            }
            review={item}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
