import {Dialog} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {connect} from 'react-redux';
import {cartActions} from '../../_actions';
import {LastContent} from './components/LastContent';
import {Steps} from './components/Steps/Steps';
import './styles.scss';
const MainDialog = props => {
  const {
    getOrderList,
    orders,
    openReturnDialog: open,
    setOpenReturnDialog: setOpen,
    getInvoices,
    invoice,
    userSelectedData,
  } = props;
  const [filterFromDate, setFromDate] = useState(
    moment()
      .subtract(1, 'month')
      .add(1, 'day'),
  );
  const [filterUntilDate, setUntilDate] = useState(moment());
  const [showLastContent, setShowLastContent] = useState(false);
  const [firstOrder, setFirstOrder] = useState(null);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState({});

  useEffect(() => {
    getOrderList(
      moment(filterFromDate).format('DD.MM.YYYY'),
      moment(filterUntilDate).format('DD.MM.YYYY'),
    );
    getInvoices(
      moment(filterFromDate).format('DD.MM.YYYY'),
      moment(filterUntilDate).format('DD.MM.YYYY'),
    );
  }, [filterFromDate, filterUntilDate, getOrderList, getInvoices]);

  const handleClose = () => {
    setOpen(false);
    setShowLastContent(false);
  };

  return (
    <Dialog
      scroll="body"
      className="dialog-wrapper"
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}>
      <div className="main-dialog">
        <Steps
          showLastContent={showLastContent}
          setShowLastContent={setShowLastContent}
          orders={orders}
          setFirstOrder={setFirstOrder}
          invoices={invoice}
          userSelectedData={userSelectedData}
          setSelectedInvoiceItem={setSelectedInvoiceItem}
        />
        <LastContent
          showLastContent={!showLastContent}
          handleClose={handleClose}
          firstOrder={firstOrder}
          selectedInvoiceItem={selectedInvoiceItem}
        />
      </div>
    </Dialog>
  );
};
{
  /* <svg
        className="close-icon"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 1L14.845 15M15 1L1.15497 15"
          stroke="white"
          strokeOpacity="0.8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg> */
}

const actions = {
  getOrderList: cartActions.getOrderList,
  getInvoices: cartActions.getInvoices,
};

const mapState = state => {
  const {orders, invoice, userSelectedData} = state;
  return {orders, invoice, userSelectedData};
};

export default connect(mapState, actions)(MainDialog);
