import axInst from '../_helpers/axios-instance';
import {handleResponse} from './cart.service';
import {store} from '../_helpers';

export const promotionService = {
  searchRandomPromoProduct,
  promotionListItems,
  getPromotionsByGroupId,
  getPromoByGroupId,
};

function searchRandomPromoProduct() {
  // console.log(slug, 'slug searchPromotionList');
  // console.log(id, 'id searchPromotionList');
  const state = store.getState();
  const {userSelectedData} = state;
  const {customer_id, sell_currency_id} = userSelectedData;
  return axInst
    .post(
      '/api-promotion/promotions/promotion-random-product/',
      JSON.stringify({
        customer_id: customer_id,
        search_mode_other: '=',
        sell_currency_id: sell_currency_id,
      }),
    )
    .then();
}

function promotionListItems() {
  // console.log(slug, 'slug searchPromotionList');
  // console.log(id, 'id searchPromotionList');
  // const state = store.getState();
  // const {userSelectedData} = state;
  // const {customer_id, sell_currency_id} = userSelectedData;
  return axInst.get('/api-promotion/promotions/').then(handleResponse);
}
function getPromotionsByGroupId(id) {
  return axInst
    .post('/api-promotion/get-by-products-group/', {
      product_group_id: id,
    })
    .then(handleResponse);
}

function getPromoByGroupId(id) {
  const state = store.getState();
  const {userSelectedData} = state;
  const {customer_id, sell_currency_id} = userSelectedData;
  return axInst
    .post('/api-promotion/promotions/promotion-random-product/', {
      customer_id,
      sell_currency_id,
      product_group_id: id,
    })
    .then(handleResponse);
}
