import {alertActions} from './';
import {newsService} from '../_services/news.service';
import {errorMessage} from '../_constants/errorMessage.constants';
import {newsConstants} from '../_constants';

export const newsActions = {
  getNews,
};

function getNews(pageName) {
  return dispatch => {
    dispatch(request());

    newsService.getNews(pageName).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
      },
    );
  };

  function request() {
    return {type: newsConstants.GET_ALL_NEWS_REQUEST};
  }

  function success(data) {
    return {type: newsConstants.GET_ALL_NEWS_SUCCESS, data};
  }

  function failure(error) {
    return {type: newsConstants.GET_ALL_NEWS_FAILURE, error};
  }
}
