import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import Timer from 'react-compound-timer';
import moment from 'moment';

import logoIcon from '../../images/logo-light.svg';
import clsx from 'clsx';

export const StockItem = ({slide}) => {
  const desc = slide.promotion_description?.slice(0, 290) + '...';
  const {t} = useTranslation();
  let toDay = moment().valueOf();
  const history = useHistory();

  const createMarkup = html => ({__html: html});

  return (
    <React.Fragment>
      <div
        className={clsx(
          'promotion__left',
          slide.banner_tablet[0] ? '' : 'without-photo',
        )}
        onClick={() => history.push(`/promotion/${slide.url}/${slide.id}`)}>
        <Link to={`/promotion/${slide.url}/${slide.id}`}>
          {slide.banner_tablet[0] ? (
            <img src={slide.banner_tablet[0]} alt="promotion" />
          ) : (
            <img
              style={{objectFit: 'contain', maxWidth: 200}}
              src={logoIcon}
              alt="promotion"
            />
          )}
        </Link>
        {/* {slide?.badge.name && (
          <span
            className="info"
            style={{backgroundColor: '#' + slide?.badge.rgb_code}}>
            {slide?.badge.name}
          </span>
        )}
        <span className="percent">– 10%</span> */}
      </div>
      <div
        className="promotion__right"
        onClick={() => history.push(`/promotion/${slide.url}/${slide.id}`)}>
        <div>
          <Link to={`/promotion/${slide.url}/${slide.id}`}>
            <div className="promotion__title">{slide.name}</div>
          </Link>
          <div
            className="promotion__description formated-text-wrapper"
            dangerouslySetInnerHTML={createMarkup(slide.promotion_description)}
          />
        </div>
        <div className="promotion__footer">
          {t('common:It_remained_until')}:{' '}
          <span>
            {/* {date.getDate() - new Date().getDate()} {t('common:days')} */}
            {new Date(slide?.promotion_date_to).getDate() -
              new Date().getDate() ===
            1 ? (
              new Date(slide?.promotion_date_to).getDate() -
              new Date().getDate()
            ) : (
              <Timer
                initialTime={moment(slide.promotion_date_to).valueOf() - toDay}>
                <Timer.Days />
              </Timer>
            )}{' '}
            {t('common:days')}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
