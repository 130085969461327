import React, {useEffect} from 'react';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';



// import {useTranslation} from 'react-i18next';




// import clsx from "clsx";

// import {store} from "../../../_helpers";
import TableBody from "@material-ui/core/TableBody";
// import DaniBeznal from "../../../images/daniBeznal.png";
// import PerfectBeznal from "../../../images/perfectBeznal.png";




/* ===================================================== */

const useStyles = makeStyles(theme => ({




  print_container: {
    padding: '15px',
    background: '#fff',
    width: '100%',
    margin: '0 auto',

  },

  formTemplate: {
    padding: '15px 15px 15px 70px',
    // minHeight: '1200px',
    position: 'relative',
    maxWidth: '1200px',
    // margin: '0 20px 0 0',
  },


  headerWarningText: {
    fontSize: '12px',
    background: '#eee',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '3px 0'
  },
  headerCompanyBlock: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '5px 0 10px',
    fontSize: 11,

    "& span": {
      minWidth: '120px',
      fontSize: 11,
    },
    "& strong": {
      display: 'inline-block',
      textTransform: 'uppercase',
      // marginBottom: 5,
      fontSize: 11,
    }
  },



  watermark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-20deg)',
    width: '100%',
    textAlign: 'center',
    fontSize: '40px',
    opacity: 0.2,
    textTransform: 'uppercase',
  },

  stamp: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(90px, -50%)  rotate(-18deg)',
    width: '120px',
  },

  stamp2: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(90px, -50%)  rotate(21deg)',
    width: '120px',
  },



  table: {
    width: '100%',
    border: '1px solid #000',
    lineHeight: 1,
    marginRight: 20,

    "& td": {
      padding: '10px !important',
    },

    "& th": {
      padding: '5px !important',
    }
  },

  headerPrintBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '2px solid #000',
    padding:'0 0 10px',
    margin:'0 0 10px',
    fontSize: '15px',
    fontWeight: 600,
  },

  footerTable: {
    margin: '15px 0',
    padding: '0 0 10px',
    fontSize: 12,

  },

  footerPrintForm: {
    display: 'flex',

    "& strong": {
      margin: '0 0 15px',
      display: 'block',
      fontSize: 12,
    },

    "& span": {
      fontSize: 12,
    }
  },

  printFormHeader: {
    whiteSpace: 'nowrap',
    fontSize: 11,
    fontWeight: 600,
    borderRight: '1px solid #000',
    borderLeft: '1px solid #000',
    borderBottom: '1px solid #000',
  },

  itemCol: {
    borderRight: '1px solid #000',
    borderLeft: '1px solid #000',
    borderBottom: '1px solid #000',
    fontSize: 11,
    lineHeight: 1,
  },

  attention: {
    fontSize: 11,
    textAlign: 'center',
    padding:'0 0 15px'
  },


}));

/* ================================================================================== */

const PrintInvoiceForm2 = props => {
  const {
    printData,
    // htmlTemplate

  } = props;
  const classes = useStyles();
  const printDataTemplate1 = printData[0].data;
  const printDataTemplate2 = printData[1].data;
  // const html_template = htmlTemplate;

  // console.log(printData, 'printData');
  // console.log(printDataTemplate2, 'printDataTemplate2');
  // console.log(printDataTemplate1, 'printDataTemplate1');

  /* ========================================================================== */


  useEffect(() => {

    // eslint-disable-next-line
  }, []);

  /* =============================================================================== */

  // const {t} = useTranslation();
  return (
      <>
      <React.Fragment>
        <div className={classes.print_container}>

          {/*=============ФОРМА СЧЕТ=================*/}

          <div className={classes.formTemplate} style={{pageBreakBefore: 'always'}}>
            <Grid className={classes.grid} container>
              <Grid className={classes.content} item lg={12} xs={12}>
                <div className={classes.attention}>
                  Увага! Оплата цього рахунку означає погодження з умовами поставки товарів.
                  Повідомлення про оплату є обов'язковим, в іншому випадку не гарантується наявність товарів на складі.
                  Товар відпускається за фактом надходження коштів на п/р Постачальника, самовивозом,
                  за наявності довіреності та паспорта.
                </div>
                <div style={{border: '1px solid #000', padding: '10px', marginBottom: '10px'}}>
                  <div  className={classes.headerCompanyBlock}>
                    <span>Одержувач</span>
                    <strong>{printDataTemplate2.provider}</strong>
                  </div>
                  <div  className={classes.headerCompanyBlock}>
                    <span>Код</span>
                    <strong style={{border: '1px solid #000', padding: '5px 20px', marginTop: 10}}>{printDataTemplate2.provider_edrpou}</strong>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'space-between',alignItems:'flex-end'}}>
                    <div>
                      <div style={{fontSize: '11px'}}>Банк одержувача</div>
                      <strong style={{fontSize: '11px'}}>{printDataTemplate2.bank_name}</strong>
                    </div>
                    <div>
                      <div style={{fontSize: '11px'}}>Код банку</div>
                      <strong style={{fontSize: '11px', border: '1px solid #000', padding: '5px 20px', display:'block', marginTop: 10}}>{printDataTemplate2.bank_mfo}</strong>
                    </div>
                    <div>
                      <div style={{fontSize: '11px'}}>КРЕДИТ рах. №</div>
                      <div style={{fontSize: '11px', fontWeight:'600', border: '1px solid #000', padding: '5px 10px', marginTop: 10, height: 25}}>{printDataTemplate2.account_number}</div>
                      <div style={{fontSize: '11px', borderBottom: '1px solid #000',borderRight: '1px solid #000',borderLeft: '1px solid #000', padding: '5px 10px', height: 25}}></div>
                    </div>
                  </div>
                </div>
                <div className={classes.headerPrintBlock}>
                  <div>
                    Рахунок на оплату № {printDataTemplate2.order_number_1c} {printDataTemplate2.invoice_for_payment_number} вiд {printDataTemplate2.invoice_for_payment_date}
                  </div>
                </div>

                <div className={classes.headerCompanyBlock}>
                  <span>Постачальник:</span>
                  <div>
                    <strong>{printDataTemplate2.provider}</strong>
                    <div>{printDataTemplate2.provider_description}</div>
                  </div>

                </div>
                <div  className={classes.headerCompanyBlock}>
                  <span>Покупець:</span>
                  <div>
                    <strong>{printDataTemplate2.buyer}</strong>
                    <div>тел.: {printDataTemplate2.buyer_phone}</div>
                  </div>
                </div>
                <div className={classes.headerCompanyBlock}>
                  <span>Договір:</span>
                  <div style={{fontSize: 13}}>{printDataTemplate2.contract}</div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Table className={classes.table} size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.printFormHeader} style = {{ maxWidth : '50px'}}>№</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Код УКТЗЕД</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Артикул</TableCell>
                      <TableCell className={classes.printFormHeader} align="center"  style = {{ minWidth : '200px'}}>Товар</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '70px'}}>Кiл-сть</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Од.</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '60px'}}>Цiна <br/>з ПДВ</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '60px'}}>Сума <br/>з ПДВ</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {printDataTemplate2.items && printDataTemplate2.items.map((el, index) => (
                        <TableRow key={'row'+ index}>
                          <TableCell className={classes.itemCol} align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                            {el.uktzed_code}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                            {el.article}
                          </TableCell>
                          <TableCell className={classes.itemCol}>
                            {el.name}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                            {el.quantity}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                              {el.units_of_measurement}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="right">
                              {parseFloat(el.price).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="right">
                            {parseFloat(el.sum).toFixed(2)}
                          </TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={7} className={classes.itemCol} align="right">
                        Всього:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                        {parseFloat(printDataTemplate2.sum).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={7} className={classes.itemCol} align="right">
                        У тому числi ПДВ:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                        {printDataTemplate2.sum_pdv}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

            </Grid>
            <div  className={classes.footerTable} style={{borderBottom: '1px solid #000', padding: '0 0 10px'}}>
              <div>Всього найменувань {printDataTemplate2.total_items}, на суму {parseFloat(printDataTemplate2.sum).toFixed(2)} {printDataTemplate2.currency}  </div>
              <strong style={{display: 'block'}}>{printDataTemplate2.sum_in_cuirsive}</strong>
              <strong style={{display: 'block'}}>{printDataTemplate2.sum_pdv_in_cuirsive}</strong>
            </div>

            <div className={classes.footerPrintForm} style={{display: 'flex', justifyContent: 'flex-end'}}>
              <div style={{width: '50%', position: 'relative',borderBottom: '1px solid #000'}}>
                <strong>Виписав(ла):</strong>
                {/*{html_template === 4 ?(*/}
                {/*    <img className={classes.stamp2} src={DaniBeznal} alt="stamp"/>*/}
                {/*): html_template === 2 ? (*/}
                {/*    <img className={classes.stamp} src={PerfectBeznal} alt="stamp"/>*/}
                {/*):''*/}
                {/*}*/}
              </div>
            </div>
          </div>

          {/*========форма накладная=================*/}

          <div className={classes.formTemplate} style={{pageBreakBefore: 'always'}}>
            <Grid className={classes.grid} container>
              <Grid className={classes.content} item lg={12} xs={12}>
                <div className={classes.headerPrintBlock}>
                  <div>
                    Видаткова накладна № {printDataTemplate2.invoice_number} вiд {printDataTemplate2.invoice_date}
                  </div>
                </div>

                <div className={classes.headerCompanyBlock}>
                  <span>Постачальник:</span>
                  <div>
                    <strong>{printDataTemplate2.provider}</strong>
                    <div>{printDataTemplate2.provider_description}</div>
                  </div>

                </div>
                <div  className={classes.headerCompanyBlock}>
                  <span>Покупець:</span>
                  <div>
                  <strong>{printDataTemplate2.buyer}</strong>
                  <div>Юридична адреса :{printDataTemplate2.buyer_address}, тел.: {printDataTemplate2.buyer_phone}</div>
                  </div>
                </div>
                <div className={classes.headerCompanyBlock}>
                  <span>Договір:</span>
                  <div style={{fontSize: 13}}>{printDataTemplate2.contract}</div>
                </div>
                <div className={classes.headerCompanyBlock}>
                  <span>Склад:</span>
                  <div style={{fontSize: 13}}>{printDataTemplate2.storage}</div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Table className={classes.table} size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.printFormHeader} style = {{ maxWidth : '50px'}}>№</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Артикул</TableCell>
                      <TableCell className={classes.printFormHeader} align="center"  style = {{ minWidth : '200px'}}>Товар</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '70px'}}>Кiл-сть</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Од.</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '60px'}}>Цiна <br/>з ПДВ</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '60px'}}>Сума <br/>з ПДВ</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {printDataTemplate2.items && printDataTemplate2.items.map((el, index) => (
                        <TableRow key={'row'+ index}>
                          <TableCell className={classes.itemCol} align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                            {el.article}
                          </TableCell>
                          <TableCell className={classes.itemCol}>
                            {el.name}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                            {el.quantity}
                          </TableCell>
                            <TableCell className={classes.itemCol} align="center">
                                {el.units_of_measurement}
                            </TableCell>
                          <TableCell className={classes.itemCol} align="right">
                              {parseFloat(el.price).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="right">
                            {parseFloat(el.sum).toFixed(2)}
                          </TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={6} className={classes.itemCol} align="right">
                        Всього:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                        {parseFloat(printDataTemplate2.sum).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={6} className={classes.itemCol} align="right">
                        У тому числi ПДВ:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                        {printDataTemplate2.sum_pdv}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <div className={classes.footerTable}>
              <div>Всього найменувань {printDataTemplate2.total_items}, на суму {parseFloat(printDataTemplate2.sum).toFixed(2)} {printDataTemplate2.currency} </div>
              <strong style={{display: 'block'}}>{printDataTemplate2.sum_in_cuirsive}</strong>
              <strong style={{display: 'block'}}>{printDataTemplate2.sum_pdv_in_cuirsive}</strong>

            </div>
            <div className={classes.headerCompanyBlock} style={{ borderBottom: '1px solid #000', padding: '0 0 10px'}}>
              <span>Мiсце складання:</span>
              <div>{printDataTemplate2.place_of_compilation}</div>
            </div>

            <div className={classes.footerPrintForm}>
              <div style={{width: '50%', margin: '0 20px 0 0'}}>
                <strong style={{padding: '0 0 15px', margin: '0 0 5px', borderBottom: '1px solid #000'}}>Вiд постачальника*</strong>

                <span>{printDataTemplate2.manager}</span>
              </div>
              <div style={{width: '50%', position: 'relative'}}>
                <strong style={{padding: '0 0 15px', margin: '0 0 5px',borderBottom: '1px solid #000'}}>Отримав(ла)</strong>
                {/*{html_template === 4 ?(*/}
                {/*    <img className={classes.stamp2} src={DaniBeznal} alt="stamp"/>*/}
                {/*): html_template === 2 ? (*/}
                {/*    <img className={classes.stamp} src={PerfectBeznal} alt="stamp"/>*/}
                {/*    ):''*/}
                {/*}*/}
                <span>печатка</span>
              </div>
            </div>
            <div style={{display: 'flex', fontSize: 12}}>
              <p style={{width: '50%', margin: '0 10px 0 0'}}>* Відповідальний за здійснення господарської операції<br/>
                і правильність її оформлення</p>
              {printDataTemplate2.Received_from_another_document ?(
                  <p>{printDataTemplate2.document_confirming_credentials}</p>
              ):(<p>За довіреністю {printDataTemplate2.attorney_series} № {printDataTemplate2.attorney_number} вiд {printDataTemplate2.attorney_date}</p>)}
            </div>
          </div>
          <div className={classes.formTemplate} style={{pageBreakBefore: 'always'}}>
            <Grid className={classes.grid} container>
              <Grid className={classes.content} item lg={12} xs={12}>
                <div className={classes.headerPrintBlock}>
                  <div>
                    Видаткова накладна № {printDataTemplate2.invoice_number} вiд {printDataTemplate2.invoice_date}
                  </div>
                </div>

                <div className={classes.headerCompanyBlock}>
                  <span>Постачальник:</span>
                  <div>
                    <strong>{printDataTemplate2.provider}</strong>
                    <div>{printDataTemplate2.provider_description}</div>
                  </div>

                </div>
                <div  className={classes.headerCompanyBlock}>
                  <span>Покупець:</span>
                  <div>
                    <strong>{printDataTemplate2.buyer}</strong>
                    <div>Юридична адреса :{printDataTemplate2.buyer_address}, тел.: {printDataTemplate2.buyer_phone}</div>
                  </div>
                </div>
                <div className={classes.headerCompanyBlock}>
                  <span>Договір:</span>
                  <div style={{fontSize: 13}}>{printDataTemplate2.contract}</div>
                </div>
                <div className={classes.headerCompanyBlock}>
                  <span>Склад:</span>
                  <div>{printDataTemplate2.storage}</div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Table className={classes.table} size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.printFormHeader} style = {{ maxWidth : '50px'}}>№</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Артикул</TableCell>
                      <TableCell className={classes.printFormHeader} align="center"  style = {{ minWidth : '200px'}}>Товар</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '70px'}}>Кiл-сть</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Од.</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '60px'}}>Цiна <br/>з ПДВ</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '60px'}}>Сума <br/>з ПДВ</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {printDataTemplate2.items && printDataTemplate2.items.map((el, index) => (
                        <TableRow key={'row'+ index}>
                          <TableCell className={classes.itemCol} align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                            {el.article}
                          </TableCell>
                          <TableCell className={classes.itemCol}>
                            {el.name}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                            {el.quantity}
                          </TableCell>
                            <TableCell className={classes.itemCol} align="center">
                                {el.units_of_measurement}
                            </TableCell>
                          <TableCell className={classes.itemCol} align="right">
                              {parseFloat(el.price).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="right">
                            {parseFloat(el.sum).toFixed(2)}
                          </TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={6} className={classes.itemCol} align="right">
                        Всього:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                        {parseFloat(printDataTemplate2.sum).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={6} className={classes.itemCol} align="right">
                        У тому числi ПДВ:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                        {printDataTemplate2.sum_pdv}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <div className={classes.footerTable}>
              <div>Всього найменувань {printDataTemplate2.total_items}, на суму {parseFloat(printDataTemplate2.sum).toFixed(2)} {printDataTemplate2.currency}  </div>
              <strong style={{display: 'block'}}>{printDataTemplate2.sum_in_cuirsive}</strong>
              <strong style={{display: 'block'}}>{printDataTemplate2.sum_pdv_in_cuirsive}</strong>

            </div>
            <div className={classes.headerCompanyBlock} style={{borderBottom: '1px solid #000', padding: '0 0 10px'}}>
              <span>Мiсце складання:</span>
              <div>{printDataTemplate2.place_of_compilation}</div>
            </div>

            <div className={classes.footerPrintForm}>
              <div style={{width: '50%', margin: '0 20px 0 0'}}>
                <strong style={{padding: '0 0 15px', margin: '0 0 5px', borderBottom: '1px solid #000'}}>Вiд постачальника*</strong>

                <span>{printDataTemplate2.manager}</span>
              </div>
              <div style={{width: '50%', position: 'relative'}}>
                <strong style={{padding: '0 0 15px', margin: '0 0 5px',borderBottom: '1px solid #000'}}>Отримав(ла)</strong>
                {/*{html_template === 4 ?(*/}
                {/*    <img className={classes.stamp2} src={DaniBeznal} alt="stamp"/>*/}
                {/*): html_template === 2 ? (*/}
                {/*    <img className={classes.stamp} src={PerfectBeznal} alt="stamp"/>*/}
                {/*):''*/}
                {/*}*/}
                <span>печатка</span>
              </div>
            </div>
            <div style={{display: 'flex', fontSize: 12}}>
              <p style={{width: '50%', margin: '0 10px 0 0'}}>* Відповідальний за здійснення господарської операції<br/>
                і правильність її оформлення</p>
              {printDataTemplate2.Received_from_another_document ?(
                  <p>{printDataTemplate2.document_confirming_credentials}</p>
              ):(<p>За довіреністю {printDataTemplate2.attorney_series} № {printDataTemplate2.attorney_number} вiд {printDataTemplate2.attorney_date}</p>)}
            </div>
          </div>



          <div className={classes.formTemplate} style={{pageBreakBefore: 'always'}}>
            <div className={classes.watermark}>не є офiцiйним документом</div>
            <Grid className={classes.grid} container>
              <Grid className={classes.content} item lg={12} xs={12}>
                <div className={classes.headerPrintBlock}>
                  <div>
                    Видаткова накладна {printDataTemplate1.invoice_number} вiд {printDataTemplate1.invoice_date}
                  </div>
                  <div>
                    Заказ {printDataTemplate1.order_number_1с}
                  </div>
                </div>

                {/*<div className={classes.headerWarningText}>*/}
                {/*  не є офiцiйним документом*/}
                {/*</div>*/}
                <div className={classes.headerCompanyBlock}>
                  <span>Постачальник:</span>
                  <strong>{printDataTemplate1.provider}</strong>
                </div>
                <div  className={classes.headerCompanyBlock}>
                  <span>Покупець:</span>
                  <strong>{printDataTemplate1.buyer}</strong>
                </div>
                <div className={classes.headerCompanyBlock}>
                  <span>Договiр:</span>
                  <div style={{fontSize: 13}}>{printDataTemplate1.contract}</div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Table className={classes.table} size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>№</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Артикул</TableCell>
                      <TableCell className={classes.printFormHeader} align="center"  style = {{ minWidth : '200px'}}>Товар</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '70px'}}>Кiл-сть</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Од.</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '60px'}}>Цiна <br/>з ПДВ</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '60px'}}>Сума <br/>з ПДВ</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {printDataTemplate1.items && printDataTemplate1.items.map((el, index) => (
                        <TableRow key={'row'+ index}>
                          <TableCell className={classes.itemCol} align="center">
                              {index + 1}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                              {el.article}
                          </TableCell>
                          <TableCell className={classes.itemCol}>
                              {el.name}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="center">
                            {el.quantity}
                          </TableCell>
                            <TableCell className={classes.itemCol} align="center">
                                {el.units_of_measurement}
                            </TableCell>
                          <TableCell className={classes.itemCol} align="right">
                              {parseFloat(el.price).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.itemCol} align="right">
                            {parseFloat(el.sum).toFixed(2)}
                          </TableCell>
                        </TableRow>
                    ))}

                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={6} className={classes.itemCol} align="right">
                        Всього:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                        {parseFloat(printDataTemplate1.sum).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <div className={classes.footerTable}>
              <div style={{textAlign: 'left'}}>Всього найменувань {printDataTemplate1.total_items}, на суму {parseFloat(printDataTemplate1.sum).toFixed(2)} {printDataTemplate1.currency} </div>
              <strong style={{textAlign: 'left'}}>{printDataTemplate1.sum_in_cuirsive}</strong>
            </div>
            <div className={classes.footerPrintForm}>
              <div style={{width: '50%', margin: '0 20px 0 0',position: 'relative'}}>
                <strong style={{padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000'}}>Вiд постачальника*</strong>

                <span>Менеджер</span>
              </div>
              <div style={{width: '50%', position: 'relative'}}>
                <strong style={{padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000'}}>Отримав(ла)</strong>

                <span>За довiренiстю</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>

       {/*===============================================================================*/}
    </>
  );
};

class WrapperPrint extends React.Component {

  render() {
    return <PrintInvoiceForm2 {...this.props} />;
  }
}

export default WrapperPrint;

