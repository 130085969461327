import {Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';
import {ProductSkeleton} from '../../_components/Product/ProductSkeleton';

export const PromotionPageSkeleton = () => {
  const typePage = localStorage.getItem('TypePage') || 'cardVr';
  return (
    <div>
      <div className="wrapper-inner my-3">
        <Skeleton width={'22%'} height={17} variant="rect" />
      </div>
      <div className="wrapper-inner">
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="title mb-4">
              <Typography variant="h2">
                <Skeleton width={240} />
              </Typography>
            </div>
            <div className="d-none d-lg-block">
              <Skeleton variant="rect" width={'100%'} height={230} />
            </div>
            <div className="d-block d-lg-none">
              <Skeleton variant="rect" width={'100%'} height={330} />
            </div>
          </div>
          <div className="col-lg-8 col-12 mt-11 promotion-page-left">
            <div className="formated-text-wrapper">
              <Skeleton width="60%" />
              <Skeleton width="90%" />
              <Skeleton width="81%" />
              <Skeleton width="82%" />
              <Skeleton width="86%" />
              <Skeleton width="93%" />
              <Skeleton width="90%" />
              <Skeleton width="99%" />
              <Skeleton width="95%" />
              <Skeleton width="50%" />
            </div>
            <div className="">
              <div className="timer-title" style={{margin: '30px 0 17px'}}>
                <Skeleton width="213px" />
              </div>
              <div className="timers d-flex gap-10 ">
                <div className="timer">
                  <Skeleton variant="circle" width={70} height={70} />
                </div>
                <div className="timer">
                  <Skeleton variant="circle" width={70} height={70} />
                </div>
                <div className="timer">
                  <Skeleton variant="circle" width={70} height={70} />
                </div>
                <div className="timer">
                  <Skeleton variant="circle" width={70} height={70} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper-inner">
        <div className="row mb-4">
          <div className="col-12">
            <div className="listing-info">
              <div className="listing-info__text">
                <Skeleton variant="rect" width={250} />
              </div>
              <div className="display-btns d-none d-xl-flex gap-10">
                <Skeleton variant="rect" width={20} height={20} />
                <Skeleton variant="rect" width={20} height={20} />
                <Skeleton variant="rect" width={20} height={20} />
              </div>
            </div>

            <div className="product__list product-listing">
              {typePage === 'cardHr' ? (
                <>
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                  <ProductSkeleton typePage={typePage} />
                </>
              ) : typePage === 'cardVr' ? (
                <>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                  <div className="product__item">
                    <ProductSkeleton />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
