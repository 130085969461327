import React, {useState} from 'react';
/*import clsx from 'clsx';*/
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import PriceToStr from '../../../../_components/PriceToStr';
//import CountDay from "../../../../_components/CountDay";
//import clsx from "clsx";
//import DeleteBasket from "@material-ui/icons/DeleteForever";
import IconButton from '@material-ui/core/IconButton';
//import NumericInput from "react-numeric-input";
import Button from '@material-ui/core/Button';
import MyNumericInput from '../../../../_components/MyNumericInput';
import {useTranslation} from 'react-i18next';
import product from '../../../../images/product.png';
import BasketDelete from '../../../../icons/BasketDelete';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import PromoLabel from '../../../../_components/PromoLabel';
import {getDescriptForPromo} from '../../../../_helpers';
import Info from '@material-ui/icons/Info';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cansel from '../../../../icons/Cancel';
import {Divider} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import ImageWithFallback from '../../../../_components/ImageWithFallback';
import Dialog from '@material-ui/core/Dialog';
import {Link} from 'react-router-dom';
import BlockTypeToggleButton from '../../../ProductList/components/BlockTypeToggleButton';
import NoProducts from '../../../../_components/NoProducts/NoProducts';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
    /*overflowX: 'auto',*/
  },
  /*table: {
        minWidth: 700,
    },*/

  paper: {
    /*padding: theme.spacing(2),*/
    padding: '16px 4px',
    textAlign: 'center',
    borderRadius: 0,
    backgroundColor: '#eee',
    fontsize: '14px!important',
  },

  gridItem: {
    // padding: '5px 5px',
    // borderStyle: 'solid!important',
    // borderColor: '#eee',
    // borderWidth: 1,
    // wordWrap: 'break-word',
    // display: 'flex',
    /*justifyContent: 'center',*/
    // alignItems: 'center',
  },
  gridItemBasket: {
    padding: '0 0px',
    borderStyle: 'solid!important',
    borderColor: '#eee',
    borderWidth: 1,
    wordWrap: 'break-word',
    display: 'flex',
    /*justifyContent: 'center',*/
    alignItems: 'center',
  },

  gridItemHead: {
    textAlign: 'center',
    // borderStyle: 'solid!important',
    // borderColor: '#eee',
    // borderWidth: 1,
    padding: '16px 4px',
    fontWeight: 500,
    fontsize: '14px',
    backgroundColor: '#EBF0F8',
  },

  row: {
    // '&:hover': {backgroundColor: '#fafafa'}
  },

  mainImg: {maxWidth: '100%'},

  otherOffersHr: {
    transitionProperty: 'opacity',
    transitionDuration: '300ms',
    opacity: 0,
    zIndex: 2,
    width: 350,
    marginLeft: 'calc(100% - 371px - 5px)',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'calc(66% - 280px - 10px)',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 585,
    },
    marginTop: 60,
    position: 'absolute',
    display: 'none',
    //maxHeight: 350,
  },
  stBalance: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stDelivery: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  signOutButton: {
    //color: 'white',
    //backgroundColor: 'darkorange',//forestgreen
    padding: '4px 10px',
    width: 'auto',
    marginTop: 8,
    marginLeft: 16,
    minWidth: 40,
  },
  gridContainerSubBlPrice: {
    borderStyle: 'solid!important',
    borderColor: '#eee',
    borderWidth: 1,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end', //'space-between',
  },

  stBrand: {
    // fontWeight: 'bold',
    // padding: 0,
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis', /* Многоточие */
  },
  stArticle: {
    // fontWeight: 500,
    // padding: 0,
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis', /* Многоточие */
  },
  stName: {
    // fontSize: 16
  },
  stInfo: {
    fontSize: 14,
    color: '#546e7a',
  },
  stPrice: {
    //padding: '8px 10px 8px 2px',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.1,
  },
  primaryTotal: {
    fontSize: 24,
    lineHeight: 1.3,
  },
  secondaryTotal: {
    fontSize: 18,
    lineHeight: 1.3,
  },
  secondaryTotalFinish: {
    fontSize: 18,
    textAlign: 'right',
    lineHeight: 1.3,
  },
  primaryTotalBlock: {
    fontSize: 18,
    lineHeight: 1.2,
  },
  secondaryTotalBlock: {
    fontSize: 16,
    textAlign: 'right',
    lineHeight: 1.2,
  },
  stShowProductCart: {
    // display: "contents",
    // cursor: "pointer",
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      // color: '#3f51b5',
    },
  },

  promoRow: {
    background: '#eee',
    '&:hover': {
      background: '#eee',
    },
  },

  next_discount_block: {
    fontSize: '12px',
  },

  stMainHeaderHelp: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#315EFB',
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

// lg={3}
// sm={6}
// xl={3}
// xs={12}

let timerId;

const CartTable = props => {
  const {
    currencyMainContr,
    list,
    gift_list,
    currencyMain,
    id,
    quantityGoods,
    nameBasket,
    setPhotosGallery,
    clearBasket,
    deleteItemsFromBasket,
    changeItemQuantityCart,
    setCartOderData,
    loading,
    promotionsByProductsList,
    showPage,
    typePage,
    //showProductCart,
  } = props;
  const {
    //all: allCart,
    main_warehouse: own,
    on_request: offer,
    not_found_in_price: notFound,
  } = {...list};

  // let abbr = currencyMain ? currencyMain.abbr : 'UAH';
  // let abbrContr = currencyMainContr ? currencyMainContr.abbr : 'UAH';
  let all = [];

  let ownAndGift_list = own;
  if (gift_list !== undefined && gift_list.length > 0) {
    gift_list.map(it => {
      it.customer_cart_id = id;
      it.brand_clean = it.brand
        .replace(/[^a-zЄєЇїA-ZА-Яа-я0-9]/gi, '')
        .toUpperCase();
      it.article_clean = it.article
        .replace(/[^a-zЄєЇїA-ZА-Яа-я0-9]/gi, '')
        .toUpperCase();
      it.regular_price_current = currencyMainContr.abbr
        ? it.price_in_currencies[currencyMainContr.abbr].regular_price
        : 1;
      it.price_current = currencyMainContr.abbr
        ? it.price_in_currencies[currencyMainContr.abbr].price_discount
        : 1;
      return true;
    });
    ownAndGift_list = own.concat(gift_list);
  }

  all[0] = ownAndGift_list;
  all[1] = offer;
  all[2] = notFound;

  const {t} = useTranslation();
  let nameGroup = [
    t('common:warehouse'),
    t('common:under_the_order'),
    t('common:No shipment'),
  ];
  // let nameGroup = ["Склад", "Под заказ", "Нет отгрузки"];
  all[0].sort((a, b) => a.warehouse.id - b.warehouse.id);
  all[1].sort((a, b) => a.warehouse.id - b.warehouse.id);
  all[2].sort((a, b) => a.warehouse.id - b.warehouse.id);

  let totalCost = [];
  let totalCostWarehouse = [];
  let totalCountWarehouse = [];
  totalCost[id] = {price_current: 0, regular_price: 0};
  ownAndGift_list.map((row, index2) => {
    // console.log('===>', currencyMainContr, abbrContr, row.price_in_currencies);
    row.regularPrice = row.regular_price_current;

    if (row.customer_cart_id === undefined || row.customer_cart_id === null)
      row.customer_cart_id = 0;
    if (!totalCost[row.customer_cart_id])
      totalCost[row.customer_cart_id] = {price_current: 0, regular_price: 0};

    if (!totalCostWarehouse[row.customer_cart_id])
      totalCostWarehouse[row.customer_cart_id] = [];
    if (!totalCostWarehouse[row.customer_cart_id][row.warehouse.id])
      totalCostWarehouse[row.customer_cart_id][row.warehouse.id] = {
        price_current: 0,
        regular_price: 0,
      };

    if (!totalCountWarehouse[row.customer_cart_id])
      totalCountWarehouse[row.customer_cart_id] = [];
    if (!totalCountWarehouse[row.customer_cart_id][row.warehouse.id])
      totalCountWarehouse[row.customer_cart_id][row.warehouse.id] = 0;

    totalCost[row.customer_cart_id].price_current +=
      row.quantity * row.price_current;
    totalCostWarehouse[row.customer_cart_id][row.warehouse.id].price_current +=
      row.quantity * row.price_current;
    if (row.price_current !== row.regularPrice) {
      row.isDiscount = Math.round(
        ((row.regularPrice - row.price_current) / row.regularPrice) * 100,
      );
      totalCost[row.customer_cart_id].regular_price +=
        row.quantity * (row.regularPrice - row.price_current);
      totalCostWarehouse[row.customer_cart_id][
        row.warehouse.id
      ].regular_price += row.quantity * (row.regularPrice - row.price_current);
    } else row.isDiscount = 0;

    totalCountWarehouse[row.customer_cart_id][row.warehouse.id] += 1;
    return true;
  });
  let totalCostOffer = [];
  let totalCountOffer = [];
  offer.map((row, index2) => {
    // row.regularPrice = row.price_in_currencies[abbrContr].regular_price;
    row.regularPrice = row.regular_price_current;

    if (row.customer_cart_id === null) row.customer_cart_id = 0;

    if (!totalCost[row.customer_cart_id])
      totalCost[row.customer_cart_id] = {price_current: 0, regular_price: 0};
    if (!totalCostOffer[row.customer_cart_id])
      totalCostOffer[row.customer_cart_id] = {
        price_current: 0,
        regular_price: 0,
      };
    if (!totalCountOffer[row.customer_cart_id])
      totalCountOffer[row.customer_cart_id] = 0;

    totalCost[row.customer_cart_id].price_current +=
      row.quantity * row.price_current;
    totalCostOffer[row.customer_cart_id].price_current +=
      row.quantity * row.price_current;

    row.isDiscount = 0;
    totalCountOffer[row.customer_cart_id] += 1;
    return true;
  });
  let totalCostNotFound = [];
  let totalCountNotFound = [];
  notFound.map((row, index2) => {
    row.regularPrice = row.regular_price_current;
    if (row.customer_cart_id === null) row.customer_cart_id = 0;

    if (!totalCost[row.customer_cart_id])
      totalCost[row.customer_cart_id] = {price_current: 0, regular_price: 0};
    if (!totalCostNotFound[row.customer_cart_id])
      totalCostNotFound[row.customer_cart_id] = {
        price_current: 0,
        regular_price: 0,
      };
    if (!totalCountNotFound[row.customer_cart_id])
      totalCountNotFound[row.customer_cart_id] = 0;

    totalCost[row.customer_cart_id].price_current +=
      row.quantity * row.price_current;
    totalCostNotFound[row.customer_cart_id].price_current +=
      row.quantity * row.price_current;

    row.isDiscount = 0;
    totalCountNotFound[row.customer_cart_id] += 1;
    return true;
  });

  const classes = useStyles();

  const [flag, setFlag] = React.useState(0);
  const [promoModal, setPromoModal] = useState(false);
  const [modalInfoId, setModalInfoId] = useState();
  const [modalInfoUuid, setModalInfoUuid] = useState();
  // const [newQuantity, setNewQuantity] = React.useState({});
  // function changeDiscountQuantity (quantity, id) {
  //     console.log(quantity, '--------->>>>>changeDiscountQuantity quantity');
  //     console.log(id, '--------->>>>>changeDiscountQuantity id');
  //     setNewQuantity(
  //         { quantity: quantity,
  //         id: id})
  //
  // };

  const createMarkup = html => ({__html: html});

  const onChangeQuantity = (
    quantity,
    goods_id,
    price,
    priceCurrent,
    maxQuantity,
    minQuantity,
  ) => {
    // changeDiscountQuantity(quantity, goods_id)
    // console.log(quantity, '!!!!!1quantity!!!!!');
    // console.log(goods_id, '!!!!!goods_id!!!!!');
    // setNewQuantity(quantity)
    if (quantity === null || quantity === '') {
      quantity = minQuantity;
      setFlag(flag + 1);
    } else {
      setFlag(flag + 1);
    }
    quantity = parseInt(quantity);
    price = parseFloat(price);
    maxQuantity = parseInt(maxQuantity);

    if (quantity > maxQuantity) quantity = maxQuantity;
    if (quantity < minQuantity) quantity = minQuantity;

    clearTimeout(timerId);
    timerId = setTimeout(function() {
      changeItemQuantityCart(id, goods_id, quantity);
      //console.log('tmp=',tmp);
    }, 200);
  };

  // console.log(newQuantity, '!!!!!!newQuantity')

  let currentData = {
    indAll: -1,
    warehouse_id: -1,
    warehouse_name: '',
  };

  const registerForm = (indAll, warehouse_id, name, count, cost, st) => (
    <div
      style={{display: 'inline-block', padding: '0 10px 10px', width: '100%'}}>
      {st === 'st' ? (
        <h4 style={{float: 'left', margin: '5px 0'}}>
          <span style={{color: indAll === 2 ? 'red' : '#777'}}>
            {nameGroup[indAll]}:
          </span>{' '}
          {name}
        </h4>
      ) : (
        <div
          style={{
            display: 'block',
            width: '100%',
            marginTop: 8,
            textAlign: 'right',
          }}>
          {cost.regular_price > 0 && (
            <PriceToStr
              data={cost.regular_price} // это экономия
              stAll={{color: 'red'}}
              // stBefore={{fontStyle: "normal", color: '#333'}}
              // stSumma={{fontStyle: "normal", fontWeight: 'bold'}}
              beforeText={t('common:save_money') + ' '}
              text={currencyMainContr.simbol}
              grn={t('common:uah')}
            />
          )}
          <ListItemText
            classes={{
              primary: classes.primaryTotalBlock,
              secondary: classes.secondaryTotalBlock,
            }}
            primary={
              <PriceToStr
                data={cost.price_current}
                stBefore={{fontStyle: 'normal', color: '#333'}}
                stSumma={{fontStyle: 'normal', fontWeight: 'bold'}}
                beforeText={
                  t('common:goods') +
                  ' ' +
                  count +
                  ' ' +
                  t('common:to_the_tune_of') +
                  ' '
                }
                text={
                  currencyMainContr.simbol === '-'
                    ? ''
                    : currencyMainContr.simbol
                }
                grn={t('common:uah')}
              />
            }
            secondary={
              currencyMain &&
              currencyMain.simbol &&
              currencyMain.simbol !== currencyMainContr.simbol && (
                <PriceToStr
                  // data={cost * currencyMainContr.koef}
                  data={
                    currencyMain.simbol === '₴'
                      ? Math.round(cost.price_current * currencyMainContr.koef)
                      : cost.price_current * currencyMainContr.koef
                  }
                  beforeText={''}
                  text={
                    currencyMain && currencyMain.simbol
                      ? currencyMain.simbol
                      : '??'
                  }
                  grn={t('common:uah')}
                />
              )
            }
          />
          {indAll !== 2 && (
            <button
              className={clsx(
                'btn',
                loading === true || totalCost[id].price_current === 0
                  ? true
                  : false
                  ? classes.disabled
                  : '',
              )}
              style={{margin: '0 0 auto auto'}}
              onClick={() =>
                setCartOderData({
                  currencyMainContrSimbol: currencyMainContr.simbol,
                  id: id,
                  type: indAll,
                  //id_warehouse: indAll === 0 ? row.warehouse.id : '',
                  id_warehouse: indAll === 0 ? warehouse_id : '',
                  //name_warehouse: indAll === 0 ? row.warehouse.name_repr : 'Заказной товар',
                  name_warehouse: indAll === 0 ? name : 'Заказной товар',
                  nameBasket: nameBasket,
                  quantityGoods: count,
                  totalCost: cost.price_current,
                  list: all[indAll],
                })
              }>
              {t('common:To issue')}
            </button>
            // <Button
            //   className={classes.signOutButton}
            //   disabled={
            //     loading === true || totalCost[id].price_current === 0
            //       ? true
            //       : false
            //   }
            //   //color="inherit"
            //   color="primary"
            //   variant="contained"
            //   component={Button}
            //   onClick={() =>
            //     setCartOderData({
            //       currencyMainContrSimbol: currencyMainContr.simbol,
            //       id: id,
            //       type: indAll,
            //       //id_warehouse: indAll === 0 ? row.warehouse.id : '',
            //       id_warehouse: indAll === 0 ? warehouse_id : '',
            //       //name_warehouse: indAll === 0 ? row.warehouse.name_repr : 'Заказной товар',
            //       name_warehouse: indAll === 0 ? name : 'Заказной товар',
            //       nameBasket: nameBasket,
            //       quantityGoods: count,
            //       totalCost: cost.price_current,
            //       list: all[indAll],
            //     })
            //   }>
            //   {t('common:To issue')}
            // </Button>
          )}
        </div>
      )}
    </div>
  );

  const ifRegisterForm = (
    currentIndAll,
    indAll,
    row,
    currentData,
    st,
    always,
  ) => (
    <div style={{display: 'contents'}}>
      {(row.warehouse &&
        row.warehouse.id !== currentData.warehouse_id &&
        currentIndAll === 0) ||
      (currentIndAll !== indAll && currentIndAll === 0) ||
      (always === 1 && currentIndAll === 0)
        ? registerForm(
            currentIndAll,
            st === 'st' ? row.warehouse.id : currentData.warehouse_id,
            st === 'st' ? row.warehouse.name_repr : currentData.warehouse_name,
            st === 'st'
              ? totalCountWarehouse[id][row.warehouse.id]
              : totalCountWarehouse[id][currentData.warehouse_id],
            st === 'st'
              ? totalCostWarehouse[id][row.warehouse.id]
              : totalCostWarehouse[id][currentData.warehouse_id],
            st,
          )
        : ''}

      {indAll !== currentData.indAll || always === 1
        ? currentIndAll === 1
          ? registerForm(
              currentIndAll,
              '',
              '',
              totalCountOffer[id],
              totalCostOffer[id],
              st,
            )
          : currentIndAll === 2
          ? registerForm(
              currentIndAll,
              '',
              '',
              totalCountNotFound[id],
              totalCostNotFound[id],
              st,
            )
          : ''
        : ''}
    </div>
  );

  const openPromoModal = (e, productId, uuid) => {
    console.log(productId, '------productId');
    e.preventDefault();
    e.stopPropagation();
    setPromoModal(true);
    setModalInfoId(productId);
    setModalInfoUuid(uuid);
    // setPromoModalData(dataPromotionList);
  };

  const promoClose = e => {
    e.preventDefault();
    e.stopPropagation();
    setPromoModal(false);
    window.scrollTo({
      top: 10,
      behavior: 'smooth',
    });
  };

  return (
    <div className="products-block pt-1">
      <div className="wrapper-inner">
        <div className="cart_table_container">
          {/*<h3>{nameBasket} : {t('common:of_goods')} {quantityGoods} {t('common:to_the_tune_of')}</h3>*/}
          <Grid
            container
            className="cart_header mb-4"
            alignItems={'center'}
            justifyContent={'space-between'}>
            {/*<BasketDelete onClick={clearBasket(id)} style={{float: "right", cursor: "pointer", marginRight: 10}}/>*/}
            <Grid style={{display: 'flex', alignItems: 'flex-end', gap: 10}}>
              {/* <ListItemText
                style={{marginBottom: 5}}
                classes={{
                  primary: classes.primaryTotal,
                  secondary: classes.secondaryTotal,
                }}
                primary={nameBasket}
                secondary={t('common:of_goods') + ' ' + quantityGoods}
              /> */}
              <h4 className="title">{nameBasket}</h4>
              <div>{t('common:of_goods') + ' ' + quantityGoods}</div>
            </Grid>

            <Grid className="button_container d-flex gap-10 items-center">
              {/* <BlockTypeToggleButton showPage={showPage} typePage={typePage} /> */}
              <button
                className="clear_cart_button btn btn-outline"
                onClick={clearBasket(id)}>
                {t('common:clear_cart')}
              </button>
            </Grid>
          </Grid>
          <div className={clsx(classes.root, 'cart_product_table')}>
            <Grid component="div" container spacing={0}>
              <Grid
                component="div"
                container
                item
                xs={12}
                spacing={0}
                style={{fontSize: 14}}>
                <Grid
                  component="div"
                  item
                  xs={1}
                  align="center"
                  className={classes.gridItemHead}
                />
                {/*<Grid item xs={8} className={classes.gridItemHead}>
                                        <div>{t('common:article/brand/name')}</div>
                                    </Grid>*/}
                <Grid
                  component="div"
                  item
                  xs={2}
                  className={classes.gridItemHead}>
                  <div>{t('common:brand')}</div>
                </Grid>
                <Grid
                  component="div"
                  item
                  xs={2}
                  className={classes.gridItemHead}>
                  <div>{t('common:article')}</div>
                </Grid>
                <Grid
                  component="div"
                  item
                  xs={4}
                  className={classes.gridItemHead}>
                  <div>{t('common:name')}</div>
                </Grid>
                <Grid component="div" container item xs={3} spacing={0}>
                  {/*<Grid item xs={4} align="center" className={classes.gridItemHead}>
                                            <div>{t('common:delivery')}</div>
                                        </Grid>*/}
                  <Grid
                    component="div"
                    item
                    xs={6}
                    align="center"
                    className={classes.gridItemHead}>
                    <div>{t('common:quantity')}</div>
                  </Grid>
                  <Grid
                    component="div"
                    item
                    xs={6}
                    align="center"
                    className={classes.gridItemHead}>
                    <div>{t('common:total')}</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div id={`${'cart'}${id}`}>
              {all[0].length > 0 ? (
                all.map((rowAll, indAll) =>
                  rowAll.map(
                    (row, index) =>
                      ((row.customer_cart_id == null && id === 0) ||
                        row.customer_cart_id === id) && (
                        <div
                          key={`${row.id}${index}`}
                          // key={`${row.sku_product}`}
                        >
                          {currentData.indAll >= 0 &&
                            ifRegisterForm(
                              currentData.indAll,
                              indAll,
                              row,
                              currentData,
                              'fn',
                              0,
                            )}
                          {ifRegisterForm(
                            indAll,
                            indAll,
                            row,
                            currentData,
                            'st',
                            0,
                          )}

                          <div hidden={true}>
                            {currentData.warehouse_id !== row.warehouse.id
                              ? (currentData.warehouse_id = row.warehouse.id)
                              : ''}
                            {currentData.warehouse_name !==
                            row.warehouse.name_repr
                              ? (currentData.warehouse_name =
                                  row.warehouse.name_repr)
                              : ''}
                            {currentData.indAll !== indAll
                              ? (currentData.indAll = indAll)
                              : ''}
                          </div>

                          <Grid
                            component="div"
                            container
                            spacing={0}
                            id={`${id}${'#'}${row.id}${'#'}${index}`}
                            className="cart_row">
                            <Grid
                              component="div"
                              container
                              spacing={0}
                              className={classes.row}
                              style={{
                                backgroundColor:
                                  row.badge_name !== undefined && '#f8f8f8',
                              }}>
                              <Grid
                                component="div"
                                item
                                xs={1}
                                align="center"
                                className={clsx(classes.gridItem, 'grid_item')}
                                style={{display: 'grid'}}>
                                {row.art_photos[0] ? (
                                  row.large_photos.length > 0 ? (
                                    <img
                                      className={classes.mainImg}
                                      src={row.art_photos[0]}
                                      alt={''}
                                      onClick={() =>
                                        setPhotosGallery({
                                          row: row,
                                          type: 'basket',
                                        })
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        margin: '0 auto',
                                      }}
                                    />
                                  ) : (
                                    <img
                                      className={classes.mainImg}
                                      src={row.art_photos[0]}
                                      alt={''}
                                    />
                                  )
                                ) : (
                                  <img
                                    className={classes.mainImg}
                                    /*src={'https://pypik.ru/uploads/posts/2018-09/1536907413_foto-net-no-ya-krasivaya-4.jpg'}*/
                                    src={product}
                                    alt={'Нет фото'}
                                  />
                                )}
                              </Grid>
                              <RouterLink
                                className={clsx(
                                  classes.stShowProductCart,
                                  'show_product_cart',
                                )}
                                //onClick={showProductCart(row.brand_clean, row.article_clean)}
                                to={`${'/product/'}${row.brand_clean}${'/'}${
                                  row.article_clean
                                }`}
                                title="Показать карточку товара">
                                <Grid
                                  component="div"
                                  item
                                  xs={2}
                                  className={clsx(
                                    classes.gridItem,
                                    'grid_item',
                                  )}>
                                  <div
                                    className="image_container"
                                    style={{display: 'grid'}}>
                                    <Typography
                                      variant={'body2'}
                                      className={clsx(
                                        classes.stBrand,
                                        'brand',
                                      )}>
                                      {row.brand}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid
                                  component="div"
                                  item
                                  xs={2}
                                  className={clsx(
                                    classes.gridItem,
                                    'grid_item',
                                  )}>
                                  <div className={'article_code_container'}>
                                    <Typography
                                      variant={'body2'}
                                      className={clsx(
                                        classes.stArticle,
                                        'article',
                                      )}>
                                      {row.article}
                                    </Typography>
                                    <Typography
                                      variant={'body2'}
                                      className={classes.stInfo}>
                                      {`${t('common:code')}: ${
                                        row.sku_product
                                      }`}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid
                                  component="div"
                                  item
                                  xs={4}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                  className={clsx(
                                    classes.gridItem,
                                    'grid_item',
                                  )}>
                                  <div>
                                    <Typography
                                      variant={'body2'}
                                      className={classes.stName}>
                                      {row.name}
                                    </Typography>
                                    <span className={classes.stInfo}>
                                      {row.weight > 0
                                        ? t('common:weight') +
                                          ': ' +
                                          row.weight +
                                          ' ' +
                                          t('common:kg')
                                        : ''}
                                    </span>
                                  </div>

                                  {promotionsByProductsList &&
                                    promotionsByProductsList[row.product_id] !==
                                      undefined && (
                                      <>
                                        <div
                                          title={getDescriptForPromo(
                                            promotionsByProductsList[
                                              row.product_id
                                            ],
                                          )}>
                                          <Info
                                            style={{color: '#315EFB'}}
                                            onClick={e =>
                                              openPromoModal(
                                                e,
                                                row.product_id,
                                                row.price_uuid,
                                              )
                                            }
                                          />
                                        </div>
                                        {console.log(
                                          modalInfoId,
                                          'modalInfoId',
                                        )}
                                        {console.log(
                                          row.price_uuid,
                                          'promotionsByProductsList[row.product_id]',
                                        )}
                                        {modalInfoId !== undefined &&
                                          row.price_uuid === modalInfoUuid && (
                                            <Dialog
                                              onClick={e => promoClose(e)}
                                              maxWidth="sm"
                                              fullWidth={true}
                                              open={promoModal}>
                                              <DialogTitle
                                                id="customized-dialog-title"
                                                onClick={() =>
                                                  setPromoModal(false)
                                                }>
                                                <Grid
                                                  container
                                                  item
                                                  xs={12}
                                                  className={clsx(
                                                    classes.gridContainerHr,
                                                    classes.stMainHeaderHelp,
                                                  )}>
                                                  <span
                                                    className={
                                                      classes.stMainHeader
                                                    }>
                                                    {t('common:DearCustomer')}!
                                                  </span>
                                                  {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                                                  <IconButton
                                                    onClick={() =>
                                                      setPromoModal(false)
                                                    }
                                                    className="close"
                                                    size={'small'}>
                                                    <Cansel />
                                                  </IconButton>
                                                </Grid>
                                                <Divider
                                                  className={classes.divider}
                                                />
                                              </DialogTitle>
                                              <DialogContent
                                                className={
                                                  classes.warehouseHelpContent
                                                }>
                                                {/*{console.log(promotionsByProductsList[row.product_id][0].promotion_name, '--------------!!!!!!!!!!!!!!!')}*/}
                                                <div>
                                                  <ImageWithFallback
                                                    images={
                                                      promotionsByProductsList[
                                                        modalInfoId
                                                      ][0]
                                                        .promotion_banner_tablet
                                                        ? promotionsByProductsList[
                                                            row.product_id
                                                          ][0]
                                                            .promotion_banner_tablet
                                                        : null
                                                    }
                                                    imgAlt={
                                                      promotionsByProductsList[
                                                        modalInfoId
                                                      ][0].promotion_name
                                                    }
                                                    style={{
                                                      width: '100%',
                                                      objectFit: 'cover',
                                                    }}
                                                  />
                                                </div>
                                                <div>
                                                  <h4>
                                                    {
                                                      promotionsByProductsList[
                                                        modalInfoId
                                                      ][0].promotion_name
                                                    }
                                                  </h4>
                                                  <div
                                                    dangerouslySetInnerHTML={createMarkup(
                                                      promotionsByProductsList[
                                                        modalInfoId
                                                      ][0]
                                                        .promotion_description,
                                                    )}
                                                  />
                                                  {/* {
                                                      promotionsByProductsList[
                                                        modalInfoId
                                                      ][0].promotion_description
                                                    }
                                                  </div> */}
                                                </div>
                                              </DialogContent>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'flex-end',
                                                  padding: '10px',
                                                }}>
                                                <Link
                                                  to={`/promotion/${promotionsByProductsList[modalInfoId][0].promotion_url}/${promotionsByProductsList[modalInfoId][0].promotion_id}`}>
                                                  <button className="btn">
                                                    {t('common:TermsOfAction')}
                                                  </button>
                                                </Link>
                                              </div>
                                            </Dialog>
                                          )}
                                      </>
                                    )}
                                </Grid>
                              </RouterLink>

                              <Grid
                                component="div"
                                container
                                item
                                xs={3}
                                spacing={0}>
                                {/*<Grid item xs={4} align="center" className={clsx(classes.gridItem, 'grid_item')}>
                                                                        <ListItemText
                                                                        secondaryTypographyProps={{style: {color: indAll === 2 ? 'red' : (indAll === 0 && 'green')}}}
                                                                        primary={row.warehouse.name_repr}
                                                                        secondary={nameGroup[indAll]}
                                                                        />
                                                                        </Grid>*/}
                                <Grid
                                  component="div"
                                  item
                                  xs={6}
                                  align="center"
                                  className={clsx(
                                    classes.gridItem,
                                    'grid_item',
                                  )}
                                  justify={'center'}>
                                  {row.badge_name ? (
                                    <div
                                      style={{display: 'grid', width: '108px'}}>
                                      {<PromoLabel promoLabel={[row]} />}
                                      <span className={classes.stInfo}>
                                        {row.quantity}
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      style={{display: 'grid', width: '100%'}}>
                                      <MyNumericInput
                                        onChange={onChangeQuantity}
                                        uuid={row.id}
                                        price={row.price_current}
                                        priceCurrent={row.price_current}
                                        min={1}
                                        max={row.available_quantity}
                                        value={row.quantity}
                                        defaultValue={1}
                                        step={1}
                                        stDisplay={'flex'}
                                        onCartPage={true}
                                      />
                                      {/*{console.log(row.discounts_by_quantity_sum_of_order, '---------!!!!!!--row.discounts_by_quantity_sum_of_order')}*/}
                                      {row.discounts_by_quantity_sum_of_order !==
                                        null &&
                                      row.discounts_by_quantity_sum_of_order
                                        .next_discount !== undefined &&
                                      row.discounts_by_quantity_sum_of_order
                                        .next_discount !== null &&
                                      row.discounts_by_quantity_sum_of_order
                                        .next_discount
                                        .discount_conditions_document__promotion_type ===
                                        'FROM_THE_QUANTITY_OF_GOODS' ? (
                                        <div
                                          className={
                                            classes.next_discount_block
                                          }>
                                          {row
                                            .discounts_by_quantity_sum_of_order
                                            .next_discount.quantity <=
                                          row.available_quantity ? (
                                            <>
                                              <div>
                                                <span
                                                  style={{margin: '0 5px 0 0'}}>
                                                  {t('common:add_more')}
                                                </span>
                                                {parseInt(
                                                  row
                                                    .discounts_by_quantity_sum_of_order
                                                    .next_discount.quantity,
                                                ) - parseInt(row.quantity)}
                                                <span
                                                  style={{margin: '0 0 0 5px'}}>
                                                  {t(
                                                    'common:andGetTheBestPrice',
                                                  )}
                                                </span>
                                              </div>
                                              <div>
                                                <PriceToStr
                                                  stSumma={{
                                                    fontStyle: 'normal',
                                                    fontSize: 12,
                                                    fontWeight: 'normal',
                                                    color: 'red',
                                                  }}
                                                  data={
                                                    row
                                                      .discounts_by_quantity_sum_of_order
                                                      .next_discount
                                                      .price_with_discount
                                                  }
                                                  beforeText={''}
                                                  text={
                                                    currencyMainContr.simbol
                                                  }
                                                  grn={false}
                                                />{' '}
                                                / шт
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                      ) : row.discounts_by_quantity_sum_of_order
                                          .next_discount !== undefined &&
                                        row.discounts_by_quantity_sum_of_order
                                          .next_discount !== null &&
                                        row.discounts_by_quantity_sum_of_order
                                          .next_discount
                                          .discount_conditions_document__promotion_type ===
                                          'FROM_THE_SUM_OF_ORDER' ? (
                                        <div
                                          className={
                                            classes.next_discount_block
                                          }>
                                          <>
                                            <div>
                                              <span
                                                style={{margin: '0 5px 0 0'}}>
                                                {t(
                                                  'common:WithTheTotalAmountOfTheOrder',
                                                )}
                                              </span>
                                              <PriceToStr
                                                stSumma={{
                                                  fontStyle: 'normal',
                                                  fontSize: 12,
                                                  fontWeight: 'normal',
                                                }}
                                                data={
                                                  row
                                                    .discounts_by_quantity_sum_of_order
                                                    .next_discount !==
                                                    undefined &&
                                                  row
                                                    .discounts_by_quantity_sum_of_order
                                                    .next_discount
                                                    .discount_conditions_document__sum_of_order
                                                }
                                                beforeText={''}
                                                text={currencyMainContr.simbol}
                                                grn={false}
                                              />
                                              <span
                                                style={{
                                                  margin: '0 0 0 5px',
                                                  display: 'block',
                                                }}>
                                                {t('common:thePriceOfTheGoods')}
                                              </span>
                                            </div>
                                            <div>
                                              <PriceToStr
                                                stSumma={{
                                                  fontStyle: 'normal',
                                                  fontSize: 12,
                                                  fontWeight: 'normal',
                                                  color: 'red',
                                                }}
                                                data={
                                                  row
                                                    .discounts_by_quantity_sum_of_order
                                                    .next_discount
                                                    .price_with_discount
                                                }
                                                beforeText={''}
                                                text={currencyMainContr.simbol}
                                                grn={false}
                                              />{' '}
                                              /{' '}
                                              <span>{t('common:pieces')}</span>
                                            </div>
                                          </>
                                        </div>
                                      ) : null}
                                      {/*<span className={classes.stInfo}*/}
                                      {/*    style={{color: row.quantity > row.available_quantity && 'red'}}>*/}
                                      {/*    {t('common:Available') + row.available_quantity}*/}
                                      {/*    </span>*/}
                                    </div>
                                  )}
                                </Grid>

                                <Grid
                                  component="div"
                                  container
                                  item
                                  xs={6}
                                  align="right"
                                  className={classes.gridContainerSubBlPrice}
                                  style={{display: 'flex'}}>
                                  <div style={{display: 'block'}}>
                                    {row.isDiscount !== 0 && (
                                      <PriceToStr
                                        stSumma={{
                                          fontStyle: 'normal',
                                          fontSize: 12,
                                          fontWeight: 'normal',
                                          color: '#546e7a',
                                          textDecoration: 'line-through',
                                        }}
                                        data={row.quantity * row.regularPrice}
                                        beforeText={''}
                                        text={
                                          currencyMainContr.simbol === '-'
                                            ? ''
                                            : currencyMainContr.simbol
                                        }
                                        grn={false}
                                      />
                                    )}
                                    <ListItemText
                                      style={{
                                        marginTop: row.isDiscount !== 0 && -2,
                                      }}
                                      classes={{
                                        primary: classes.stPrice,
                                        //secondary: classes.secondaryPrice
                                      }}
                                      primary={
                                        <PriceToStr
                                          stAll={{
                                            color:
                                              row.isDiscount !== 0 && 'red',
                                          }}
                                          data={
                                            row.price_current * row.quantity
                                          }
                                          beforeText={''}
                                          text={
                                            currencyMainContr.simbol === '-'
                                              ? ''
                                              : currencyMainContr.simbol
                                          }
                                          grn={false}
                                        />
                                      }
                                      secondary={
                                        <PriceToStr
                                          data={row.price_current}
                                          beforeText={row.quantity + ' x '}
                                          text={
                                            currencyMainContr.simbol === '-'
                                              ? ''
                                              : currencyMainContr.simbol
                                          }
                                          grn={false}
                                        />
                                      }
                                    />
                                  </div>
                                  <IconButton
                                    disabled={row.badge_name}
                                    style={{opacity: row.badge_name && 0}}
                                    edge="end"
                                    aria-label="comments"
                                    onClick={deleteItemsFromBasket(
                                      [row.id],
                                      `${id}${'#'}${row.id}${'#'}${index}`,
                                    )}>
                                    {/*<BasketDelete onClick={() => deleteItemsFromCart([row.id])}/>*/}
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg">
                                      <circle
                                        cx="14"
                                        cy="14"
                                        r="14"
                                        fill="#EBF0F8"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M19.5639 9.78062H17.1524V9.17775C17.1524 8.15287 16.3687 7.36914 15.3438 7.36914H12.9323C11.9075 7.36914 11.1237 8.15287 11.1237 9.17775V9.78062H8.71225C8.35052 9.78062 8.10938 10.0218 8.10938 10.3835C8.10938 10.7452 8.35052 10.9864 8.71225 10.9864H9.31512V18.8237C9.31512 19.8486 10.0988 20.6323 11.1237 20.6323H17.1524C18.1773 20.6323 18.9611 19.8486 18.9611 18.8237V10.9864H19.5639C19.9256 10.9864 20.1668 10.7452 20.1668 10.3835C20.1668 10.0218 19.9256 9.78062 19.5639 9.78062ZM12.3281 9.17807C12.3281 8.81634 12.5693 8.5752 12.931 8.5752H15.3425C15.7042 8.5752 15.9453 8.81634 15.9453 9.17807V9.78094H12.3281V9.17807ZM17.155 19.4255C17.5167 19.4255 17.7579 19.1844 17.7579 18.8227V10.9854H10.5234V18.8227C10.5234 19.1844 10.7646 19.4255 11.1263 19.4255H17.155Z"
                                        fill="#315EFB"
                                      />
                                    </svg>
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      ),
                    //)
                  ),
                )
              ) : (
                <NoProducts variant="cartProducts" />
              )}
              {ifRegisterForm(
                currentData.indAll,
                currentData.indAll,
                {},
                currentData,
                'fn',
                1,
              )}
            </div>
          </div>

          <div
            style={{
              display: 'block',
              float: 'right',
              marginTop: 5,
              textAlign: 'right',
            }}>
            {/*{totalCost[id].regular_price > 0 &&
                                                                        <PriceToStr
                                                                        data={totalCost[id].regular_price}
                                                                        stAll={{color: 'red'}}
                                                                        // stBefore={{fontStyle: "normal", color: '#333'}}
                                                                        // stSumma={{fontStyle: "normal", fontWeight: 'bold'}}
                                                                        beforeText={t('common:save_money') + ' '}
                                                                        text={currencyMainContr.simbol}
                                                                        grn={t('common:uah')}/>
                                                                        }*/}
            <ListItemText
              classes={{
                primary: classes.primaryTotal,
                secondary: classes.secondaryTotalFinish,
              }}
              primary={
                <PriceToStr
                  stBefore={{fontStyle: 'normal', color: '#333'}}
                  stSumma={{fontStyle: 'normal', fontWeight: 'bold'}}
                  data={totalCost[id].price_current}
                  beforeText={t('common:just_in_the_basket') + ': '}
                  text={
                    currencyMainContr.simbol === '-'
                      ? ''
                      : currencyMainContr.simbol
                  }
                  grn={t('common:uah')}
                />
              }
              secondary={
                currencyMain &&
                currencyMain.simbol &&
                currencyMain.simbol !== currencyMainContr.simbol && (
                  <PriceToStr
                    data={
                      currencyMain && currencyMain.simbol === '₴'
                        ? Math.round(
                            totalCost[id].price_current *
                              currencyMainContr.koef,
                          )
                        : totalCost[id].price_current * currencyMainContr.koef
                    }
                    beforeText={''}
                    text={
                      currencyMain && currencyMain.simbol
                        ? currencyMain.simbol
                        : '??'
                    }
                    grn={t('common:uah')}
                  />
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CartTable.propTypes = {
  className: PropTypes.string,
  //data: PropTypes.object.isRequired,
  //addToCart: PropTypes.func.isRequired,
};

export default CartTable;
