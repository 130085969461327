import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {DialogHeader} from '../DialogHeader';
import {NavBtns} from '../NavBtns';
import {FirstStep} from './FirstStep';
import ThirdStep from './ThirdStep';
import {FourthStep} from './FourthStep';
import {SecondStep} from './SecondStep';

export const Steps = props => {
  const {
    orders,
    setShowLastContent,
    showLastContent,
    setFirstOrder,
    invoices,
    userSelectedData,
    setSelectedInvoiceItem,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [disableBtn, setDisableBtn] = useState(true);

  // const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedResults, setSelectedResults] = useState([]);

  useEffect(() => {
    setFirstOrder(selectedResults[0]);
  }, [selectedResults]);

  const {t} = useTranslation();

  const stepsTitles = [
    t('common:return_terms_nav_title_1'),
    t('common:return_terms_nav_title_1'),
    t('common:return_terms_nav_title_2'),
    t('common:return_terms_nav_title_3'),
  ];

  return (
    <div className={clsx('main-content', showLastContent ? 'hidden' : '')}>
      <DialogHeader
        title={stepsTitles[activeStep]}
        activeStep={activeStep + 1}
      />
      <div className={clsx('step-item', activeStep === 0 ? 'active' : '')}>
        <FirstStep
          // filteredOrders={filteredOrders}
          // setFilteredOrders={setFilteredOrders}
          setSelectedInvoiceItem={setSelectedInvoiceItem}
          orders={orders}
          setSelectedResults={setSelectedResults}
          setDisableBtn={setDisableBtn}
          selectedResults={selectedResults}
          invoices={invoices}
          userSelectedData={userSelectedData}
        />
      </div>
      <div className={clsx('step-item', activeStep === 1 ? 'active' : '')}>
        <SecondStep
          stepIsActive={activeStep === 1}
          selectedResults={selectedResults}
          setSelectedResults={setSelectedResults}
          setDisableBtn={setDisableBtn}
        />
      </div>
      <div className={clsx('step-item', activeStep === 2 ? 'active' : '')}>
        <ThirdStep setDisableBtn={setDisableBtn} activeStep={activeStep} />
      </div>
      <div className={clsx('step-item', activeStep === 3 ? 'active' : '')}>
        <FourthStep stepIsActive={activeStep === 3} orders={selectedResults} />
      </div>
      <NavBtns
        setShowLastContent={setShowLastContent}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        disableBtn={disableBtn}
      />
    </div>
  );
};
