import {TextField, withStyles} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import React, {useEffect, useRef} from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDebouncer} from '../../../../hooks/useDebouncer';
import axInst from '../../../../_helpers/axios-instance';
import FormField from '../../../Authorization/FormField';
import {TableProductItem1} from '../TableProductItem1';
import {TableHeader} from './TableHeader';

const Input = withStyles({
  root: {
    borderRadius: 40,
    background: '#EBF0F8',
    border: 'none',

    '& .MuiInputBase-input': {
      marginLeft: 20,
    },
    '& .MuiFormLabel-root': {
      left: 20,
      color: '#000',

      '&.Mui-focused': {
        color: '#315EFB',
      },
    },

    '& .MuiFilledInput-root': {
      background: 'transparent',
    },
    '& .MuiFilledInput-underline:after': {
      border: 'none',
      content: '',
    },
    '& .MuiFilledInput-underline:before': {
      border: 'none',
      content: '',
    },
  },
})(TextField);

export const FirstStep = props => {
  const {
    orders,
    // setFilteredOrders,
    // filteredOrders,
    setSelectedInvoiceItem,
    setSelectedResults,
    selectedResults,
    setDisableBtn,
    invoices,
    userSelectedData,
  } = props;

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {codeNumber: ''},
    touched: {},
    errors: {},
  });
  // const orderIndexes = orders?.order_items?.map(i => i.order__id);
  // const orderOptions = orderIndexes?.filter(
  //   (number, index, numbers) => numbers.indexOf(number) === index,
  // );
  // const arrOfInvoice = invoices?.invoices?.map(
  //   item => item?.description_number,
  // );

  // console.log(invoices);
  // console.log(arrOfInvoice);
  // console.log(formState);
  const {t} = useTranslation();

  useEffect(() => {
    if (selectedResults.length > 0) {
      setDisableBtn(false);
    }
  }, [selectedResults]);
  useEffect(() => {
    if (invoices.invoices.length > 0) {
      setSelectedInvoiceItem(
        invoices.invoices.find(
          item =>
            item.description_number ===
            formState.values.codeNumber.description_number,
        ),
      );
    }
  }, [formState, invoices]);
  // console.log(formState.values.codeNumber);
  const handleChangeAutocomplete = (_, order) => {
    if (!order) {
      return setFilteredOrders(orders.order_items);
    }

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        codeNumber: order,
      },
    });
    // setFilteredOrders(
    //   orders?.order_items?.filter(item => item?.order__id === order),
    // );

    axInst
      .post(
        '/api/get-invoice-items/',
        JSON.stringify({
          customer_id: userSelectedData.customer_id,
          number: order.number,
          date: order.date,
        }),
      )
      .then(res => {
        setFilteredOrders(res.data.invoices);
      });
  };

  console.log(filteredOrders);

  if (invoices.loading) {
    return <></>;
  }

  return (
    <div>
      <div className="user-form p-0">
        <Autocomplete
          id="combo-box-demo"
          options={invoices.invoices}
          getOptionLabel={option => option.description_number}
          style={{borderRadius: '40px'}}
          onChange={(e, order) => handleChangeAutocomplete(e, order)}
          renderInput={params => (
            <Input {...params} label={t('common:id_zakazu')} variant="filled" />
          )}
        />
      </div>
      {filteredOrders.length > 0 && (
        <>
          <div className="results-by-search">
            <h3 className="title mt-4 mb-5">
              {t('common:return_terms_text_2')}
            </h3>
            {/* <TableHeader /> */}
            <div className="products-all">
              {filteredOrders?.map(order => (
                <React.Fragment key={order.id}>
                  <TableProductItem1
                    order={order}
                    setSelectedResults={setSelectedResults}
                    selectedResults={selectedResults}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
