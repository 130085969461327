import React from 'react';

export const TabPaneDescription = ({result}) => {
  console.log(result);
  return (
    <div className="single-product__descriptions">
      {result?.article.product_description}
      {/* <div className="single-product__descriptions-block">
        <h3 className="single-product__descriptions-title">Застосування:</h3>
        <ul className="list">
          <li>PREMIUM PR 4X2 (FAB 22AA/AB)</li>
          <li>PREMIUM TRACTEUR 4X2(FAB 22CC)</li>
          <li>PREMIUM(FAB22EE/HA/HB/JJ/QQ/RR</li>
          <li>DCI 11 [08.00-]</li>
        </ul>
      </div>
      <div className="single-product__descriptions-block">
        <h3 className="single-product__descriptions-title">Характеристики:</h3>
        <ul className="list">
          <li>Розмір [mm]: 595 x 813 x 62</li>
          <li>Матеріал: Aluminium</li>
        </ul>
      </div>
      <div className="single-product__descriptions-block">
        <h3 className="single-product__descriptions-title">Вага:</h3>
        <ul className="list">
          <li>19кг. 600г.</li>
        </ul>
      </div> */}
    </div>
  );
};
