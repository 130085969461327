import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';

import PriceToStr from '../../../_components/PriceToStr';
import IconDiscount from '../../../images/icon_discount.svg';
import StorageBlockHide from '../../ProductList/components/StorageBlockHide';
import DiscountLabel from '../../../_components/DiscountLabel';
import PromoLabel from '../../../_components/PromoLabel';
import AddToFavorite from '../../ProductList/components/AddToFavorite';
import product from '../../../images/product.png';
import connect from 'react-redux/es/connect/connect';
import {customerActions} from '../../../_actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
    borderRadius: 10,
    /*overflowX: 'auto',*/
  },
  /*table: {
         minWidth: 700,
     },*/

  paper: {
    /*padding: theme.spacing(2),*/
    padding: '16px 4px',
    textAlign: 'center',
    borderRadius: 0,
    backgroundColor: '#eee',
    fontsize: '14px!important',
  },

  gridItem: {
    // padding: '5px 5px',
    // borderStyle: 'solid!important',
    // borderColor: '#eee',
    // borderWidth: 1,
    // wordWrap: 'break-word',
    // display: 'flex',
    /*justifyContent: 'center',*/
    // alignItems: 'center',
  },
  gridItemBasket: {
    padding: '0 0px',
    borderStyle: 'solid!important',
    borderColor: '#eee',
    borderWidth: 1,
    wordWrap: 'break-word',
    display: 'flex',
    /*justifyContent: 'center',*/
    alignItems: 'center',
  },

  gridItemHead: {
    textAlign: 'center',
    // borderStyle: 'solid!important',
    // borderColor: '#eee',
    // borderWidth: 1,
    padding: '16px 4px',
    fontWeight: 500,
    fontsize: '14px',
    backgroundColor: '#EBF0F8',
  },

  row: {
    '&:hover': {backgroundColor: '#fafafa'},
  },

  mainImg: {maxWidth: '100%', maxHeight: 50, verticalAlign: 'middle'},

  otherOffersHr: {
    transitionProperty: 'opacity',
    transitionDuration: '300ms',
    opacity: 0,
    zIndex: 2,
    width: 350,
    backgroundColor: 'white',
    marginLeft: 'calc(100% - 371px - 5px)',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'calc(66% - 280px - 10px)',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 585,
    },
    marginTop: 60,
    position: 'absolute',
    display: 'none',
    //maxHeight: 350,
  },
  stBalance: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
  },
  stDelivery: {
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  signOutButton: {
    marginRight: 5,
    minWidth: 40,
    padding: '4px 0',
    //backgroundColor: 'darkorange',//forestgreen
  },
  gridContainerSubBlPrice: {
    // borderStyle: 'solid!important',
    // borderColor: '#eee',
    // borderWidth: 1,
    borderBottom: '1px solid #eee',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-end', //'space-between',
  },

  stBrand: {
    // fontWeight: 600,
    // padding: 0,
    // marginBottom: 5,
    // overflow: 'auto',
    // color: '#232323',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis', /* Многоточие */
  },

  stBlockDelivery: {display: 'grid', lineHeight: 1.3, width: '100%'},

  stArticle: {
    color: '#315EFB',
  },
  stName: {
    // fontSize: 16
  },
  stInfo: {
    fontSize: 14,
    color: '#546e7a',
  },
  stPrice: {
    padding: '8px 10px 8px 2px',
    fontSize: 14,
    fontWeight: 'bold',
  },

  stMoreInfo: {
    cursor: 'pointer',
    backgroundColor: 'darkgray',
    borderRadius: '50%',
    color: 'white',
  },

  stShowProductCart: {
    // display: "contents",
    // cursor: "pointer",
    textDecoration: 'none',
    color: 'inherit',
    // display: "contents",
    // cursor: "pointer",
    '&:hover': {
      // color: '#3f51b5',
    },
  },

  labelBlock: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}));

const WishlistTable = ({
  results,
  setDialogData,
  type,
  sell_currency_id,
  main_currency,
  currencies,
  removeFromWishlist,
  addToWishlist,
  userSelectedData,
}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const [countRows, setCountRows] = useState(40);
  const [selectUuid, setSelectUuid] = useState(false);
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const {wish_list} = userSelectedData;

  const addRows = () => {
    //console.log('countRows', countRows);
    setCountRows(countRows + 40);
  };

  const currencyMain =
    sell_currency_id &&
    currencies &&
    currencies.find(x => x.id === sell_currency_id);

  const handleUuidInfo = uuid => event => {
    //event.preventDefault();
    event.stopPropagation();
    setSelectUuid(uuid);
    setAnchorElInfo(event.currentTarget);
  };

  const handleLike = (event, data) => {
    event.preventDefault();
    if (wish_list.find(i => i.article === data.article)) {
      removeFromWishlist(data);
    } else {
      addToWishlist(data);
    }
  };
  //   const currencyMainContr = getCurrencyContrData(
  //     currencyMain,
  //     main_currency,
  //     sell_currency_id,
  //     customer_data,
  //   );

  return (
    <div className={clsx(classes.root, 'search_result_product_table')}>
      <Grid container spacing={0}>
        <Grid container item xs={12} spacing={0} style={{fontSize: 14}}>
          <Grid
            style={{borderRadius: '10px 0 0 0'}}
            item
            xs={1}
            align="center"
            className={classes.gridItemHead}>
            <div>{t('common:photo')}</div>
          </Grid>

          <Grid item xs={2} align="center" className={classes.gridItemHead}>
            <div>{t('common:article_brand')}</div>
          </Grid>
          <Grid item xs={4} className={classes.gridItemHead}>
            <div>{t('common:name')}</div>
          </Grid>
          <Grid container item xs={5} spacing={0}>
            <Grid item xs={5} align="center" className={classes.gridItemHead}>
              <div>{t('common:availability')}</div>
            </Grid>
            {/*<Grid item xs={2} align="center" className={classes.gridItemHead}>
                                  <div>{t('common:quantity')}</div>
                              </Grid>*/}
            <Grid
              style={{borderRadius: '0 10px 0 0'}}
              item
              xs={7}
              align="center"
              className={classes.gridItemHead}>
              <div>{t('common:price')}</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {results.length > 0 &&
        results.map((row, index) => (
          <Grid
            container
            className={clsx(
              'search_result_product_table_row',
              row.balance.every(i => i.quantity === '0') ? 'product-empty' : '',
            )}
            spacing={0}
            // key={`${row.article}${row.brand}${row.sku}${index}`}
            key={`${row.product_id}`}>
            <Grid
              container
              spacing={0}
              style={{display: 'flex'}}
              className={classes.row}>
              <Grid
                item
                xs={1}
                align="center"
                className={clsx(classes.gridItem, 'grid_item')}>
                <Grid container alignItems="center">
                  <Grid item align="center" xs={12}>
                    {row.images.thumb ? (
                      row.images.large.length > 0 ? (
                        <img
                          className={classes.mainImg}
                          src={row.images.thumb}
                          alt={''}
                          //  onClick={() =>
                          //    setPhotosGallery({row: row, type: 'search'})
                          //  }
                          style={{cursor: 'pointer'}}
                        />
                      ) : (
                        <img
                          className={classes.mainImg}
                          src={row.images.thumb}
                          alt={''}
                        />
                      )
                    ) : (
                      <img
                        className={classes.mainImg}
                        /*src={'https://pypik.ru/uploads/posts/2018-09/1536907413_foto-net-no-ya-krasivaya-4.jpg'}*/
                        src={product}
                        alt={t('common:No photo')}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <RouterLink
                className={clsx(classes.stShowProductCart, 'show_product_cart')}
                //onClick={showProductCart(row.brand_clean, row.article_clean)}
                to={`${'/product/'}${row.brand_clean}${'/'}${
                  row.article_clean
                }`}
                title="Показать карточку товара">
                {row.balance && row.offers && (
                  <AddToFavorite
                    row={row}
                    favoriteSost={row.favorite_id ? true : false}
                    forTable={1}
                  />
                )}

                <Grid
                  item
                  xs={2}
                  className={clsx(classes.gridItem, 'grid_item')}>
                  <div>
                    <Typography
                      variant={'body2'}
                      className={clsx(classes.stBrand, 'brand')}>
                      {row.brand}
                    </Typography>
                    <Typography
                      variant={'body2'}
                      className={clsx(classes.stArticle, 'article')}>
                      {row.article}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  align="left"
                  className={clsx(classes.gridItem, 'grid_item')}>
                  <div style={{display: 'grid', width: '100%'}}>
                    <Typography variant={'body2'} className={classes.stName}>
                      {row.name}
                    </Typography>
                    {/*<span className={classes.stInfo}>{`${t('common:code')}: ${row.sku}`}</span>*/}
                    <span className={classes.stInfo}>
                      {row.weight > 0
                        ? t('common:weight') +
                          ': ' +
                          row.weight +
                          ' ' +
                          t('common:kg')
                        : ''}
                    </span>
                    <div className={classes.labelBlock}>
                      {row.promoLabel && row.promoLabel[0] !== undefined && (
                        <PromoLabel promoLabel={row.promoLabel} />
                      )}
                      {row.isDiscount && row.isDiscount !== 0 ? (
                        <DiscountLabel
                          discount={`${'- '}${row.isDiscount}${'%'}`}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Grid>
              </RouterLink>

              <Grid container item xs={5} spacing={0}>
                <Grid
                  item
                  xs={5}
                  align="left"
                  className={clsx(classes.gridItem, 'grid_item')}>
                  <StorageBlockHide
                    type={type}
                    warehouses={row.balance.map(i => ({
                      ...i,
                      id: i.warehouse_id,
                    }))}
                    typePage={'card'}
                    index={index}
                    row={row}
                    setDialogData={setDialogData}
                    //addToCart={}
                    classStDelivery={classes.stDelivery}
                    classStBalance={classes.stBalance}
                    handleUuidInfo={handleUuidInfo}
                    noInfo
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={7}
                  align="center"
                  className={classes.gridContainerSubBlPrice}>
                  <Grid item xs={9} align="center" className={classes.stPrice}>
                    {currencyMain && currencyMain.simbol && (
                      <div>
                        {row.discounts_by_quantity_sum_of_order &&
                        row.discounts_by_quantity_sum_of_order.best_discount !==
                          undefined &&
                        row.discounts_by_quantity_sum_of_order.best_discount
                          .discount_conditions_document__promotion_type !==
                          'NO_CONDITION' ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '-2px',
                            }}>
                            <PriceToStr
                              stAll={{marginBottom: -5}}
                              stSumma={{
                                fontStyle: 'normal',
                                fontSize: 14,
                                fontWeight: 'normal',
                                color: '#546e7a',
                                textDecoration: 'line-through',
                              }}
                              data={row.price}
                              beforeText={''}
                              text={currencyMain?.simbol}
                              grn={t('common:uah')}
                            />
                            <PriceToStr
                              stSumma={{
                                fontStyle: 'normal',
                                fontSize: '16px',
                                color: 'red',
                                fontWeight: '700',
                              }}
                              data={
                                row.discounts_by_quantity_sum_of_order
                                  .best_discount.price_with_discount
                              }
                              beforeText={''}
                              text={currencyMain?.simbol}
                              grn={t('common:uah')}
                            />
                          </div>
                        ) : (
                          <PriceToStr
                            stAll={{marginBottom: -5}}
                            stSumma={{
                              fontStyle: 'normal',
                              fontSize: 16,
                              fontWeight: '700',
                            }}
                            data={row.price}
                            beforeText={''}
                            text={currencyMain?.simbol}
                            grn={t('common:uah')}
                          />
                        )}
                      </div>
                    )}
                    {/* {row.discounts_by_quantity_sum_of_order &&
                  row.discounts_by_quantity_sum_of_order.best_discount !==
                    undefined &&
                  row.discounts_by_quantity_sum_of_order.best_discount
                    .discount_conditions_document__promotion_type !==
                    'NO_CONDITION' ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                          style={{width: '20px', margin: '0 5px 0 0'}}
                          src={IconDiscount}
                          alt={'IconDiscount'}
                        />
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: 12,
                            margin: '0 5px 0 0',
                          }}>
                          {t('common:best_price')}
                        </span>
                      </div>
                      <PriceToStr
                        stSumma={{
                          fontStyle: 'normal',
                          fontSize: '12px',
                          color: 'red',
                          fontWeight: '600',
                        }}
                        data={
                          row.discounts_by_quantity_sum_of_order.best_discount
                            .price_with_discount
                        }
                        beforeText={''}
                        // text={currencyMain.simbol} grn={t('common:uah')}
                      />
                    </div>
                  ) : null} */}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{display: 'flex', alignItems: 'center', gap: 10}}>
                    <Grid item>
                      <div
                        disabled={
                          !(
                            row.balance.some(i => i.quantity_int > 0) &&
                            (row.price || row.offers[0].price)
                          )
                        }
                        onClick={e =>
                          handleLike(e, {
                            article: row.article_clean,
                            brand: row.brand,
                          })
                        }
                        style={{
                          cursor: 'pointer',
                          background: '#EBF0F8',
                          width: 35,
                          height: 35,
                          borderRadius: 35,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <svg
                          class="like-default"
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.5639 2.78062H9.15244V2.17775C9.15244 1.15287 8.36871 0.369141 7.34382 0.369141H4.93234C3.90746 0.369141 3.12373 1.15287 3.12373 2.17775V2.78062H0.712246C0.350523 2.78062 0.109375 3.02177 0.109375 3.38349C0.109375 3.74522 0.350523 3.98637 0.712246 3.98637H1.31512V11.8237C1.31512 12.8486 2.09885 13.6323 3.12373 13.6323H9.15244C10.1773 13.6323 10.9611 12.8486 10.9611 11.8237V3.98637H11.5639C11.9256 3.98637 12.1668 3.74522 12.1668 3.38349C12.1668 3.02177 11.9256 2.78062 11.5639 2.78062ZM4.32812 2.17807C4.32812 1.81634 4.56927 1.5752 4.931 1.5752H7.34248C7.7042 1.5752 7.94535 1.81634 7.94535 2.17807V2.78094H4.32812V2.17807ZM9.15502 12.4255C9.51674 12.4255 9.75789 12.1844 9.75789 11.8227V3.98535H2.52344V11.8227C2.52344 12.1844 2.76459 12.4255 3.12631 12.4255H9.15502Z"
                            fill="#315EFB"></path>
                        </svg>
                      </div>
                    </Grid>
                    <Grid item>
                      <button
                        disabled={
                          !(
                            row.balance.some(i => i.quantity_int > 0) &&
                            (row.price || row.offers[0].price)
                          )
                        }
                        onClick={() =>
                          setDialogData({row, indexRows: '', type: type})
                        }
                        className="btn"
                        style={{padding: '12px 12px'}}>
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0.304453 0.654591C0.49957 0.454903 0.778008 0.344864 1.05645 0.348731C1.87699 0.348731 2.7225 0.519591 3.41332 0.980841C3.95227 1.33697 4.36184 1.871 4.59633 2.47076C4.72289 2.77803 4.79637 3.10322 4.86 3.42877C5.30719 5.68791 5.75227 7.94776 6.21633 10.2037C6.29121 10.5493 6.47402 10.8844 6.77109 11.0865C7.11879 11.3375 7.55789 11.4237 7.98012 11.4237C9.72738 11.4258 11.475 11.4212 13.2223 11.4258C13.5197 11.0809 13.7602 10.6928 13.9971 10.3053C14.3768 9.68939 14.7104 9.04709 15.0525 8.41006C15.4093 7.75932 15.7127 7.08115 16.0555 6.42303C16.2605 6.03631 16.7295 5.81904 17.1577 5.90729C17.6295 5.9899 17.9975 6.43252 18 6.911V6.97252C17.9845 7.13108 17.9395 7.28576 17.8703 7.42885C17.216 8.79854 16.5414 10.1619 15.7419 11.4535C15.4009 11.99 15.0441 12.5237 14.5948 12.9772C14.3205 13.2497 13.9771 13.5067 13.5714 13.4975C11.707 13.5035 9.84234 13.4993 7.97801 13.4996C7.14656 13.5014 6.30141 13.2873 5.6148 12.8074C4.88426 12.3092 4.37379 11.5161 4.18992 10.6537C3.70934 8.33693 3.25512 6.01486 2.79422 3.69385C2.73727 3.39854 2.64937 3.09338 2.43914 2.86803C2.22469 2.63072 1.90898 2.51787 1.60031 2.46865C1.26105 2.4008 0.891211 2.47674 0.574453 2.31045C0.237656 2.14697 0.0158203 1.79049 0 1.41854V1.34049C0.0189844 1.08561 0.120234 0.834591 0.304453 0.654591Z"
                            fill="#1F1F1F"
                          />
                          <path
                            d="M6.94234 14.2255C7.36633 14.1379 7.81563 14.2339 8.18266 14.4582C8.54617 14.6867 8.82426 15.0527 8.93324 15.4696C9.15649 16.1991 8.79613 17.0464 8.13449 17.4159C7.74109 17.6448 7.25137 17.7228 6.81297 17.5868C6.39004 17.4753 6.01809 17.1905 5.79273 16.8168C5.54453 16.4059 5.46227 15.8852 5.61836 15.4264C5.78992 14.8193 6.32676 14.3464 6.94234 14.2255Z"
                            fill="#1F1F1F"
                          />
                          <path
                            d="M13.165 14.227C13.5724 14.1415 14.0073 14.2224 14.3655 14.4312C15.0402 14.8088 15.3998 15.6807 15.1509 16.4193C14.9523 17.1358 14.2404 17.6586 13.4996 17.6529C13.0155 17.6512 12.5332 17.436 12.2129 17.0721C11.8934 16.7164 11.7292 16.2238 11.7798 15.7471C11.8304 15.2996 12.0554 14.8703 12.4091 14.5884C12.6239 14.4034 12.8911 14.2913 13.165 14.227Z"
                            fill="#1F1F1F"
                          />
                          <rect
                            x="7.875"
                            y="5.625"
                            width="5.625"
                            height="2.25"
                            rx="1.125"
                            fill="#1F1F1F"
                          />
                        </svg>
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}

      {countRows < results.length && (
        <button onClick={addRows} className="btn btn-big mx-auto my-4">
          <svg
            className="mr-2"
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M14.878 7.42578C15.2282 8.90675 15.0884 10.4613 14.4798 11.8561C13.8711 13.2509 12.8263 14.4106 11.5024 15.161C10.1785 15.9114 8.64688 16.212 7.13753 16.0178C5.62818 15.8235 4.22259 15.1448 3.13184 14.0836"
              stroke="#1F1F1F"
              strokeWidth="1.8"
              strokeLinecap="round"
            />
            <path
              d="M1.15846 10.5463C1.0529 10.0551 0.99979 9.55398 1.00001 9.05151C0.997234 7.67104 1.40104 6.32031 2.16104 5.16787C2.92104 4.01544 4.00359 3.11232 5.2736 2.57122C6.54361 2.03012 7.94484 1.875 9.30245 2.1252C10.6601 2.3754 11.9139 3.01986 12.9076 3.97815"
              stroke="#1F1F1F"
              strokeWidth="1.8"
              strokeLinecap="round"
            />
            <path
              d="M9.74756 4.28857L13.0032 4.0494L12.7491 1"
              stroke="#1F1F1F"
              strokeWidth="1.8"
              strokeLinecap="round"
            />
            <path
              d="M6.21387 13.7109L2.96118 13.9501L3.2153 16.9995"
              stroke="#1F1F1F"
              strokeWidth="1.8"
              strokeLinecap="round"
            />
          </svg>
          {t('common:show_another')}{' '}
          {results.length - countRows < 40 ? results.length - countRows : 40}
        </button>
      )}
    </div>
  );
};
const actionCreators = {
  addToWishlist: customerActions.addToWishlist,
  removeFromWishlist: customerActions.removeFromWishlist,
};
const mapState = state => {
  const {userSelectedData} = state;
  return {userSelectedData};
};
export default connect(mapState, actionCreators)(WishlistTable);
