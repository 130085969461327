import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import {withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';

import {useTranslation} from 'react-i18next';

// import OrdersMobile from './OrdersMobile';
// import OrderModal from './OrderModal';

import {cartActions} from '../../../_actions/cart.actions';
import clsx from 'clsx';

import ukLocale from 'date-fns/locale/uk';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import {store} from '../../../_helpers';
import SalesInvoiceEnhancedTableBody from './SalesInvoiceEnhancedTableBody';

const localeMap = {
  en: enLocale,
  uk: ukLocale,
  ru: ruLocale,
};

/* ===================================================== */

function desc(a, b, invoiceBy) {
  if (b[invoiceBy] < a[invoiceBy]) {
    return -1;
  }
  if (b[invoiceBy] > a[invoiceBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  let stabilizedThis = [];
  if (array !== undefined && array.length > 0) {
    stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const invoices = cmp(a[0], b[0]);
      if (invoices !== 0) return invoices;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
}

function getSorting(invoices, invoiceBy) {
  return invoices === 'desc'
    ? (a, b) => desc(a, b, invoiceBy)
    : (a, b) => -desc(a, b, invoiceBy);
}

function EnhancedTableHead(props) {
  const {classes, invoices, invoiceBy, onRequestSort} = props;
  const {t} = useTranslation();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'arrows',
      align: 'center',
      disablePadding: false,
      label: '',
    },
    {
      id: 'date',
      align: 'center',
      disablePadding: false,
      label: t('common:orderTableHeaderLabelNumberDate'),
    },
    {
      id: 'deliveryFrom',
      align: 'center',
      disablePadding: false,
      label: t('common:orderTableHeaderLabelDeliveryFrom'),
    },
    {
      id: 'remark',
      align: 'center',
      disablePadding: false,
      label: t('common:orderTableHeaderLabelDelivery'),
    },
    {
      id: 'deliveryStatus',
      align: 'center',
      disablePadding: false,
      label: t('common:deliveryStatus'),
    },
    {
      id: 'total',
      align: 'right',
      disablePadding: false,
      label: t('common:orderTableHeaderLabelTotal'),
    },
    {
      id: 'download',
      align: 'center',
      disablePadding: false,
      label: '',
    },
  ];

  return (
    <TableHead>
      <TableRow className={classes.tableHeadRow}>
        {headCells.map(headCell => (
          <TableCell
            style={{width: headCell.id === 'date' ? '220px' : ''}}
            className={classes.tableHeadCells}
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={invoiceBy === headCell.id ? invoices : false}>
            {headCell.id === 'date' ? (
              <TableSortLabel
                active={invoiceBy === headCell.id}
                direction={invoices}
                onClick={createSortHandler(headCell.id)}
                className={classes.tableHeadTitle}>
                {headCell.label}
                {invoiceBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {invoices === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <div className={classes.tableHeadTitle}>{headCell.label}</div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  invoices: PropTypes.oneOf(['asc', 'desc']).isRequired,
  invoiceBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  contentBodyHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    maxHeight: 550,
    overflowX: 'auto',
  },
  tableHeadTitle: {
    clear: 'both',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  visuallyHidden: {
    align: 'center',
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHeadRow: {
    backgroundColor: '#e5e5e5',
  },
  tableHeadCells: {
    fontSize: 14,
    fontWeight: 700,
  },
  tableCells: {
    fontSize: 14,
  },
  tableRowText: {
    '& a': {
      margin: 0,
      fontSize: 14,
      // color: '#2f76b5',
      textDecoration: 'none',
    },
    '& b': {
      margin: 0,
      fontSize: 14,
      whiteSpace: 'nowrap',
    },
    '& p': {
      margin: 0,
      fontSize: 14,
    },
  },
  tableRowCount: {
    color: '#a0a0a0',
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
  modalButton: {
    marginLeft: theme.spacing(1),
  },
  dateFromInput: {
    marginRight: 20,
    '& input': {
      textAlign: 'center',
    },
  },
  dateUntilInput: {
    '& input': {
      textAlign: 'center',
    },
  },
  dateFilter: {
    display: 'flex',
    alignItems: 'baseline',
    '& p': {
      margin: '0 14px 0 0',
    },
  },
}));

/* ================================================================================== */

const Invoice = props => {
  const {getInvoices, invoice} = props;

  const classes = useStyles();

  /* ========================================================================== */

  // const [order, setOrder] = useState('asc');
  const [invoices, setInvoices] = useState('asc');
  const [invoiceBy, setInvoiceBy] = useState('calories');
  const [isMobileList, showMobileList] = useState(false);
  // const [userOrderList, setUserOrderList] = useState([]);
  // const [modalOrderData, setModalOrderData] = useState({});

  const [modifyData, setModifyData] = useState([]);

  const [filterFromDate, setFromDate] = useState(
    moment()
      .subtract(1, 'month')
      .add(1, 'day'),
  );
  const [filterUntilDate, setUntilDate] = useState(moment().add(7, 'day'));

  // const [modalIsOpen, setModal] = useState(false);

  const state2 = store.getState();

  const {languageReducer} = state2;
  let lang = 'uk';
  if (languageReducer.ln_ch !== undefined) lang = languageReducer.ln_ch; //'UA';

  useEffect(() => {
    const handleResize = () => {
      const currentScreenWidth = window.innerWidth;
      if (currentScreenWidth <= 780) {
        showMobileList(true);
      } else {
        showMobileList(false);
      }
    };

    // first render set
    handleResize();

    window.addEventListener('resize', handleResize);

    // clean up
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInvoices(
      moment(filterFromDate).format('DD.MM.YYYY'),
      moment(filterUntilDate).format('DD.MM.YYYY'),
    );
  }, [filterFromDate, filterUntilDate, getInvoices]);

  useEffect(
    // setup user order list
    () => {
      setupUserInvoices(invoice);
      // setupOrdersTable(userOrderList);
    },
    // eslint-disable-next-line
    [invoice],
  );

  const setupUserInvoices = list => {
    let modifyData = [];
    let sortedData = [];

    if (list.invoices && list.invoices.length > 0) {
      list.invoices.map((invoice, id) => {
        modifyData[id] = {
          status_message: invoice.delivery_type,
          price_sell: invoice.sum.toFixed(2),
        };

        sortedData[id] = {
          status: invoice.delivery_status,
          number: invoice.number,
          date: invoice.date,
          deliveryFrom: invoice.storage,
          deliveryTo: t('common:orderDelivery'),
          // deliveryTo: order.storage__name_repr,
          total: invoice.sum, //.toFixed(2),
          // currency_simbol: invoice.currency_simbol,
          currency:
            invoice.currency_simbol === '₴'
              ? JSON.parse(localStorage.getItem('state')).languageReducer
                  .lan === 'uk'
                ? 'UAH'
                : 'грн'
              : invoice.currency_simbol,
          // count: invoice.quantity,
          // price_sell: invoice.price_sell.toFixed(2),
        };

        return true;
      });
    }

    modifyData.sort((a, b) => (a.number - b.number) * -1);

    // setUserOrderList(sortedData);
    setModifyData(invoice.invoices);
  };

  const [locale] = useState(lang);

  // const toggleModal = currentOrder => {
  //   if (!modalIsOpen) {
  //     setModalOrderData({...currentOrder});
  //   }
  //   setModal(!modalIsOpen);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = invoiceBy === property && invoices === 'asc';

    setInvoices(isAsc ? 'desc' : 'asc');
    setInvoiceBy(property);
  };

  const handleSetFromDate = value => {
    setFromDate(value);
  };

  const handleSetUntilDate = value => {
    setUntilDate(value);
  };

  /*const isSelected = name => selected.indexOf(name) !== -1;*/

  /* =============================================================================== */

  const {t} = useTranslation();
  return (
    <div className={clsx(classes.root, 'order_container')}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={12} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <Grid container className={classes.contentBodyHead}>
                <Grid item xs={12} md={3} style={{display: 'flex'}}>
                  <Typography className={classes.title} variant="h3">
                    {t('common:salesInvoice')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  className={clsx('data_filter_container')}>
                  <Typography>{t('common:orderDateFilterTitle')}</Typography>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[locale]}>
                    <DatePicker
                      className={classes.dateFromInput}
                      disableToolbar
                      autoOk
                      format="dd.MM.yyyy"
                      maxDate={filterUntilDate}
                      variant="inline"
                      value={filterFromDate}
                      onChange={value => handleSetFromDate(value)}
                    />
                    <DatePicker
                      className={classes.dateUntilInput}
                      disableToolbar
                      autoOk
                      format="dd.MM.yyyy"
                      minDate={filterFromDate}
                      variant="inline"
                      value={filterUntilDate}
                      onChange={value => handleSetUntilDate(value)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {/* <Button variant="contained">{t('common:filters')}</Button> */}
              </Grid>

              {/* ================================================================ */}

              <div className={classes.root}>
                <Paper className={clsx(classes.paper, 'order_table_paper')}>
                  <div className={classes.tableWrapper}>
                    {isMobileList ? (
                      <SalesInvoiceEnhancedTableBody
                        invoiceRows={stableSort(
                          modifyData,
                          getSorting(invoices, invoiceBy),
                        )}
                        // invoiceRows={invoice.invoices}
                        isMobileList={isMobileList}
                      />
                    ) : (
                      <Table
                        className={clsx(classes.table, 'order_table')}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                        stickyHeader>
                        <EnhancedTableHead
                          classes={classes}
                          invoices={invoices}
                          invoiceBy={invoiceBy}
                          onRequestSort={handleRequestSort}
                        />
                        <SalesInvoiceEnhancedTableBody
                          invoiceRows={stableSort(
                            modifyData,
                            getSorting(invoices, invoiceBy),
                          )}
                          // invoiceRows={invoice.invoices}
                          isMobileList={isMobileList}
                        />
                        {/*{emptyRows > 0 && (
                                                    <TableRow style={{height: 53 * emptyRows}}>
                                                        <TableCell colSpan={6}/>
                                                    </TableRow>
                                                )}*/}
                      </Table>
                    )}
                  </div>
                </Paper>
              </div>

              {/* =================================================================== */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

function mapState(state) {
  const {invoice} = state;
  return {invoice};
}

const actionCreators = {
  getInvoices: cartActions.getInvoices,
};

export default connect(mapState, actionCreators)(withRouter(Invoice));
