import React from 'react';
import {Helmet} from 'react-helmet';
//import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
//import PtoductsNewTable from '../ProductList/components/PtoductsNewTable';
//import Grid from '@material-ui/core/Grid';
//import {cartActions, catalogActions} from '../../_actions';
import {catalogActions} from '../../_actions';
import {splitPath} from '../../_helpers';
//import SliderPhoto from '../../_components/SliderPhoto';
//import AddToCartDialog from '../ProductList/components/AddToCartDialog/AddToCartDialog';
import ProductList from '../ProductList';

function mapState(state) {
  const {
    catalogPage,
    catalogByGroup,
    //cart,
  } = state;

  // console.log(state, 'state');
  // console.log(state.catalogPage, 'state.catalogPage');

  //const {customer_id, sell_currency_id} = state.userSelectedData;
  //const {currencies} = state.frontendData;

  return {
    //customer_id,
    //sell_currency_id,
    //currencies,
    catalogPage,
    catalogByGroup,
    //cart,
  };
}

const actionCreators = {
  //addToCart: cartActions.addToCart,
  getCatalogByGroup: catalogActions.getCatalogByGroup,
  getPageProductGroup: catalogActions.getPageProductGroup,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getPageProductGroup(splitPath(this.props.location.pathname));
  }

  componentDidUpdate(prevProps) {
    // Для того чтоб работали стрелки в браузере
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.getPageProductGroup(splitPath(this.props.location.pathname));
    }

    // console.log(this.props.customer_id, 'this.props.customer_id');
    // console.log(prevProps.customer_id, 'prevProps.customer_id');
    // console.log(this.props.catalogPage, 'this.props.catalogPage');
    // console.log(prevProps.catalogPage, 'prevProps.catalogPage');
    // console.log('------------------------------------------------');
    // console.log(this.props.customer_id !== prevProps.customer_id, 'this.props.customer_id !== prevProps.customer_id');
    // console.log(this.props.catalogPage !== prevProps.catalogPage, 'this.props.catalogPage !== prevProps.catalogPage');
    // console.log('*****************************************************');

    if (
      this.props.customer_id !== prevProps.customer_id ||
      //|| this.props.sell_currency_id !== prevProps.sell_currency_id
      this.props.catalogPage !== prevProps.catalogPage
    ) {
      const {page_par: page} = this.props.catalogPage;
      page &&
        page.id &&
        this.props.getCatalogByGroup(
          'setData',
          page.id,
          this.props.location.pathname,
        );
    }
  }

  render() {
    //return <CatalogList {...this.props} />;
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content={this.props.catalogPage?.page_par?.meta_desc}
          />
          <meta
            name="keywords"
            content={this.props.catalogPage?.page_par?.meta_keywords}
          />
          <title>{this.props.catalogPage?.page_par?.meta_title}</title>
        </Helmet>
        <ProductList {...this.props} />;
      </>
    );
  }
}

export default connect(mapState, actionCreators)(Wrapper);
