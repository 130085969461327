import React from 'react';
import {Info, NextBtn, PrevBtn} from '../SwiperInterface';

export const ProductHeader = ({title = 'Perfect', slider, totalCount}) => {
  return (
    <div className="products-block__header">
      <h2 className="title mb-4">{title}</h2>
      {totalCount && (
        <div className="products-block__slick">
          <PrevBtn isDeactive={slider === 1} />
          <Info currentCount={slider} totalCount={totalCount} />
          <NextBtn isDeactive={slider === totalCount} />
        </div>
      )}
    </div>
  );
};
