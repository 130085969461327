import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {connect} from "react-redux";
import moment from 'moment';
import PriceToStr from "../../../_components/PriceToStr";
import {Link as RouterLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
// import ReactHtmlParser from "react-html-parser";
// import AttentionRed from "../../../icons/AttentionRed";
import Grid from "@material-ui/core/Grid";
// import AttentionYellow from "../../../icons/AttentionYellow";
import axInst from "../../../_helpers/axios-instance";
import {cartActions} from "../../../_actions";
import DownloadIcon from "../../../images/download_DANI.svg";
import PrintInvoiceForm from "./PrintInvoiceForm"
import PrintInvoiceForm2 from "./PrintInvoiceForm2"
// import PrintInvoiceForm3 from "./PrintInvoiceForm3"
import PrintInvoiceForm4 from "./PrintInvoiceForm4"
// import PrintInvoiceFormCertificateCompletion from "./PrintInvoiceFormCertificateCompletion"
import { useReactToPrint } from 'react-to-print';
// import ReactToPrint from 'react-to-print';

const useRowStyles = makeStyles({
    root: {
        cursor: 'pointer',
        '& > *': {
            // borderBottom: 'unset',
        },
    },

    printHolder: {
        position:'absolute',
        display: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#fff',
        width: '70vw',
        height: '70vh',
        zIndex: 999,
        // fontSize: 6,
    },

    table: {
        fontSize: 20,
        lineHeight: 1,
    },

    mobileBlockSaleInvoice: {
        width: '100%',
        padding: '10px',

        borderBottom: '1px solid #dadada'
    },
    loader4: {
        position: 'absolute',
        top: '60%',
        left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: '65px',
        height: '65px',
        display: 'inline-block',
        padding: '0px',
        borderRadius: '100%',
        border: '5px solid #ddd',
        borderTopColor: '#FFBE00',
        borderBottomColor: '#FFBE00',
        animation: 'loader4 1s ease-in-out infinite',
        zIndex: 999,
      }

});

function Row(props) {
    const {row,
        // getInvoiceItems,
        // invoiceItems,
        userSelectedData,
        isMobileList
        } = props;

    const [open, setOpen] = React.useState(false);
    const [invoiceSubItems, setInvoiceSubItems] = React.useState([]);
    const [printInvoiceData, setPrintInvoiceData] = React.useState([]);
    // const [variantPrint, setVariantPrint] = React.useState();
    const classes = useRowStyles();
    // console.log(isMobileList, 'isMobileList');

    const {t} = useTranslation();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: row.description_number,
    });
    useEffect(() => {
        handlePrint()

        // eslint-disable-next-line
    }, [printInvoiceData]);


    const invoiceDetail = (number, date, event) => {
        event.stopPropagation();
        axInst.post('/api/get-invoice-items/',
            JSON.stringify({
                customer_id: userSelectedData.customer_id,
                number: number,
                date: date

            })
        ).then(res => {
            setInvoiceSubItems(res.data.invoices);
            // console.log(invoiceSubItems, 'InvoiceSubItems')
        });

        setOpen(!open);
    };
    
    const getPrintInvoice = (number, order_number_1c, date, organization_internal_id, event) => {
        event.stopPropagation();
        // console.log(event.currentTarget);
        axInst.post('/api/get-invoices-print/',
            JSON.stringify({
                customer_id: userSelectedData.customer_id,
                number: number,
                order_number_1c: order_number_1c,
                date: moment(date).format('YYYY-MM-DD'),
                organisation_item_code_in_accounting_system: organization_internal_id

            })
        ).then(res => {
            setPrintInvoiceData(res.data.invoices);

            // setVariantPrint(res.data.invoices.html_template);

        });
        // console.log(printInvoiceData, 'ggggggggggggggggggggggg');
        // console.log(variantPrint);
        // handlePrint


    };

    console.log(componentRef, 'componentRef');


    return (
        <>
        <React.Fragment>
            {isMobileList ? (
                <>
                    <div className={clsx(classes.root, classes.mobileBlockSaleInvoice)}>
                        <div style={{display:'flex', justifyContent: 'space-between'}}>
                            <Typography variant={"subtitle2"} style={{ width: '200px' }}>
                                <b>{row.description_number}</b>
                            </Typography>
                            <div>{row.delivery_status}</div>
                        </div>
                        <div style={{color: '#008CB4', fontSize: '12px', fontWeight: 600}}>{row.ttn}</div>
                        <div style={{display:'flex', justifyContent: 'flex-end' }} onClick={(event) => getPrintInvoice(row.number, row.order_number_1c, row.date, row.organization_internal_id, event)}>
                            <IconButton aria-label="expand row" size="small"  >
                                <img style={{width: '20px'}} src={DownloadIcon} alt='DownloadIcon' />
                                <div className={classes.printBlockHolder}>
                                </div>
                            </IconButton>
                        </div>
                    </div>
                </>
            ):(
                <>
                    <TableRow className={clsx(classes.root, "order_table_row")} onClick={(event) => invoiceDetail(row.number, row.date, event)}>
                        <TableCell>
                            <IconButton aria-label="expand row" size="small" >
                                {open
                                    ? <KeyboardArrowUpIcon style={{color: "#008CB4"}}/>
                                    : <KeyboardArrowDownIcon style={{color: "#008CB4"}}/>
                                }
                            </IconButton>
                        </TableCell>
                        {/*<TableCell component="th" scope="row">
                    {moment(row.date).format('DD.MM.YYYY')}
                </TableCell>
                <TableCell align="center">{row.number}</TableCell>*/}
                        <TableCell component="th" scope="row">
                            <Grid container alignItems={"center"}>
                                {/*{(row.attentionRed1 || row.attentionRed2)*/}
                                {/*    ? <Grid item xs={4}><AttentionRed/></Grid>*/}
                                {/*    : row.attentionYellow && <Grid item xs={4}><AttentionYellow/></Grid>*/}
                                {/*}*/}
                                <Grid item xs={12}>
                                    <Typography variant={"subtitle2"}>
                                        <b>{row.description_number}</b>
                                    </Typography>
                                    {/*<Typography variant={"subtitle2"}>*/}
                                    {/*    {moment(row.date).format('DD.MM.YYYY')}*/}
                                    {/*</Typography>*/}
                                </Grid>
                            </Grid>
                        </TableCell>

                        <TableCell align="center">
                            <Typography variant={"body2"}>{row.storage}</Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography variant={"subtitle2"}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12}>
                                        {row.delivery_type}
                                    </Grid>
                                </Grid>

                            </Typography>
                        </TableCell>
                        <TableCell align="center" style={{width: '350px'}}>
                            <Typography variant={"subtitle2"}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12}>
                                        <div>{row.delivery_status}</div>
                                        <div style={{color: '#008CB4', fontSize: '12px', fontWeight: 600}}>{row.ttn}</div>
                                    </Grid>
                                </Grid>

                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant={"body2"}><PriceToStr
                                stAll={{fontWeight: "bold"}}
                                data={row.sum}
                                beforeText={''}
                                text={row.currency_simbol}
                                grn={t('common:uah')}
                            /></Typography>
                        </TableCell>
                        <TableCell align="center" style={{width: '60px'}} onClick={(event) => getPrintInvoice(row.number, row.order_number_1c, row.date, row.organization_internal_id, event)}>
                            {/*<IconButton aria-label="expand row" size="small">*/}
                            {/*    <img style={{width: '60px'}} src={DownloadIcon} alt='DownloadIcon' />*/}
                            {/*    <div className={classes.printBlockHolder}>*/}
                            {/*    </div>*/}
                            {/*</IconButton>*/}
                            {/*<ReactToPrint*/}
                            {/*    trigger={() => (*/}
                            {/*        <IconButton aria-label="expand row" size="small">*/}
                            {/*            <img style={{width: '60px'}} src={DownloadIcon} alt='DownloadIcon' />*/}
                            {/*            <div className={classes.printBlockHolder}>*/}
                            {/*            </div>*/}
                            {/*        </IconButton>)*/}
                            {/*    }*/}
                            {/*    documentTitle = {row.description_number}*/}
                            {/*    content={() => componentRef.current}*/}
                            {/*/>*/}
                            <IconButton aria-label="expand row" size="small"  >
                                <img style={{width: '60px'}} src={DownloadIcon} alt='DownloadIcon' />
                                <div className={classes.printBlockHolder}>
                                </div>
                            </IconButton>

                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    {/*<Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>*/}
                                    <Table size="small" aria-label="purchases">
                                        {/*<TableHead>
                                    <TableRow>
                                        <TableCell style={{color: "#008CB4"}}>{t('common:orderTableHeaderLabelStatus')}</TableCell>
                                        <TableCell style={{color: "#008CB4"}} align="center">{t('common:orderTableHeaderLabelBrand')}</TableCell>
                                        <TableCell style={{color: "#008CB4"}} align="right">{t('common:quantity')}</TableCell>
                                        <TableCell style={{color: "#008CB4"}} align="right">{t('common:price')}</TableCell>
                                        <TableCell style={{color: "#008CB4"}} align="right">{t('common:orderTableHeaderLabelTotal')}</TableCell>
                                    </TableRow>
                                </TableHead>*/}
                                        {/*{invoiceDetail}*/}
                                        <TableBody>
                                            {invoiceSubItems && invoiceSubItems.map((item, index) => (
                                                <TableRow className={"table_order_inner_row"} key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant={"body2"} className={"article_brand"}>
                                                            <RouterLink
                                                                to={`/product/${item.brand}/${item.article}`}>
                                                                {item.brand} {item.article}

                                                            </RouterLink>
                                                        </Typography>
                                                        <Typography variant={"body2"}>
                                                            {item.name}
                                                        </Typography>
                                                    </TableCell>
                                                    {/*<TableCell align="right">
                                                {item.count}
                                            </TableCell>
                                            <TableCell align="right">
                                                {item.price_sell}
                                            </TableCell>*/}
                                                    <TableCell align="right">
                                                        <ListItemText
                                                            primaryTypographyProps={{
                                                                variant: "body2",
                                                            }}
                                                            primary={<PriceToStr
                                                                stAll={{fontWeight: "bold"}}
                                                                data={item.sum}
                                                                beforeText={''}
                                                                text={row.currency_simbol}
                                                                grn={t('common:uah')}
                                                            />}
                                                            secondary={item.quantity + ' ' + t('common:orderProductAmount') + ' x ' + item.price.toFixed(2)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </>
            )}

            <div>
                {/*<div className={classes.printHolder}>*/}
                {/*<PrintInvoiceFormCertificateCompletion printData = {printInvoiceData} ref={componentRef} htmlTemplate = {1}/>*/}
                {/*</div>*/}
                {printInvoiceData && printInvoiceData.map((item, index) => (
                    item.html_template === 1 ?(
                        <div className={classes.printHolder}>
                            <PrintInvoiceForm printData = {printInvoiceData} ref={componentRef} htmlTemplate = {item.html_template}/>
                            {/*<PrintInvoiceFormCertificateCompletion printData = {printInvoiceData} ref={componentRef} htmlTemplate = {item.html_template}/>*/}

                        </div>
                    ): item.html_template === 2 || item.html_template === 4 ? (
                        <div className={classes.printHolder}>
                            <PrintInvoiceForm2 printData = {printInvoiceData} ref={componentRef} htmlTemplate = {item.html_template}/>

                        </div>
                    ): item.html_template === 3 ? (
                        <div className={classes.printHolder}>
                            <PrintInvoiceForm4 printData = {printInvoiceData} ref={componentRef} htmlTemplate = {item.html_template}/>


                        </div>
                    ): ''
                ))}

            </div>
        </React.Fragment>
            {/*<div className={classes.printHolder}>*/}
            {/*<div className={classes.printHolder}>*/}
            {/*    <PrintInvoiceForm5 printData = {printInvoiceData} ref={componentRef}/>*/}
            {/*</div>*/}


            {/*</div>*/}
        </>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

const SalesInvoiceEnhancedTableBody = props => {
    const classes=useRowStyles()
    const {
        invoiceRows,
        userSelectedData,
        isMobileList
    } = props;
    // console.log(isMobileList);


    return (
        <>
        {isMobileList ? (
            <>
                {invoiceRows ? invoiceRows.map((row) => (
                    <Row key={row.number} row={row} userSelectedData={userSelectedData} isMobileList={isMobileList}/>
                )) : <div className={classes.loader4}></div>}
            </>
            ):(
                <TableBody style={{width: '100%'}}>
                    {invoiceRows && invoiceRows.map((row) => (
                        <Row key={row.number} row={row} userSelectedData={userSelectedData} isMobileList={isMobileList}/>
                    ))}
                </TableBody>
            )}
        </>
    );

};

function mapState(state) {
    const {invoiceItems} = state;
    const {userSelectedData} = state;

    return {userSelectedData, invoiceItems};
}

const actionCreators = {
    getInvoiceItems: cartActions.getInvoiceItems,
};

export default connect(mapState, actionCreators)(SalesInvoiceEnhancedTableBody);

// export default connect(mapState)(SalesInvoiceEnhancedTableBody);
