import {userConstants} from '../_constants';
// import axInst from '../_helpers/axios-instance';
// import config from '../_services/config';

const initialState = {};

export function authentication(state = initialState, action) {
  // console.log('function authentication=========================',action);
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user.username,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.data.username,
        key: action.data.key,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        // failure: true,
        // badUser: action.user.username,
        // badPassword: action.password.password,
        loggedIn: false,
        error: action.error,
      };

    case userConstants.REGISTER_REQUEST:
      return {
        // ...state,
        // loading: true,
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        loggedIn: false,
        user: "",
        thanks: 1
      };
    case userConstants.SEND_EMAIL_COMFIRMATION:
      return {
        loggedIn: false,
        user: "",
        thanks: 7
      };
    case userConstants.REGISTER_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
      };

    case userConstants.FORGOT_PW_REQUEST:
      return {
        // ...state,
        // loading: true,
      };
    case userConstants.FORGOT_PW_SUCCESS:
      return {
        loggedIn: false,
        user: "",
        // key: action.data.key,
        // uid: action.data.uid,
        thanks: 4
      };
    case userConstants.FORGOT_PW_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
      };

    case userConstants.CONFIRM_PW_REQUEST:
      return {
        error: action.error,
        // ...state,
        // loading: true,
      };
    case userConstants.CONFIRM_PW_SUCCESS:
      return {
        loggedIn: false,
        thanks: 5
      };
    case userConstants.CONFIRM_PW_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
      };

    case userConstants.GET_MAIL_CONFIRM_REQUEST:
      return {
        // ...state,
        // loading: true,
      };
    case userConstants.GET_MAIL_CONFIRM_SUCCESS:
      return {
        loggedIn: true,
        user: "",
        key: action.data.key,

        // loggedIn: false,
        // already_work: action.already_work,
        // thanks: 2
      };
    case userConstants.GET_MAIL_CONFIRM_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
        thanks: 6
      };

/*
    case userConstants.CREATE_CUSTOMER_REQUEST:
      return {
        // ...state,
        // loading: true,
        // loggedIn: true,
      };
*/

    case userConstants.CREATE_CUSTOMER_SUCCESS:
      return {
/*
        loggedIn: true,
        user: "",
        key: action.data.key,
*/
        loggedIn: false,
        already_work: action.already_work,
        thanks: 3
      };
    case userConstants.CREATE_CUSTOMER_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
      };

    case userConstants.EXPECT_MANAGER_RESPONSE:
      return {
        ...state,
        gfd_user: action.user,
        thanks: 3
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
