import React, {useEffect, useState} from 'react';
import axInst from '../../../../_helpers/axios-instance';
import {GoogeMap} from '../GoogleMap';

export const Contacts = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    axInst
      .post('/api/static-page/', {slug: 'contacts'})
      .then(res => setData(res.data));
  }, []);

  if (!data) return <></>;
  const createMarkup = html => {
    return {__html: html};
  };

  return (
    <div className="products-block contact mt-5">
      <div className="wrapper-inner">
        <div className="products-block__header">
          <h2 className="title mb-4">{data.name}</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 col-12">
            <div id="map">
              <GoogeMap />
            </div>
          </div>
          <div
            className="col-lg-4 col-12 mt-lg-0 mt-4  contact-right"
            dangerouslySetInnerHTML={createMarkup(data.html)}></div>
        </div>
      </div>
    </div>
  );
};
