import {FormControl, MenuItem, Select, styled} from '@material-ui/core';
import clsx from 'clsx';
import Multiselect from 'multiselect-react-dropdown';
import React, {useState} from 'react';
import './style.scss';

// export const AddToCartSelector = ({data, inputValue, setInputValue}) => {
//   const [showDropdown, setDropdown] = useState(false);
//   const [activeItem, setActiveItem] = useState(0);

//   console.log(data);

//   const handleActiveItem = index => {
//     setInputValue(data[index]);
//     setActiveItem(index);
//     setDropdown(false);
//   };
//   const options1 = data?.map(item => ({cat: item.id, key: item.name}));

//   const handleChange = () => {};

//   return (
//     <>
//       <Multiselect
//         className="dropdown__title"
//         displayValue="key"
//         onKeyPressFn={function noRefCheck() {}}
//         onRemove={function noRefCheck() {}}
//         onSearch={function noRefCheck() {}}
//         onSelect={e => {
//           setInputValue(e.cat);
//           console.log(e);
//         }}
//         options={options1}
//         singleSelect
//         style={{
//           chips: {
//             margin: 0,
//             padding: 0,
//           },
//           searchBox: {
//             border: 'none',
//             width: '100%',
//             padding: 0,

//             img: {
//               display: 'none',
//             },
//           },
//         }}
//       />
//     </>
//   );
// };

const StyledSelect = styled(Select)({
  borderRadius: 100,
  background: '#EBF0F8',
  width: '100%',
  '& .MuiSelect-select': {
    padding: '15px 20px',
  },

  '& .arrow-icon': {
    position: 'absolute',
    right: 20,
  },
});
const arrowIcon = (
  <svg
    className="arrow-icon"
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L6.23285 6L11 1"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 1L6.23285 6L11 1"
      stroke="#999999"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AddToCartSelector = ({
  // data,
  handleChangeCart,
  localMainCartId,
  cart,
}) => {
  // const [defaultValue, setDefaultValue] = useState(data[0].id);

  // const handleCahnge = e => {
  //   setDefaultValue(e.target.value);
  //   setInputValue(data.find(i => e.target.value === i.id));
  //   console.log(data.find(i => e.target.value === i.id));
  // };

  return (
    // <StyledSelect
    //   onChange={handleCahnge}
    //   disableUnderline
    //   value={defaultValue}
    //   IconComponent={() => arrowIcon}>
    //   {data.map(item => (
    //     <MenuItem value={item.id}>{item.name}</MenuItem>
    //   ))}
    // </StyledSelect>
    <StyledSelect
      disableUnderline
      id="selectedCart"
      value={localMainCartId}
      onChange={handleChangeCart}
      IconComponent={() => arrowIcon}>
      {cart &&
        cart.list_customer_cart &&
        cart.list_customer_cart.map((value, index) => (
          <MenuItem key={index} value={value.id}>
            {value.name}
          </MenuItem>
        ))}
    </StyledSelect>
  );
};
